/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Grid, useMediaQuery } from '@mui/material';
import CustomSelectWithSearch from 'app/component/core/CustomSelectWithSearch';
import CustomSearchSelect from 'app/component/core/CustomSearchSelect';
import { DarkLightMode } from 'app/component/core/DarkLightMode';
import { CustomNotification } from 'app/component/core/Notification/index';
import { Profile } from '../Profile';
import { useEffect, useRef, useState } from 'react';
import { CustomDefaultAutocomplete } from 'app/component/core/CustomAutocomplete/CustomGlobalAutocomplete';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getCompanyList,
  getCompanySiteList,
  getGlobalNotificationList,
  getGlobalSearch,
  getNewReleaseNotificationList,
} from 'app/features/GlobalSlice/Selectors';
import {
  getActiveTab,
  getCompanyId,
  getDashboardFilterData,
  getSiteId,
  getViewType,
} from 'app/features/DashboardSlice/Selectors';
import { getActiveTab as compantSiteTab } from 'app/features/AdminSlice/Selectors';
import { actions as globalActions } from 'app/features/GlobalSlice/slice';
import { saveOnLocalStorage, saveOnSessionStorage } from 'store/localStore';
import { actions, initialState } from 'app/features/DashboardSlice/slice';
import { actions as adminAction } from 'app/features/AdminSlice/slice';
import { actions as securityManagerAction } from 'app/features/SecurityManagerSlice/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchMasterCompanyId,
  fetchMasterSiteId,
  isImpersonateUser,
  getUserDetails,
  getUserId,
  updateStorage,
  persistSiteInfoId,
} from 'app/features/authService';
import { Logout } from 'app/component/core/Logout';
import { PrimaryButton } from 'app/component/core/Buttons';
import { COLORS } from 'styles/colors';
import {
  capitalizeFirstLetter,
  checkIEVersion,
  useDebounce,
} from 'utils/CommonFn';
import { isEmpty } from 'utils/CommonFn/validators';
import ModalComponent from 'app/component/core/Modal';
import { PreviewModel } from 'app/pages/Dashboard/component/UserProfile/Notification/PreviewModel';
import { CONTEXT, ERROR_TEXT } from 'utils/Constants/Content';
import { FILTER_TYPE, GLOBAL_SEARCH_LIMIT } from 'utils/Constants';
import { MobileLogoutIcon } from 'assets/component';
import { ROUTE_PATH } from 'app/pages/Router/constant';
import { MobileMenuModel } from './component/mobile/MenuModel';
import { CustomDivider } from 'app/component/core/Charts/style';
import { MobileNav } from './component/mobile';
import {
  NavFooterContainer,
  NavFooterMenuItem,
  NavIconContainer,
} from '../styles';
import { props } from './types';
import CustomAdvanceFilter from 'app/component/core/CustomAutocomplete/CustomAdvanceFilter';
import { CustomRadio } from 'app/component/core/CustomRadio';
import { Title } from './style';
import ImpersonateUserBanner from './component/impersonateUserBanner';
import CustomToggleSearchSelect from 'app/component/core/CustomToggleSearchSelect';
import { STORE_KEY } from 'store/Constant';
import { getSelfTestProps } from 'app/features/SecurityManagerSlice/selectors';
import CustomTooltip from 'app/component/core/Tooltip';

function Navbar({ mode, setMode, theme, hideShow }: props): JSX.Element {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { siteId } = state ?? '';
  const selectorCompanyList: any = useSelector(getCompanyList);
  const selectorCompanySiteList: any = useSelector(getCompanySiteList);
  const selectorActiveTab: any = useSelector(getActiveTab);
  const selectorUserNotificationList: any = useSelector(
    getGlobalNotificationList,
  );
  const selectorNewRelaseNotificationList: any = useSelector(
    getNewReleaseNotificationList,
  );
  const selfTestProps = useSelector(getSelfTestProps, shallowEqual);
  const { isSystemOnTest } = selfTestProps;
  const ListViewData = selectorUserNotificationList.data || [];
  const TotalListCount = selectorUserNotificationList.recordCount || 0;
  const NewRelaseListViewData = selectorNewRelaseNotificationList.data || [];
  const NewRelaseTotalListCount =
    selectorNewRelaseNotificationList.recordCount || 0;
  const companyMainList: any = selectorCompanyList.companiesList || [];
  const companyTypeList: any = selectorCompanyList.companyTypesList || [];
  const companySiteList: any = selectorCompanySiteList.companySiteList || [];
  const selectorDashboardfilter = useSelector(getDashboardFilterData);
  const selectedCompanyId = useSelector(getCompanyId);
  const selectedSiteId = useSelector(getSiteId);
  const selectorCompanyTab = useSelector(compantSiteTab);
  const [companyValue, setCompanyValue] = useState(
    fetchMasterCompanyId() ?? '',
  );
  const [siteValue, setSiteValue] = useState(fetchMasterSiteId() ?? '');
  const selectedView = useSelector(getViewType);
  const defaultCount = 15;
  const [count, setCount] = useState(defaultCount);
  const [siteList, setsiteList] = useState<any>([]);
  const [siteSearchList, setsiteSearchList] = useState<any>([]);
  const [companyList, setCompanyList] = useState<any>([]);
  const [previewMessage, setPreviewMessage] = useState({});
  const [open, setOpen] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const searchList: any = [];
  const [globalSearchValue, setGlobalSearchValue] = useState([]);
  const [globalSearchList, setGlobalSearchList] = useState(searchList);
  const globalApiSearchList = useSelector(getGlobalSearch);
  const [isCancelIconGlobalSearch, setCancelIconGlobalSearch] =
    useState<boolean>(false);
  const [operator, setOperator] = useState('contains');
  const [selectedType, setselectedType] = useState<number>(1);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isMobileMenu, setisMobileMenu] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const menuRef: any = useRef(null);
  const handleChangeCompany = (event: any, item?: any): void => {
    const selectedID = item?.Id ?? event.target.value;
    if (selectedID) {
      setsiteList([]);
      dispatch(globalActions.clearSiteList({}));
      setCompanyValue(selectedID);
      setInLocalStore(STORE_KEY.SITE_COMPANY_ID, selectedID);
      isImpersonateUser()
        ? saveOnSessionStorage(STORE_KEY.SITE_ID, '')
        : updateStorage(STORE_KEY.SITE_ID, '');
      isImpersonateUser()
        ? saveOnSessionStorage(STORE_KEY.SITE_INFO_ID, '')
        : updateStorage(STORE_KEY.SITE_INFO_ID, '');
      setSiteValue('');
      dispatch(
        globalActions.fetchCompanySiteList({
          userId: getUserId(),
          companyId: selectedID,
        }),
      );
    }
  };
  const handleChangeSite = (event: any, item?: any): void => {
    const id: any = item?.Id ?? event.target.value;
    const masterCompanyId: any = item?.MasterCompanyId;
    setSiteValue(id);
    setCompanyValue(masterCompanyId);
    if (
      pathname.includes('/sitemanager') ||
      pathname.includes('/details') ||
      pathname.includes('/tripdetail')
    ) {
      setInLocalStore(STORE_KEY.SITE_INFO_ID, id);
    }
  };
  const onSiteTextSearch = (searchTerm: any) => {
    if (isEmpty(companyValue) && searchTerm) {
      dispatch(
        globalActions.fetchCompanySiteList({
          userId: getUserId(),
          companyId: null,
          searchTerm,
        }),
      );
    }
  };
  const setInLocalStore = (key: string, value: any) => {
    if (isImpersonateUser()) {
      saveOnSessionStorage(key, value);
    } else {
      saveOnLocalStorage(key, value);
    }
  };

  const onGoClick = (): void => {
    if (isMobileMenu) setisMobileMenu(!isMobileMenu);

    if (
      pathname.includes(`/${ROUTE_PATH.INSIGHTS_ANALYTICS}`) ||
      pathname.includes(`/${ROUTE_PATH.COMPANY_SITE}`)
    ) {
      setGlobalSearchList([]);
      setGlobalSearchValue([]);
      if (selectedCompanyId !== companyValue) {
        setInLocalStore(STORE_KEY.COMPANY_ID, companyValue);
        dispatch(actions.updateCompanyId(companyValue));
      }
      if (parseInt(selectedSiteId) !== parseInt(siteValue)) {
        setInLocalStore(STORE_KEY.SITE_ID, siteValue);
        dispatch(actions.updateSiteId(siteValue));
      }
      fetchBannerNotifications(companyValue);
      fetchNotifications(companyValue);
    } else {
      if (siteValue) {
        if (selectedCompanyId !== companyValue) {
          setInLocalStore(STORE_KEY.COMPANY_ID, companyValue);
          dispatch(actions.updateCompanyId(companyValue));
          dispatch(globalActions.fetchUserSystems({}));
        }
        if (parseInt(selectedSiteId) !== parseInt(siteValue)) {
          setInLocalStore(STORE_KEY.SITE_INFO_ID, siteValue);
          setInLocalStore(STORE_KEY.SITE_ID, siteValue);
          dispatch(actions.updateSiteId(siteValue));
          dispatch(globalActions.fetchUserSystems({}));
        }
        fetchNotifications(companyValue);
        setGlobalSearchList([]);
        setGlobalSearchValue([]);
        dispatch(actions.updateDashboardFilter(initialState.dashboardFilter));
        navigate(`/dashboard/sitemanager/${siteValue}`);
      } else if (selectedCompanyId !== companyValue) {
        setGlobalSearchList([]);
        setGlobalSearchValue([]);
        setInLocalStore(STORE_KEY.COMPANY_ID, companyValue);
        dispatch(actions.updateCompanyId(companyValue));
        if (!siteValue || parseInt(selectedSiteId) !== parseInt(siteValue)) {
          dispatch(actions.updateSiteId(siteValue));
        }
        fetchBannerNotifications(companyValue);
        fetchNotifications(companyValue);
        if ([`/${ROUTE_PATH.DASHBOARD}`].includes(pathname)) {
          dispatch(actions.fetchServiceCount({}));
        } else {
          navigate(`/${ROUTE_PATH.DASHBOARD}`);
        }
        dispatch(actions.updateDashboardFilter(initialState.dashboardFilter));
        dispatch(actions.updateResolvedEventsActive(false));
        dispatch(globalActions.fetchUserSystems({}));
        dispatch(actions.updateDashboardListPageNo(1));
      }
      if (
        isEmpty(siteValue) &&
        isEmpty(selectedCompanyId) &&
        isEmpty(companyValue)
      ) {
        dispatch(
          adminAction.displayToast({
            toastMessage: ERROR_TEXT.SELECT_COMPANY,
            toastType: 'warning',
          }),
        );
      }
    }
    if (siteValue && companySiteList.length > 0) {
      const selectedSite = companySiteList.find((c: any) => c.Id === siteValue);
      dispatch(actions.updateSiteStatus(selectedSite?.IsActiveSite));
    }
    dispatch(securityManagerAction.updateSMUserSiteGroupList({ data: [] }));
    dispatch(globalActions.updateExportSMSiteGroupList({ data: [] }));
    dispatch(globalActions.updateExportSMSiteList({ data: [] }));
  };
  const getDashboardData = (): any => {
    switch (selectorActiveTab) {
      case 'Events':
        break;
      case 'Sites':
        dispatch(
          actions.fetchFilterSiteData({
            page: 1,
            recordCountRequire: selectedView === 'list',
          }),
        );
        break;
      case CONTEXT.NETWORK:
        dispatch(
          actions.fetchFilterNetworkData({
            page: 1,
            recordCountRequire: selectedView === 'list',
          }),
        );
        break;
      case 'Service Requests':
        dispatch(
          actions.fetchFilterServiceRequest({
            page: 1,
            recordCountRequire: selectedView === 'list',
          }),
        );
        break;
      case 'Service Trips':
        dispatch(
          actions.fetchFilterServiceTrip({
            page: 1,
            recordCountRequire: selectedView === 'list',
          }),
        );
        break;
    }
    dispatch(actions.fetchServiceCount({}));
  };
  const fetchCompanySites = (companyID: any): void => {
    if (companyID) {
      setCompanyValue(companyID);
      dispatch(
        globalActions.fetchCompanySiteList({
          companyId: companyID,
        }),
      );
    }
  };
  const handleTextChange = (text: any): void => {
    const filterText = text.target.value;
    setGlobalSearch(filterText);
    if (filterText) {
      setCancelIconGlobalSearch(true);
    } else {
      setCancelIconGlobalSearch(false);
    }
  };
  const setCompanyId = (obj: any): void => {
    if (companyValue !== obj.CompanyId) {
      setCompanyValue(obj.CompanyId);
      setInLocalStore(STORE_KEY.COMPANY_ID, obj.CompanyId);
      dispatch(actions.updateCompanyId(obj.CompanyId));
      setsiteList([]);
      dispatch(globalActions.clearSiteList({}));
      dispatch(
        globalActions.fetchCompanySiteList({
          userId: getUserId(),
          companyId: obj.CompanyId,
        }),
      );
      dispatch(globalActions.fetchUserSystems({}));
    }
  };
  const clearSiteId = (): void => {
    setSiteValue('');
    dispatch(actions.updateSiteId(''));
    isImpersonateUser()
      ? saveOnSessionStorage(STORE_KEY.SITE_ID, '')
      : updateStorage(STORE_KEY.SITE_ID, '');
    persistSiteInfoId('');
    dispatch(actions.updateSiteStatus(true));
  };
  const onSelectedSite = (obj: any): void => {
    setCancelIconGlobalSearch(!isEmpty(obj.Type));
    switch (obj?.Type) {
      case CONTEXT.COMPANY:
        if (obj.CompanyId !== companyValue) {
          setCompanyId(obj);
          clearSiteId();
          if ([`/${ROUTE_PATH.DASHBOARD}`].includes(pathname)) {
            dispatch(actions.fetchServiceCount({}));
          } else {
            navigate(`/${ROUTE_PATH.DASHBOARD}`);
          }
        }
        break;
      case CONTEXT.SITE:
        setCompanyId(obj);
        setSiteValue(obj.SiteId);
        dispatch(actions.updateSiteId(obj.SiteId));
        isImpersonateUser()
          ? saveOnSessionStorage(STORE_KEY.SITE_ID, obj.SiteId)
          : updateStorage(STORE_KEY.SITE_ID, obj.SiteId);

        setInLocalStore(STORE_KEY.SITE_INFO_ID, obj.SiteId);
        dispatch(actions.updateDashboardFilter(initialState.dashboardFilter));
        dispatch(actions.updateSiteStatus(obj.IsActiveSite));
        navigate(`/dashboard/sitemanager/${obj.SiteId}`);
        break;
      case 'service_request':
        setCompanyId(obj);
        clearSiteId();
        navigate(
          { pathname: `/${ROUTE_PATH.DASHBOARD}/${ROUTE_PATH.CASE_DETAILS}` },
          {
            state: {
              ...obj,
              CaseNumber: obj.ServiceRequestNumber,
              Id: obj.SiteId,
            },
          },
        );
        break;
      case 'service_trip':
        setCompanyId(obj);
        clearSiteId();
        navigate(`/dashboard/tripdetail/${obj.WorkOrderNumber}`);
        break;
    }
  };

  useEffect(() => {
    if (globalApiSearchList?.data?.length > 0) {
      const list =
        globalApiSearchList.data.length > 20
          ? globalApiSearchList.data.slice(0, GLOBAL_SEARCH_LIMIT)
          : globalApiSearchList.data;
      setGlobalSearchList(list);
    }
  }, [globalApiSearchList]);

  useEffect(() => {
    if (globalSearch.length > 2) {
      dispatch(
        globalActions.fetchGlobalSearchList({
          searchKey: globalSearch,
          operator,
        }),
      );
    } else {
      setGlobalSearchList([]);
    }
  }, [useDebounce(globalSearch, 300)]);

  useEffect(() => {
    if (companyMainList && companyMainList.length === 1) {
      const companyID = companyMainList[0].Id;
      if (companyID) {
        fetchCompanySites(companyID);
        setCompanyValue(companyID);
        setInLocalStore(STORE_KEY.COMPANY_ID, companyID);
        if (!selectedCompanyId) {
          dispatch(actions.updateCompanyId(companyID));
        }
        getDashboardData();
      }
    }
    setCompanyList(companyMainList);
  }, [companyMainList]);

  useEffect(() => {
    if (pathname === `/${ROUTE_PATH.OPEN_SERVICE_REQUEST}` && isEmpty(siteId)) {
      clearSiteId();
    }
    if (pathname === `/${ROUTE_PATH.DASHBOARD}`) {
      setInLocalStore(STORE_KEY.SITE_ID, '');
      setInLocalStore(STORE_KEY.SITE_INFO_ID, '');
      dispatch(actions.updateSiteId(''));
      if (fetchMasterCompanyId() && selectedCompanyId) {
        dispatch(actions.fetchServiceCount({}));
      }
      dispatch(actions.updateSiteStatus(true));
    }
    if (
      !pathname.includes('/sitemanager') &&
      !pathname.includes('/details') &&
      !pathname.includes('/tripdetail') &&
      !pathname.includes('/analytics')
    ) {
      setInLocalStore(STORE_KEY.SITE_INFO_ID, '');
    }
    if (!pathname.includes('/login') && selectorCompanyList.loading) {
      dispatch(globalActions.fetchCompanyList({}));
    }
    const selectedSiteId = fetchMasterSiteId() ?? '';
    setSiteValue(selectedSiteId);
    // If path - companysite & not in add edit
    if (!pathname.includes('/companysite')) {
      if (selectorCompanyTab !== 1) {
        dispatch(adminAction.updateActiveTab(1));
      }
    }
    if (!pathname.includes('dashboard') && !pathname.includes('exportData')) {
      dispatch(actions.updateDashboardActiveTab(1));
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(actions.updateDashboardFilter(initialState.dashboardFilter));
      dispatch(actions.updateActiveTab('Sites'));
    } else if (pathname.includes('sitemanager')) {
      dispatch(actions.updateDashboardFilter(initialState.dashboardFilter));
    }
  }, [pathname]);

  useEffect(() => {
    if (isImpersonateUser() && !companyValue) {
      setsiteList([]);
    } else if (companySiteList && companySiteList.length > 0) {
      setsiteList(companySiteList);
    }
  }, [selectorCompanySiteList]);

  useEffect(() => {
    if (selectedCompanyId && selectorDashboardfilter.loading) {
      dispatch(actions.fetchFillterData({}));
    }
  }, [selectedCompanyId]);

  const onModalClose = (): void => {
    setOpen(false);
  };

  const fetchBannerNotifications = (id?: any): void => {
    if (id || selectedCompanyId) {
      dispatch(
        globalActions.fetchUserBannerNotification({
          companyId: id || selectedCompanyId,
          recordCountRequire: false,
          unreadOnly: true,
          notificationType: CONTEXT.BANNER,
        }),
      );
    }
  };
  const fetchNotifications = (id?: any): void => {
    if (id || selectedCompanyId) {
      dispatch(
        globalActions.fetchUserNotification({
          limit: 5,
          companyId: id || selectedCompanyId,
          recordCountRequire: true,
          unreadOnly: true,
          isGlobal: true,
          notificationType: CONTEXT.GENERAL,
        }),
      );
      dispatch(
        globalActions.fetchNewReleaseNotification({
          limit: 5,
          companyId: id || selectedCompanyId,
          recordCountRequire: true,
          unreadOnly: true,
          notificationType: CONTEXT.RELEASE,
        }),
      );
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchBannerNotifications();
    if (
      process.env.REACT_APP_ENV !== 'local' &&
      process.env.REACT_APP_ENV !== 'development'
    ) {
      const notificationInterval = setInterval(() => {
        fetchNotifications();
        fetchBannerNotifications();
      }, 300000);
      return () => {
        clearInterval(notificationInterval);
      };
    }
  }, []);

  const getAvatarLabel = (): string => {
    try {
      if (getUserDetails()) {
        const firstName =
          (getUserDetails().FirstName && getUserDetails().FirstName) || '';
        const lastName =
          (getUserDetails().LastName && getUserDetails().LastName) || '';
        const email =
          (getUserDetails().UserName && getUserDetails().UserName) || '';
        const avtarText =
          capitalizeFirstLetter(firstName) + capitalizeFirstLetter(lastName) ||
          capitalizeFirstLetter(email);
        return avtarText;
      }
      return '';
    } catch (error) {
      return '';
    }
  };
  const getTitleName = (): string => {
    try {
      if (getUserDetails()) {
        const firstName =
          (getUserDetails().FirstName &&
            capitalizeFirstLetter(getUserDetails().FirstName)) ||
          '';
        const lastName =
          (getUserDetails().LastName &&
            capitalizeFirstLetter(getUserDetails().LastName)) ||
          '';
        const userName = getUserDetails().UserName || '';
        if (firstName || lastName) {
          return `${firstName} ${lastName}`;
        } else {
          return userName;
        }
      }
      return '';
    } catch (error) {
      return '';
    }
  };
  useEffect(() => {
    if (selectorCompanySiteList.loading) {
      if (selectedCompanyId) {
        dispatch(
          globalActions.fetchCompanySiteList({
            userId: getUserId(),
            companyId: selectedCompanyId,
          }),
        );
      }
    }
  }, [selectedCompanyId]);

  const onScroll = (e: any): void => {
    if (e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight) {
      if (siteSearchList?.length > 0 && siteSearchList?.length > count) {
        setsiteList([
          ...siteList,
          ...siteSearchList.slice(count, defaultCount + count),
        ]);
        setCount(count + defaultCount);
      } else if (!siteSearchList?.length && companySiteList?.length > count) {
        setsiteList([
          ...siteList,
          ...companySiteList.slice(count, defaultCount + count),
        ]);
        setCount(count + defaultCount);
      }
    }
  };
  const onSucess = (): void => {
    fetchNotifications();
  };
  const onNotificationClick = (item: any): void => {
    setOpen(true);
    setPreviewMessage(item);
    if (!item.Read) {
      dispatch(
        globalActions.postReadUserNotification({
          notificationId: item.NotificationId,
          userId: getUserId(),
          companyId: companyValue,
          callback: onSucess,
        }),
      );
    }
  };
  const onClearHandler = (): void => {
    setCancelIconGlobalSearch(false);
    setGlobalSearchValue([]);
  };
  const searchData = (list: any, text: any): any => {
    return list.filter(
      (ele: any) =>
        ele.Name.toLowerCase().indexOf(text.trim().toLowerCase()) > -1,
    );
  };
  const handleSearchCompanyEvent = (text: any): void => {
    if (text === '') {
      setCompanyList(companyMainList);
    } else {
      const updatedList = searchData(companyMainList, text);
      setCompanyList(updatedList);
    }
  };
  const handleSearchSiteEvent = (text: any): void => {
    if (text === '') {
      setsiteSearchList([]);
      setsiteList(companySiteList.slice(0, defaultCount));
      setCount(defaultCount);
    } else {
      const updatedList = searchData(companySiteList, text);
      setsiteSearchList(updatedList);
      setsiteList(updatedList.slice(0, defaultCount));
      setCount(defaultCount);
    }
  };

  const openMobileNavbar = () => setisMobileMenu(!isMobileMenu);

  const onLogout = (): void => navigate(`/${ROUTE_PATH.LOGOUT}`);

  const advancedSeach = () => {
    const onFilterChange = (e: any): void => {
      const selectedId = parseInt(e.target.value);
      const name = e.target.name;
      setOperator(name);
      setselectedType(selectedId);
    };
    return (
      <Grid
        container
        flexDirection={'column'}
        p={2}
        sx={{
          backgroundColor: theme.palette.customBackground.main,
          borderRadius: '8px',
        }}
      >
        <Title theme={theme}>Advanced Search</Title>
        <CustomRadio
          options={FILTER_TYPE}
          onChange={onFilterChange}
          value={selectedType}
        />
      </Grid>
    );
  };
  const reloadPage = () => (window.location.href = `/${ROUTE_PATH.DASHBOARD}`);

  const onCompanyClear = () => {
    setCompanyValue('');
    setInLocalStore(STORE_KEY.COMPANY_ID, '');
    dispatch(actions.updateCompanyId(''));
    setsiteList([]);
    dispatch(globalActions.clearSiteList({}));
    clearSiteId();
    reloadPage();
  };

  const onSiteClear = () => {
    if (pathname === '/dashboard' || pathname === '/insightsAnalytics') {
      clearSiteId();
    } else {
      clearSiteId();
      reloadPage();
    }
  };

  const getMenuItems = (key: string, props: any = {}) => {
    switch (key) {
      case CONTEXT.COMPANY:
        return checkIEVersion() ? (
          <CustomTooltip
            title={isSystemOnTest ? ERROR_TEXT.SELF_TEST_IN_PROGRESS : ''}
            disable={!isSystemOnTest}
          >
            <CustomSelectWithSearch
              value={companyValue}
              handleChange={handleChangeCompany}
              label="Select Company"
              options={companyList}
              style={{
                background: theme.palette.customBackground.main,
              }}
              labelStyle={{
                color: theme.palette.customBackground.dark,
                fontSize: '0.875rem',
              }}
              placeholder={'Search Company'}
              handleSearchEvent={handleSearchCompanyEvent}
              isDisabled={isSystemOnTest}
            />
          </CustomTooltip>
        ) : (
          <CustomTooltip
            title={isSystemOnTest ? ERROR_TEXT.SELF_TEST_IN_PROGRESS : ''}
            disable={!isSystemOnTest}
          >
            <CustomToggleSearchSelect
              mainList={companyList}
              companyTypes={companyTypeList}
              handleChange={handleChangeCompany}
              value={companyValue}
              placeholder={'Select Company'}
              searchPlaceholder={'Search Company'}
              onSelectionClear={onCompanyClear}
              isDisabled={isSystemOnTest}
            />
          </CustomTooltip>
        );
      case CONTEXT.SITE:
        return checkIEVersion() ? (
          <CustomTooltip
            title={isSystemOnTest ? ERROR_TEXT.SELF_TEST_IN_PROGRESS : ''}
            disable={!isSystemOnTest}
          >
            <CustomSelectWithSearch
              value={siteValue}
              handleChange={handleChangeSite}
              label={
                selectorCompanySiteList.loading ? 'Loading...' : 'Select Site'
              }
              options={checkIEVersion() ? companySiteList : siteList}
              style={{
                background: theme.palette.customBackground.main,
              }}
              labelStyle={{
                color: theme.palette.customBackground.dark,
                fontSize: '0.875rem',
              }}
              onScroll={onScroll}
              loading={selectorCompanySiteList.loading}
              placeholder={'Search Site'}
              handleSearchEvent={handleSearchSiteEvent}
              isDisabled={isSystemOnTest}
            />
          </CustomTooltip>
        ) : (
          <CustomTooltip
            title={isSystemOnTest ? ERROR_TEXT.SELF_TEST_IN_PROGRESS : ''}
            disable={!isSystemOnTest}
          >
            <CustomSearchSelect
              listOptions={siteList}
              handleChange={handleChangeSite}
              value={siteValue}
              placeholder={'Select Site'}
              loading={selectorCompanySiteList.loading}
              searchPlaceholder={'Search Site'}
              onTextSearch={onSiteTextSearch}
              onSelectionClear={onSiteClear}
              isDisabled={isSystemOnTest}
            />
          </CustomTooltip>
        );
      case CONTEXT.SEARCH:
        return (
          <CustomTooltip
            title={isSystemOnTest ? ERROR_TEXT.SELF_TEST_IN_PROGRESS : ''}
            disable={!isSystemOnTest}
          >
            <CustomDefaultAutocomplete
              value={globalSearchValue}
              placeholder={`Search by ${operator.split('W').join(' w')}`}
              onTextChange={handleTextChange}
              companySiteList={globalSearchList}
              handleChange={onSelectedSite}
              theme={theme}
              loading={globalApiSearchList.loading}
              showEndAdornment={isCancelIconGlobalSearch}
              isShowAdvanceSearch={true}
              onTextClear={onClearHandler}
              advanceFilter={
                <CustomAdvanceFilter
                  children={advancedSeach()}
                  selectedType={selectedType}
                  isDisabled={isSystemOnTest}
                />
              }
              isDisabled={isSystemOnTest}
            />
          </CustomTooltip>
        );
      case CONTEXT.AVATAR:
        return (
          getTitleName() && (
            <Profile
              fullName={getTitleName()}
              title={'Welcome'}
              theme={theme}
              label={getAvatarLabel()}
            />
          )
        );
      case CONTEXT.GO:
        return (
          <PrimaryButton
            style={{
              padding: '0.45rem',
              backgroundColor: COLORS.royalBlue,
            }}
            children={CONTEXT.GO}
            disabled={false}
            fullWidth={false}
            onClick={onGoClick}
            {...props}
          />
        );
      case CONTEXT.NOTIFICATION:
        return (
          <CustomNotification
            data={ListViewData.slice(0, 5)}
            newReleaseData={NewRelaseListViewData.slice(0, 5)}
            onNotifyClick={onNotificationClick}
            badgeContent={TotalListCount + NewRelaseTotalListCount}
            {...props}
          />
        );
      case CONTEXT.THEME:
        return <DarkLightMode mode={mode} setMode={setMode} />;
      default:
        break;
    }
  };

  const customMobileNav = (): JSX.Element => {
    const onModeChange = () => {
      setMode(mode === 'dark' ? 'light' : 'dark');
    };
    const onOpenNotification = () => {
      if (isSmallerScreen) {
        setIsShowNotification(!isShowNotification);
      }
    };
    return (
      <>
        <Grid container p={2} spacing={3}>
          <Grid item xs={12}>
            {getMenuItems(CONTEXT.AVATAR)}
          </Grid>
          <Grid item xs={12}>
            {getMenuItems(CONTEXT.COMPANY)}
          </Grid>
          <Grid item xs={12}>
            {getMenuItems(CONTEXT.SITE)}
          </Grid>
          <Grid item xs={12}>
            {getMenuItems(CONTEXT.GO, { fullWidth: true })}
          </Grid>
        </Grid>
        <NavFooterContainer>
          <CustomDivider variant="fullWidth" theme={theme} />
          <Grid container sx={{ padding: 2.5 }}>
            <Grid item xs={12}>
              <NavFooterMenuItem
                container
                theme={theme}
                onClick={onOpenNotification}
                ref={menuRef}
              >
                <Grid item>Notification</Grid>
                <Grid item>
                  {getMenuItems(CONTEXT.NOTIFICATION, {
                    isShowNotification,
                    openMobileNavbar,
                  })}
                </Grid>
              </NavFooterMenuItem>
              <NavFooterMenuItem container theme={theme} onClick={onModeChange}>
                <Grid item>Theme</Grid>
                <Grid item>{getMenuItems(CONTEXT.THEME)}</Grid>
              </NavFooterMenuItem>
              <NavFooterMenuItem container theme={theme} onClick={onLogout}>
                <Grid item>Logout</Grid>
                <NavIconContainer item>
                  <MobileLogoutIcon />
                </NavIconContainer>
              </NavFooterMenuItem>
            </Grid>
          </Grid>
        </NavFooterContainer>
      </>
    );
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {isSmallerScreen ? (
          <MobileNav
            hideShow={hideShow}
            isMobileMenu={isMobileMenu}
            openMobileNavbar={openMobileNavbar}
            theme={theme}
          />
        ) : (
          <>
            <Grid item xs={3} md={3} lg={3}>
              {getMenuItems(CONTEXT.SEARCH)}
            </Grid>
            <Grid item xs={2.5} md={2.5} lg={2.5}>
              {getMenuItems(CONTEXT.COMPANY)}
            </Grid>
            <Grid item xs={2.5} md={2.5} lg={2.5}>
              {getMenuItems(CONTEXT.SITE)}
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              <Grid container>
                <Grid item sx={{ paddingRight: '1.875rem' }}>
                  {getMenuItems(CONTEXT.GO)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              {isImpersonateUser() && (
                <ImpersonateUserBanner
                  theme={theme}
                  userDetails={getUserDetails()}
                />
              )}
              <Grid container pt={isImpersonateUser() ? 1.5 : 0}>
                <Grid
                  container
                  xs={6}
                  md={7}
                  lg={7}
                  xl={8}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {getMenuItems(CONTEXT.AVATAR)}
                  <Logout />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={5}
                  lg={5}
                  xl={4}
                  sx={{ justifyContent: 'flex-end', display: 'flex' }}
                >
                  <Grid item pr={2}>
                    {getMenuItems(CONTEXT.NOTIFICATION)}
                  </Grid>
                  <Grid item pr={1}>
                    {getMenuItems(CONTEXT.THEME)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <ModalComponent
        modalOpen={open}
        children={
          <PreviewModel data={previewMessage} onCancelClick={onModalClose} />
        }
        setModalOpen={onModalClose}
      />
      {isSmallerScreen && (
        <ModalComponent
          modalOpen={isMobileMenu}
          children={
            <MobileMenuModel
              Children={customMobileNav()}
              onCancelClick={onModalClose}
            />
          }
        />
      )}
    </>
  );
}
export default Navbar;
