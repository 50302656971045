import { useRef, useEffect, useState } from 'react';
import CustomTooltip from '../Tooltip';

const OverflowTip = ({
  text,
  customClass = '',
  customStyle = {},
}: any): JSX.Element => {
  const textElementRef: any = useRef(null);
  const [onHover, setHover] = useState(false);

  const compareSize = (): void => {
    if (textElementRef !== null) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, [onHover]);

  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    [onHover],
  );

  return (
    <CustomTooltip title={text} disable={!onHover}>
      <div
        ref={textElementRef}
        className={`name ${customClass}`}
        style={customStyle}
      >
        {text}
      </div>
    </CustomTooltip>
  );
};

export default OverflowTip;
