import { Suspense, useState } from 'react';
import { Box, AppBar, CssBaseline, useMediaQuery } from '@mui/material';
import { LayoutContainer } from './styles';
import SideBar from 'app/component/layout/Sidebar';
import Navbar from './Navbar';
import CustomDrawer from './Drawer';
import CustomBreadcrumb from '../core/Breadcrumb';
import Loader from 'app/component/layout/Loader/Loader';
import { ErrorsBoundary } from 'app/component/layout/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'app/pages/Router/constant';

export function Layout({ children, mode, onChange, theme }: any): JSX.Element {
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isMidumScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const { pathname } = useLocation();
  const isDashboard = [`/${ROUTE_PATH.DASHBOARD}`].includes(pathname);

  const hideShow = () => {
    setIsShowSideBar(!isShowSideBar);
  };
  const drawerWidth = isMidumScreen ? 230 : 310; // Width of the sidebar
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
      }}
    >
      <CssBaseline />
      <CustomDrawer
        toggleDetailsDrawer={isSmallerScreen && isShowSideBar}
        children={<SideBar theme={theme} />}
        setToggleDetailsDrawer={hideShow}
        variant={isSmallerScreen ? 'temporary' : 'permanent'}
        drawerWidth={drawerWidth}
      />
      <Box
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppBar
          position="static"
          sx={{
            height: '10%',
            flexShrink: 0,
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 2,
            background: 'transparent',
            boxShadow: 'none',
          }}
        >
          <Navbar
            mode={mode}
            setMode={onChange}
            theme={theme}
            hideShow={hideShow}
          />
        </AppBar>

        {!isSmallerScreen && !isDashboard && (
          <Box
            sx={{
              height: isMidumScreen ? '5%' : '1%',
              backgroundColor: 'transparent',
              flexShrink: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 2,
            }}
          >
            <CustomBreadcrumb />
          </Box>
        )}
        <Box
          sx={{
            flex: '1 1 auto',
            overflowY: 'auto',
            padding: 2,
            paddingTop: isDashboard ? 0 : isMidumScreen ? 0 : 2,
          }}
        >
          <LayoutContainer theme={theme} isHome={isDashboard}>
            <ErrorsBoundary>
              <Suspense fallback={<Loader size={100} maxHeight={true} />}>
                {children}
              </Suspense>
            </ErrorsBoundary>
          </LayoutContainer>
        </Box>
      </Box>
    </Box>
  );
}
