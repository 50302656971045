import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { Grid } from '@mui/material';
import { isEmpty } from 'utils/CommonFn/validators';
import { CONTEXT } from 'utils/Constants/Content';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { CUSTOM_ROW_WIDTH, DATE_FORMAT_2 } from 'utils/Constants';
import { getFormatedDate } from 'utils/CommonFn';
function ServiceRequestRowItem({
  key,
  rowItem,
  tableName,
  onServiceRequestEditClick,
  onSiteHandleClick,
  source,
}: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  const onSiteClick = (e: any) => {
    if (source !== CONTEXT.SITE_MANAGER) {
      onSiteHandleClick(e, rowItem.Site.Id);
    }
  };
  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={
            <span
              className="name link"
              onClick={(e: any) => onServiceRequestEditClick(rowItem)}
            >
              {rowItem.CaseNumber}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={2} theme={theme} width={CUSTOM_ROW_WIDTH}>
        <OverflowTip
          text={
            <span
              className={`name ${
                source !== CONTEXT.SITE_MANAGER ? 'link' : ''
              }`}
              onClick={e => onSiteClick(e)}
            >
              {rowItem?.Site?.Name}
            </span>
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme} width={CUSTOM_ROW_WIDTH}>
        <OverflowTip
          text={!isEmpty(rowItem.Subject) ? rowItem.Subject : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1.5} theme={theme}>
        <OverflowTip
          text={!isEmpty(rowItem.Title) ? rowItem.Title : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={!isEmpty(rowItem.IncidentType) ? rowItem.IncidentType : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={!isEmpty(rowItem.Status) ? rowItem.Status : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={!isEmpty(rowItem.SubStatus) ? rowItem.SubStatus : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={!isEmpty(rowItem.Priority) ? rowItem.Priority : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip
          text={getFormatedDate(
            rowItem.CreatedOn,
            DATE_FORMAT_2,
            rowItem?.TimeZone,
          )}
        ></OverflowTip>
      </TableCell>

      {tableName === CONTEXT.ACTIVE_SERVICE_REQUEST ? (
        <TableCell item sm={false} md={1} theme={theme}>
          <OverflowTip
            text={getFormatedDate(
              rowItem.ResolvedOn,
              DATE_FORMAT_2,
              rowItem?.TimeZone,
            )}
          ></OverflowTip>
        </TableCell>
      ) : (
        <TableCell item sm={false} md={1} theme={theme}>
          <OverflowTip
            text={getFormatedDate(
              rowItem.ResolvedOn,
              DATE_FORMAT_2,
              rowItem?.TimeZone,
            )}
          ></OverflowTip>
        </TableCell>
      )}
      {tableName !== CONTEXT.ACTIVE_SERVICE_REQUEST && (
        <TableCell item sm={false} md={0.5} theme={theme}>
          <Grid container>
            <CustomTooltip title={'Edit'}>
              <IconsButton
                name="edit"
                children={<EditIcon />}
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                }}
                OnIconClick={() => onServiceRequestEditClick(rowItem)}
              />
            </CustomTooltip>
          </Grid>
        </TableCell>
      )}
    </TableRow>
  );
}
export default ServiceRequestRowItem;
