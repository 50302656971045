import { CUSTOM_ROW_WIDTH, DATE_FORMAT_4 } from 'utils/Constants';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import CustomTooltip from '../Tooltip';
import { getFormatedDate } from 'utils/CommonFn';

function SecurityEventsExportRowItem({ key, rowItem, isAutoCells }: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  return (
    <TableRow
      container
      key={key}
      onClick={handleClick}
      alignContent="center"
      theme={theme}
    >
      {isAutoCells ? (
        Object.keys(rowItem).length > 0 &&
        Object.keys(rowItem).map((key: any, value: any) => {
          const count = Object.keys(rowItem).length <= 12 ? 3 : 1;
          return (
            <TableCell item xs={count}>
              {key === 'AlarmTime' ? (
                <OverflowTip
                  text={getFormatedDate(
                    rowItem.AlarmTime,
                    DATE_FORMAT_4,
                    rowItem?.siteDetails?.TimeZone,
                  )}
                />
              ) : (
                <OverflowTip text={rowItem[key] ?? '-'} />
              )}
            </TableCell>
          );
        })
      ) : (
        <>
          <TableCell
            item
            sm={false}
            md={2.5}
            theme={theme}
            width={CUSTOM_ROW_WIDTH}
          >
            <CustomTooltip title={rowItem?.SiteName} placement="top">
              {rowItem?.SiteName}
            </CustomTooltip>
          </TableCell>
          <TableCell item sm={false} md={2} theme={theme}>
            <OverflowTip text={rowItem?.EventName ?? '-'} />
          </TableCell>
          <TableCell item sm={false} md={2} theme={theme}>
            <OverflowTip text={rowItem?.EventCategory ?? '-'} />
          </TableCell>
          <TableCell
            item
            sm={false}
            md={2}
            theme={theme}
            width={CUSTOM_ROW_WIDTH}
          >
            <OverflowTip
              text={getFormatedDate(
                rowItem?.AlarmTime,
                DATE_FORMAT_4,
                rowItem?.TimeZone,
              )}
            />{' '}
          </TableCell>
          {/* <TableCell item sm={false} md={0.7} theme={theme}>
            <OverflowTip text={rowItem?.Area ?? '-'} />
          </TableCell> */}
          <TableCell item sm={false} md={1.5} theme={theme}>
            <OverflowTip text={rowItem?.Zone ?? '-'} />
          </TableCell>
          <TableCell item sm={false} md={2} theme={theme}>
            <OverflowTip text={rowItem?.Resolution ?? '-'} />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
export default SecurityEventsExportRowItem;
