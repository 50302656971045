import { apiInstance } from '../apiInstance';
import { API_POINTS } from '../Constant';

export const getNetworkStatus = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_STATUS)
    .then(res => res.data);
};

export const getNetworkStatusCount = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_STATUS_COUNT, payload)
    .then(res => res.data);
};

export const getLocation = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_FILTER_LOCATION, { params: payload })
    .then(res => res.data);
};
export const getServiceCount = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SERVICE_COUNT, { params: payload })
    .then(res => res.data);
};

export const getNetworkCount = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_COUNT, { params: payload })
    .then(res => res.data);
};
export const getServiceCountInfo = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SERVICE_COUNT_INFO, { params: payload })
    .then(res => res.data);
};

export const getNetworkCountInfo = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_COUNT_INFO, { params: payload })
    .then(res => res.data);
};

export const postFilterSite = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_SITE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.data);
};
export const postFilterNetwork = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_NETWORK, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postFilterServiceRequest = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_SERVICE_REQUEST, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postFilterServiceTrip = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_SERVICE_TRIP, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getSiteManagerInfo = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITE_MANAGER_INFO, { params: payload })
    .then(res => res.data);
};

export const getSiteItemDetail = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITE_ITEM_DETAIL, { params: payload })
    .then(res => res.data);
};
export const getCaseNotesDetail = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITE_CASE_NOTES_DETAIL, { params: payload })
    .then(res => res.data);
};
export const getNotesAndLogs = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTES_COMMUNICATION_LOG, { params: payload })
    .then(res => res.data);
};
export const getTripsItemDetail = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_TRIPS_ITEM_DETAIL, { params: payload })
    .then(res => res.data);
};
export const getVideoUrlDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.VIDEO_VIEWER_URL, { params: payload })
    .then(res => res.data);
};
export const postFilterSystem = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_SYSTEM, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.data);
};
export const getFilters = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_FILTERS)
    .then(res => res.data);
};

export const postSRItemCaseNote = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SR_ITEM_CASENOTE, payload, {
      headers: { 'Content-Type': 'application/json' },
      params: { siteId: payload.siteId },
    })
    .then(response => response.data);
};
export const getSRItemComments = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SR_ITEM_COMMENT, { params: payload })
    .then(res => res.data);
};
export const postSRItemComment = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SR_ITEM_COMMENT, payload, {
      headers: { 'Content-Type': 'application/json' },
      params: { siteId: payload.siteId },
    })
    .then(response => response.data);
};
export const postSRItemEscalate = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.SR_ITEM_ESCALATE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.data);
};
export const postCloseSR = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.SR_CLOSE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => response.data);
};
export const getSRItemInternalNote = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SR_ITEM_INTERNAL_NOTE, { params: payload })
    .then(response => response.data);
};
export const postSRItemInternalNote = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SR_ITEM_INTERNAL_NOTE, payload, {
      headers: { 'Content-Type': 'application/json' },
      params: { siteId: payload.siteId },
    })
    .then(response => response.data);
};
export const postFilterSecurityEvents = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_FILTER_SECURITY_EVENTS, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getEventStatusFilters = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_FILTER_EVENT_STATUS)
    .then(res => res.data);
};
export const getSecurityEventCount = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_ACTIVE_EVENT_COUNT, { params: payload })
    .then(res => res.data);
};
export const getSecurityEventDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_ACTIVE_EVENT_DETAILS, { params: payload })
    .then(res => res.data);
};
export const getSecurityEventZones = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_ACTIVE_EVENT_ZONES, { params: payload })
    .then(res => res.data);
};
export const getSecurityEventCurrentActions = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_ACTIVE_EVENT_CURRENT_ACTIONS, { params: payload })
    .then(res => res.data);
};
export const getSRDownloadedFile = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SR_ITEM_ATTACHMENTS, {
      params: payload,
      headers: { 'Content-Type': 'application/json' },
      responseType: 'blob',
    })
    .then(res => res);
};
