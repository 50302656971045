import { IconsButton } from 'app/component/core/Buttons';
import {
  CustomTableRow,
  CustomTableCell,
} from 'app/component/core/CustomResponsiveListView/style';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import { getFormatedDate, renderText } from 'utils/CommonFn';
import { DATE_FORMAT_4 } from 'utils/Constants';
import { CONTEXT } from 'utils/Constants/Content';

function EventsView({
  row,
  theme,
  source,
  onSiteHandleClick,
  onViewDetailClick,
}: any): any {
  const onSiteClick = (e: any) => {
    if (source !== CONTEXT.SITE_MANAGER) {
      onSiteHandleClick(e, row.siteDetails.Id);
    }
  };
  return (
    <CustomTableRow key={row?.siteDetails?.SiteName} theme={theme}>
      <CustomTableCell theme={theme} component="th" scope="row">
        <span
          className={source !== CONTEXT.SITE_MANAGER ? 'hyperLink' : ''}
          onClick={onSiteClick}
        >
          {row?.siteDetails?.SiteName}
        </span>
      </CustomTableCell>
      <CustomTableCell theme={theme}>
        {renderText(row.EventName)}
      </CustomTableCell>
      <CustomTableCell theme={theme}>
        {renderText(row.EventCategory)}
      </CustomTableCell>
      <CustomTableCell theme={theme}>
        {renderText(
          getFormatedDate(
            row.AlarmTime,
            DATE_FORMAT_4,
            row?.siteDetails?.TimeZone,
          ),
        )}
      </CustomTableCell>
      <CustomTableCell theme={theme}>{renderText(row.Zone)}</CustomTableCell>
      <CustomTableCell theme={theme}>
        {renderText(row.Resolution)}
      </CustomTableCell>
      <CustomTableCell theme={theme}>
        <IconsButton
          name="edit"
          children={<EditIcon />}
          style={{
            background: COLORS.iconBgColor,
            borderRadius: 4,
          }}
          OnIconClick={() => onViewDetailClick(row)}
          isShowTooltip={true}
          title="View Details"
        />
      </CustomTableCell>
    </CustomTableRow>
  );
}
export default EventsView;
