import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';

function SMAllUserContactRowItem({ key, rowItem }: any): any {
  const theme = useTheme();
  const handleClick = (): any => {};

  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.SERIALNO}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem['Customer ID'] ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem['Accounting No'] ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem.Store ? rowItem.Store : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={3}>
        <OverflowTip text={rowItem['Customer Name'] ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem['User Name'] ? rowItem['User Name'] : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem['User Slot']}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default SMAllUserContactRowItem;
