import { Grid } from '@mui/material';
import { HeaderLabel, HeaderSort, SortIcon } from './style';
import { TableHeaderProps } from './type';
import { ROW_WIDTH, SORT_BY } from 'utils/Constants';
import { shallowEqual, useSelector } from 'react-redux';
import { getDashboardFilter } from 'app/features/DashboardSlice/Selectors';
import { useMobileDevice } from 'utils/CommonFn';
import { useLocation } from 'react-router-dom';
import { getSecurityManagerFilter } from 'app/features/GlobalSlice/Selectors';
import CustomTooltip from '../Tooltip';
import NetworkIcon from 'assets/component/ServicesIcons/NetworkIcon';
import IntrusionIcon from 'assets/component/ServicesIcons/IntrusionIcon';
import VoiceIcon from 'assets/component/ServicesIcons/VoiceIcon';
import FireIcon from 'assets/component/ServicesIcons/FireIcon';
import AccessControlIcon from 'assets/component/ServicesIcons/AccessControlIcon';
import FireIntrusionIcon from 'assets/component/ServicesIcons/FireIntrusionIcon';
import VideoIcon from 'assets/component/ServicesIcons/VideoIcon';
import { isEmpty } from 'utils/CommonFn/validators';

const ICON_MAPPING: any = {
  NetworkIcon,
  IntrusionIcon,
  VoiceIcon,
  VideoIcon,
  FireIcon,
  AccessControlIcon,
  FireIntrusionIcon,
};

function HeaderItem({
  label,
  value,
  columnSpan,
  theme,
  isFirst = false,
  item,
  isSort = false,
  onSortClick,
  tooltip = '',
}: TableHeaderProps) {
  const { pathname } = useLocation();
  const IconName = ICON_MAPPING[item.icon];
  const dashboardFilter: any = useSelector(getDashboardFilter, shallowEqual);
  const SMFilter: any = useSelector(getSecurityManagerFilter, shallowEqual);
  const isSMReport =
    (pathname.includes('/reports/AllActivity') ||
      pathname.includes('/reports/userAndContact') ||
      pathname.includes('/reports/OpenAndClose')) ??
    false;

  const { sortColumn, sortBy } = isSMReport ? SMFilter : dashboardFilter;
  const isMobile = useMobileDevice();
  return (
    <Grid
      item
      sm={false}
      md={columnSpan}
      alignItems="center"
      columnGap={2}
      style={{
        cursor: isSort && item.isSort ? 'pointer' : '',
        borderLeft: !isFirst ? '1px solid' : 'none',
        borderColor: theme.palette.text.primary,
        display: 'inline-flex',
        flex: isMobile ? '0 0 auto' : '',
        width: isMobile ? (item?.width ? item.width : ROW_WIDTH) : 'auto',
        position: 'relative',
        justifyContent: item?.icon ? 'center' : 'start',
      }}
    >
      {isSort && item.isSort ? (
        <HeaderLabel
          theme={theme}
          style={{
            paddingLeft: item?.icon ? '0' : '6px',
            width: '82%',
            textAlign: item?.icon ? 'center' : 'start',
          }}
          onClick={e => onSortClick(e, item)}
        >
          {item.icon ? (
            <CustomTooltip title={label}>
              <IconName color={theme.palette.text.primary} />
            </CustomTooltip>
          ) : (
            label
          )}
        </HeaderLabel>
      ) : (
        <CustomTooltip
          title={tooltip}
          placement="top"
          disable={isEmpty(tooltip)}
        >
          <HeaderLabel theme={theme}>{label}</HeaderLabel>
        </CustomTooltip>
      )}
      {isSort && item.isSort && (
        <HeaderSort theme={theme} onClick={e => onSortClick(e, item)}>
          {sortBy === SORT_BY.ASC && sortColumn === item.sortColumn && (
            <SortIcon
              theme={theme}
              style={{ transform: 'rotate(-135deg)' }}
            ></SortIcon>
          )}
          {sortBy === SORT_BY.DESC && sortColumn === item.sortColumn && (
            <SortIcon
              theme={theme}
              style={{ transform: 'rotate(45deg)' }}
            ></SortIcon>
          )}
        </HeaderSort>
      )}
    </Grid>
  );
}

export default HeaderItem;
