import {
  DashboardIcon,
  BIntelligenceIcon,
  DeviceIcon,
  InsightsAnalyticsIcon,
  OpenProjectIcon,
  AdminUsersIcon,
  HelpSupportIcon,
  ReportIcon,
  ServiceRequestSidebarIcon,
  PaymentIcon,
} from 'assets/component';
import { MenuProps, SubMenuProps } from './types';
import { ROUTE_PATH } from 'app/pages/Router/constant';

export const ITEMS = [];

export const Administration: SubMenuProps[] = [
  {
    title: 'Permissions',
    route: `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.PERMISSIONS}`,
    elementName: 'Administration',
    elements: [],
  },
  {
    title: 'Users',
    route: `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.USERS}`,
    elementName: 'Administration',
    elements: [],
  },
  {
    title: 'Company & Site',
    route: `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.COMPANY_SITE}`,
    elementName: 'Administration',
    elements: [],
  },
  {
    title: 'Notification',
    route: `/${ROUTE_PATH.ADMIN}/${ROUTE_PATH.NOTIFICATION}`,
    elementName: 'Administration',
    elements: [],
  },
];
export const PartnerPortal: SubMenuProps[] = [
  {
    title: 'Business Intelligence',
    route: `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.BUSINESS_INTELLIGENCE}`,
    elementName: 'PartnerPortal',
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Network & Voice',
    route: `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.MANAGED_NETWORK}`,
    elementName: 'PartnerPortal',
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Business Security',
    route: `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.ACCESS_ALARM}`,
    elementName: 'PartnerPortal',
    elements: ITEMS,
    isSelected: false,
  },
];
export const SecurityManagerReport: SubMenuProps[] = [
  {
    title: 'All Activity',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_ALL_ACTIVITY_REPORT}`,
    elementName: 'All Activity',
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Open/Close',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_OPEN_CLOSE_REPORT}`,
    elementName: 'Security Manager Reporting',
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'User and Contact',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_USER_CONTACTS}`,
    elementName: 'Security Manager Reporting',
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Contact List Activity',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_PANEL_CONTACT_ACTIVITY}`,
    elementName: 'Security Manager Reporting',
    elements: ITEMS,
    isSelected: false,
  },
];
export const Report: SubMenuProps[] = [
  {
    title: 'Export Data',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.EXPORT_REPORT}`,
    elementName: 'Report',
    elements: ITEMS,
  },
  {
    title: 'Open Service Requests',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.REPORT_SERVICE_REQUEST}`,
    elementName: 'Report',
    elements: ITEMS,
  },
  {
    title: 'Security Manager',
    route: `/${ROUTE_PATH.REPORTS}/${ROUTE_PATH.SECURITY_MANAGER_REPORT}/${ROUTE_PATH.SM_ALL_ACTIVITY_REPORT}`,
    elementName: 'Security Manager',
    elements: SecurityManagerReport,
    isSelected: false,
  },
];
export const BASE_ROUTES: MenuProps[] = [
  {
    title: 'Dashboard',
    route: `/${ROUTE_PATH.DASHBOARD}`,
    icon: <DashboardIcon />,
    section: true,
    elements: [],
    isSelected: false,
  },
  {
    title: 'Open a Service Request',
    route: `/${ROUTE_PATH.OPEN_SERVICE_REQUEST}`,
    icon: <ServiceRequestSidebarIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Insight and Analytics',
    route: `/${ROUTE_PATH.INSIGHTS_ANALYTICS}`,
    icon: <InsightsAnalyticsIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Partner Portals',
    route: `/${ROUTE_PATH.PARTNER}`,
    icon: <BIntelligenceIcon />,
    section: true,
    elements: PartnerPortal,
    isSelected: false,
  },
  {
    title: 'Devices',
    route: `/${ROUTE_PATH.DEVICES}`,
    icon: <DeviceIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'My Open Project',
    route: `/${ROUTE_PATH.MY_OPEN_PROJECTS}`,
    icon: <OpenProjectIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Administration',
    route: `/${ROUTE_PATH.ADMIN}`,
    icon: <AdminUsersIcon />,
    section: true,
    elements: Administration,
    isSelected: false,
  },
  {
    title: 'Report',
    route: `/${ROUTE_PATH.REPORTS}`,
    icon: <ReportIcon />,
    section: true,
    elements: Report,
    isSelected: false,
  },
  {
    title: 'Online Payments',
    route: ROUTE_PATH.ONLINE_PAYMENTS,
    icon: <PaymentIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
  {
    title: 'Help and Support',
    route: `/${ROUTE_PATH.HELP_SUPPORT}`,
    icon: <HelpSupportIcon />,
    section: true,
    elements: ITEMS,
    isSelected: false,
  },
];
