import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { RoundTick, FailRoundIcon } from 'assets/component';
import { COLORS } from 'styles/colors';

function BulkUploadedsmUserStatusRowItem({
  key,
  rowItem,
  customRowStyle = {},
}: any): any {
  const theme = useTheme();
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Success':
        return <RoundTick color={COLORS.lightGreen} />;
      case 'Failed':
        return <FailRoundIcon />;
      default:
        break;
    }
  };
  return (
    <TableRow
      container
      key={key}
      alignContent="center"
      theme={theme}
      sx={{ ...customRowStyle }}
    >
      <TableCell item xs={3}>
        <OverflowTip
          text={rowItem.siteName ? rowItem.siteName : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.name ? rowItem.name : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem.action ? rowItem.action : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        {rowItem.status && (
          <span
            style={{
              display: 'flex',
              padding: '0.313rem',
              alignItems: 'center',
            }}
          >
            {getStatusIcon(rowItem.status)}
          </span>
        )}
        <OverflowTip text={rowItem.status ? rowItem.status : '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={3}>
        <OverflowTip text={rowItem.remark ? rowItem.remark : '-'}></OverflowTip>
      </TableCell>
    </TableRow>
  );
}
export default BulkUploadedsmUserStatusRowItem;
