import { lazy } from 'react';
// login-Logout
import { Login } from 'app/pages/LoginPage';
import { Logout } from 'app/pages/Logout';
// Dashboard
import { Dashboard } from 'app/pages/Dashboard';
const TermsConditionAgreement = lazy(
  async () =>
    await import('app/pages/LoginPage/component/TermsConditionAgreement'),
);
const UserProfile = lazy(
  async () => await import('app/pages/Dashboard/component/UserProfile'),
);
const SiteManager = lazy(
  async () => await import('app/pages/Dashboard/component/SiteManager'),
);
const ItemDetails = lazy(
  async () =>
    await import('app/pages/Dashboard/component/SiteManager/ItemDetails'),
);
const TripsItemDetails = lazy(
  async () =>
    await import('app/pages/Dashboard/component/SiteManager/TripsItemDetails'),
);
const UserProfileNotification = lazy(
  async () =>
    await import('app/pages/Dashboard/component/UserProfile/Notification'),
);
const OpenServiceRequest = lazy(
  async () => await import('app/pages/OpenServiceRequest'),
);
const InsightAndAnalytics = lazy(
  async () => await import('app/pages/InsightAndAnalytics'),
);
const AnalyticsCharts = lazy(
  async () => await import('app/pages/InsightAndAnalytics/analyticsCharts'),
);
// partner
const ManagedNetwork = lazy(
  async () => await import('app/pages/Partner/ManagedNetwork'),
);
const BusinessSecurity = lazy(
  async () => await import('app/pages/Partner/BusinessSecurity'),
);
const BusinessIntelligence = lazy(
  async () => await import('app/pages/Partner/businessIntelligence'),
);
const Devices = lazy(async () => await import('app/pages/Devices'));
const MyOpenProject = lazy(async () => await import('app/pages/MyOpenProject'));
// Admin
const Users = lazy(async () => await import('app/pages/admin/Users'));
const Permission = lazy(async () => await import('app/pages/admin/Permission'));
const CompanySite = lazy(
  async () => await import('app/pages/admin/CompanySite'),
);
const AddUser = lazy(
  async () => await import('app/pages/admin/addEditUser/AddUser'),
);
const EditUser = lazy(
  async () => await import('app/pages/admin/addEditUser/EditUser'),
);
const Notification = lazy(
  async () => await import('app/pages/admin/Notification'),
);
const AddNotification = lazy(
  async () => await import('app/pages/admin/Notification/AddNotification'),
);
const AddNewCompany = lazy(
  async () =>
    await import(
      'app/pages/admin/CompanySite/component/Tabs/TabPanels/CompanyPanel/AddNewCompany'
    ),
);
const ViewSiteDetails = lazy(
  async () =>
    await import(
      'app/pages/admin/CompanySite/component/Tabs/TabPanels/SitesPanel/ViewSiteDetails'
    ),
);
const AddSite = lazy(
  async () =>
    await import(
      'app/pages/admin/CompanySite/component/Tabs/TabPanels/SitesPanel/AddSite'
    ),
);

const AddSiteGroup = lazy(
  async () =>
    await import(
      'app/pages/admin/CompanySite/component/Tabs/TabPanels/SitesGroupPanel/AddSiteGroup'
    ),
);
const ChronicProfile = lazy(
  async () =>
    await import(
      'app/pages/admin/CompanySite/component/Tabs/TabPanels/ChronicPanel/AddChronicProfile'
    ),
);
const Preview = lazy(
  async () => await import('./admin/component/BulkUpload/Preview'),
);
// report
const Report = lazy(async () => await import('app/pages/Report'));
const AllActivity = lazy(
  async () => await import('app/pages/Report/SecurityManager/AllActivity'),
);
const OpenAndClose = lazy(
  async () =>
    await import('app/pages/Report/SecurityManager/OpenCloseActivity'),
);
const UserAndContact = lazy(
  async () => await import('app/pages/Report/SecurityManager/UserAndContact'),
);
const PanelContactActivity = lazy(
  async () =>
    await import('app/pages/Report/SecurityManager/PanelContactActivity'),
);
// const SecurityManagerReport = lazy(
//   async () => await import('app/pages/Report/SecurityManager/Tabs/tabindex'),
// );
// help & support
const Help = lazy(async () => await import('app/pages/Help'));
const NewRelase = lazy(
  async () => await import('app/pages/HelpAndSupport/NewRelase'),
);
const SubmitIdea = lazy(
  async () => await import('app/pages/HelpAndSupport/SubmitIdea'),
);
const HelpAndSupport = lazy(
  async () => await import('app/pages/HelpAndSupport'),
);
const SupportDownload = lazy(
  async () => await import('app/pages/HelpAndSupport/SupportDownload'),
);
// Security Manager
const SecurityManager = lazy(
  async () => await import('app/pages/SecurityManager'),
);
const AddContact = lazy(
  async () =>
    await import('app/pages/SecurityManager/component/Contact/AddContact'),
);
// Projector View
const ProjectorView = lazy(async () => await import('app/pages/ProjectorView'));
const SecurityEventDetails = lazy(
  async () =>
    await import(
      'app/pages/Dashboard/component/SiteManager/SecurityEventDetails'
    ),
);
const ELearning = lazy(
  async () => await import('app/pages/HelpAndSupport/ELearning'),
);
const SelfTest = lazy(
  async () =>
    await import('app/pages/Dashboard/component/SiteManager/SelfTest'),
);
const HowToTest = lazy(
  async () =>
    await import(
      'app/pages/Dashboard/component/SiteManager/SelfTest/HowToTest'
    ),
);
const OpenServiceRequestReport = lazy(
  async () => await import('app/pages/Report/OpenServiceRequest'),
);

export {
  Login,
  Logout,
  Dashboard,
  UserProfile,
  SiteManager,
  ItemDetails,
  TripsItemDetails,
  UserProfileNotification,
  OpenServiceRequest,
  InsightAndAnalytics,
  AnalyticsCharts,
  ManagedNetwork,
  BusinessSecurity,
  BusinessIntelligence,
  Devices,
  MyOpenProject,
  Users,
  Permission,
  CompanySite,
  AddUser,
  EditUser,
  Notification,
  AddNotification,
  AddNewCompany,
  ViewSiteDetails,
  AddSite,
  AddSiteGroup,
  ChronicProfile,
  Preview,
  Report,
  AllActivity,
  OpenAndClose,
  UserAndContact,
  // SecurityManagerReport,
  Help,
  NewRelase,
  SubmitIdea,
  HelpAndSupport,
  SupportDownload,
  SecurityManager,
  AddContact,
  ProjectorView,
  SecurityEventDetails,
  ELearning,
  TermsConditionAgreement,
  SelfTest,
  HowToTest,
  OpenServiceRequestReport,
  PanelContactActivity,
};
