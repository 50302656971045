import {
  TableContainer,
  TableSortLabel,
  TableCell,
  TableRow,
  useMediaQuery,
  TableHead,
  tableCellClasses,
  Table,
  TableBody,
} from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const CustomTable = styled(Table)(({ theme }) => ({
  backgroundColor: `${theme.palette.customBackground.table} !important`,
}));

export const CustomTableBody = styled(TableBody)(() => ({}));

export const Container = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
  height: 540,
  padding: '0rem 0.85rem',
  borderBottom: `1px soild ${COLORS.solitude}`,
  [theme.breakpoints.down('xl')]: {
    height: 300,
  },
  backgroundColor: `${theme.palette.customBackground.table} !important`,
  borderRadius: '0.5rem',
  overflow: 'auto',
  width: '100%',
  '::-webkit-scrollbar': {
    height: '10px',
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },
  'scrollbar-color': theme.palette.customBackground.scroll,
  'scrollbar-width': 'auto',
}));

export const CustomTableSortLabel = styled(TableSortLabel)<any>(
  ({ theme, index }) => ({
    borderLeft: index === 0 ? 'none' : '1px solid !important',
    paddingLeft: '5px !important',
    '& .MuiTableSortLabel-icon': {
      color: `${theme.palette.text.primary} !important`,
    },
  }),
);
export const CustomTableLabel = styled('div')<any>(({ index }) => ({
  borderLeft: index === 0 ? 'none' : '1px solid !important',
  paddingLeft: '5px !important',
}));

export const CustomTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableSortLabel-root:hover, .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon, .MuiTableSortLabel-root.Mui-active:hover, .MuiTableSortLabel-root.Mui-active:hover .MuiTableSortLabel-icon':
    {
      color: `${theme.palette.text.primary} !important`,
    },
}));

export const CustomRow = styled(TableRow)``;

export const CustomCell = styled(TableCell)`
  height: 28.12rem !important;
  text-align: center !important;
  border-bottom: none !important;
`;

export const CustomTableRow = styled(TableRow)<any>(({ theme }) => ({
  borderBottom: `1px soild ${COLORS.solitude}`,
  background: theme.palette.customBackground.main,
  height: '2.813rem',
  whiteSpace: 'nowrap',
  borderRadius: '0.5rem !important',
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.25rem',
  },
  '.exploreDriverPill': {
    display: 'none',
  },
  '&:hover': {
    background:
      theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.titanWhite,
    '.exploreDriverPill': {
      display: 'flex',
    },
  },
}));
export const CustomTableCell = styled(TableCell)<any>(
  ({ theme, isSort = false }) => ({
    padding: '0rem !important',
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 700,
      padding: '0.75rem !important',
      cursor: isSort ? 'pointer !important' : 'auto',
      borderBottom: `1.5px solid ${COLORS.white} !important`,
      backgroundColor: `${theme.palette.customBackground.table} !important`,
    },
    [`&.${tableCellClasses.body}`]: {
      paddingLeft: '1.375rem !important',
      borderBottom: 'none !important',
    },
  }),
);
export const ActionContainer = styled('div')`
  display: inline-block;
  padding-right: 0.3125rem;
`;
