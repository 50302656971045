import { memo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  CustomTableRow,
  CustomTableCell,
} from 'app/component/core/CustomResponsiveListView/style';

const ListViewSkeleton = ({ item = 12, theme, headerCount = 12 }: any): any => {
  return Array.from({ length: item }).map((_, index) => (
    <CustomTableRow theme={theme} key={index}>
      {Array.from({ length: headerCount }).map((_, index) => (
        <>
          <CustomTableCell theme={theme}>
            <Skeleton variant="text" width={index % 2 === 0 ? '50%' : '60%'} />
          </CustomTableCell>
        </>
      ))}
    </CustomTableRow>
  ));
};

export default memo(ListViewSkeleton);
