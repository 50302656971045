import { Grid } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const LayoutWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;
export const ErrorLabel = styled('div')(() => ({
  width: '100%',
  fontSize: '0.75rem',
  color: COLORS.lightRed,
  marginLeft: '0.625rem',
}));
export const GridContainer = styled(Grid)<any>(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: '100%',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));
export const ItemGrid = styled(Grid)<any>(() => ({
  padding: '0.5rem',
}));
export const GlobalLabel = styled<any>(Grid)(({ theme }) => ({
  padding: '0.313rem 1.25rem',
  borderRadius: '1rem',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.mode === 'dark' ? COLORS.jaguar : COLORS.white,
}));

export const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;

export const CustomIframe = styled.iframe(({ theme }) => ({
  border: 'none',
  width: '100%',
  height: window.innerHeight - 200,
  overflowy: 'auto',
  overflowx: 'hidden',
}));
