import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { SecurityManagerState } from './types';
import { ToastAction } from 'app/component/core/ToastAlert/types';
import { CONTEXT } from 'utils/Constants/Content';
import { addKey, uniqueId } from 'utils/CommonFn';
import { DataMaptoArray } from '../utils';
import {
  selfTestProps,
  testStatus,
} from 'app/pages/Dashboard/component/SiteManager/types';

export const initialState: SecurityManagerState = {
  securitySiteInfo: {
    loading: false,
    data: [],
    status: '',
  },
  siteOnTest: {
    loading: false,
    status: '',
    data: [],
  },
  deleteSiteTest: {
    loading: false,
    status: '',
    data: [],
  },
  securityCallContactInfo: {
    loading: false,
    data: [],
  },
  callContactList: {
    loading: false,
    data: [],
  },
  putCallList: {
    loading: false,
    status: '',
    data: [],
  },
  userCallList: [],
  userContactList: [],
  toastInfo: {
    showToast: false,
    toastMessage: '',
    toastType: undefined,
  },
  contactList: {
    loading: false,
    data: [],
    status: '',
  },
  contactDetails: {
    loading: false,
    data: [],
    status: '',
  },
  addContact: {
    loading: false,
    data: [],
  },
  selectedSiteName: '',
  passcodeEmail: {
    loading: false,
    status: '',
    data: [],
  },
  adminSecurityConfig: {
    loading: false,
    data: [],
  },
  updateSecurityConfig: {
    loading: false,
    status: '',
    data: [],
  },
  securityManagerJobRole: { jobRoleList: [], lockList: false },
  securityManagerRestrictedPassword: [],
  securityManagerJobTitle: [],
  securityManagerAdminConfig: {
    loading: false,
    data: [],
  },
  putsecurityManagerAdminConfig: {
    loading: false,
    status: '',
    data: [],
  },
  putSiteSecurityManagerAdminConfig: {
    loading: false,
    status: '',
    data: [],
  },
  companyProfile: {
    loading: false,
    data: [],
  },
  securityManagerActivityLogTypes: {
    loading: false,
    data: [],
  },
  securityManagerActivityLog: {
    loading: false,
    data: [],
  },
  securityManagerActivityLogExport: {
    loading: false,
    data: [],
  },
  securityManagerUser: {
    loading: false,
    data: [],
  },
  updateSecurityManagerUser: {
    loading: false,
    data: [],
    status: '',
  },
  deleteSecurityManagerUser: {
    loading: false,
    data: [],
    status: '',
  },
  securityManagerZoneList: {
    loading: false,
    data: [],
  },
  securityManagerAlarmList: {
    loading: false,
    data: [],
  },
  securityManagerSiteGroupList: {
    data: [],
    loading: false,
  },
  selectedSiteGroup: null,
  selfTestReport: {
    loading: false,
    data: [],
    status: '',
  },
  postSelfTestSensor: {
    loading: false,
    data: [],
    reportId: '',
    isSystemOnTest: false,
    siteOnTest: {
      utcFrom: '',
      utcTo: '',
      message: '',
    },
  },
  patchSelfTestSensor: {
    loading: false,
    data: [],
  },
  selfTestDetailReport: {
    loading: false,
    data: {
      intrusion: [],
      panic: [],
      isSystemOnTest: false,
      siteId: '',
      affectedSystem: '',
      description: '',
      date: '',
      reportId: '',
      result: [],
      testResult: [],
      zoneType: [],
      selfTestStartTime: '',
    },
  },
  selfTestZoneList: {
    loading: false,
    data: {
      intrusion: [],
      panic: [],
    },
  },
  selfTestZoneStatus: {
    loading: false,
    data: {
      intrusion: [],
      panic: [],
      isSystemOnTest: false,
      siteId: '',
      affectedSystem: '',
      description: '',
      date: '',
      reportId: '',
      result: [],
      testResult: [],
      zoneType: [],
      selfTestStartTime: '',
    },
    statusList: {
      passZone: [],
      failedZone: [],
    },
  },
  activeSelfTestStatus: {
    loading: false,
    data: {
      intrusion: [],
      panic: [],
      isSystemOnTest: false,
      siteId: '',
      affectedSystem: '',
      description: '',
      date: '',
      reportId: '',
      result: [],
      testResult: [],
      zoneType: [],
      selfTestStartTime: '',
    },
  },
  selfTestProps: {
    siteId: '',
    reportId: '',
    isSystemOnTest: false,
    userTerminated: false,
    timeExpired: false,
    timeExtended: false,
    testProgress: '',
    testResult: [],
    srNumber: '',
    srCreated: false,
    selectedZones: {
      intrusion: [],
      panic: [],
    },
    IndividualReport: {
      reportId: '',
      siteId: 0,
      page: 1,
      limit: 10,
      zoneId: '',
      areaId: '',
      zoneName: '',
      zoneType: [],
      result: [],
    },
    affectedSystem: 'Intrusion',
    selfTestStartTime: '',
    endTime: '',
    isExtended: false,
    loading: false,
    modifiedZones: false,
    extendFailedMessage: '',
    statusList: {
      passZone: [],
      failedZone: [],
    },
  },
  globalUserAffectedSite: {
    data: [],
    loading: false,
  },
  deleteGlobalUser: {
    data: [],
    loading: false,
    status: '',
  },
  securityManagerUserFilter: {
    page: 1,
    selectedFilter: {
      id: 1,
      label: 'Employee Name',
      value: 'employeeName',
      isChecked: true,
    },
    searchedText: '',
    globalOnly: false,
  },
};

const SecurityManagerSlice = createSlice({
  name: 'securityManager',
  initialState,
  reducers: {
    fetchSecuritySiteInfo(state, action: PayloadAction<any>) {
      state.securitySiteInfo = {
        ...state.securitySiteInfo,
        loading: true,
      };
    },
    updateSecuritySiteInfo(state, action: PayloadAction<any>) {
      state.securitySiteInfo = {
        ...state.securitySiteInfo,
        ...action.payload,
      };
    },
    putSiteOnTest(state, action: PayloadAction<any>) {
      state.siteOnTest = {
        ...state.siteOnTest,
        status: 'pending',
        loading: true,
      };
    },
    updatePutSiteOnTest(state, action: PayloadAction<any>) {
      state.siteOnTest = {
        ...state.siteOnTest,
        ...action.payload,
      };
    },
    deleteSiteTest(state, action: PayloadAction<any>) {
      state.deleteSiteTest = {
        ...state.deleteSiteTest,
        status: 'pending',
        loading: true,
      };
    },
    updateDeleteSiteTest(state, action: PayloadAction<any>) {
      state.deleteSiteTest = {
        ...state.deleteSiteTest,
        ...action.payload,
      };
    },
    displayToast(state, action: PayloadAction<ToastAction>) {
      state.toastInfo.showToast = true;
      state.toastInfo.toastMessage = action.payload.toastMessage;
      state.toastInfo.toastType = action.payload.toastType;
    },
    fetchSecurityManagerCallContactList(state, action: PayloadAction<any>) {
      state.securityCallContactInfo = {
        ...state.securityCallContactInfo,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerCallContactList(state, action: PayloadAction<any>) {
      state.securityCallContactInfo = {
        ...state.securityCallContactInfo,
        ...action.payload,
      };
    },
    putSecurityManagerCallList(state, action: PayloadAction<any>) {
      state.putCallList = {
        ...state.putCallList,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updatePutSecurityManagerCallList(state, action: PayloadAction<any>) {
      state.putCallList = {
        ...state.putCallList,
        ...action.payload,
      };
    },
    updateUserCallList(state, action: PayloadAction<any>) {
      state.userCallList = [...action.payload];
    },
    updateUserContactList(state, action: PayloadAction<any>) {
      state.userContactList = [...action.payload];
    },
    fetchAddContact(state, action: PayloadAction<any>) {
      state.addContact = { ...state.addContact, loading: true };
    },
    updateCreatedContact(state, action: PayloadAction<any>) {
      state.addContact = {
        ...state.addContact,
        ...action.payload,
      };
    },
    fetchDeleteContact(state, action: PayloadAction<any>) {
      state.contactList = { ...state.contactList, loading: true };
    },
    updateDeleteContact(state, action: PayloadAction<any>) {
      state.contactList = {
        ...state.contactList,
        ...action.payload,
      };
    },
    fetchEditContact(state, action: PayloadAction<any>) {
      state.addContact = { ...state.addContact, loading: true };
    },
    updateEditContact(state, action: PayloadAction<any>) {
      state.addContact = {
        ...state.addContact,
        ...action.payload,
      };
    },
    fetchContactDetails(state, action: PayloadAction<any>) {
      state.contactDetails = {
        ...state.contactDetails,
        loading: true,
        status: '',
      };
    },
    updateContactDetails(state, action: PayloadAction<any>) {
      state.contactDetails = {
        ...state.contactDetails,
        ...action.payload,
      };
    },
    sendPasscodeEmail(state, action: PayloadAction<any>) {
      state.passcodeEmail = {
        ...state.passcodeEmail,
        status: CONTEXT.PENDING,
        loading: true,
      };
    },
    updatePasscodeEmail(state, action: PayloadAction<any>) {
      state.passcodeEmail = {
        ...state.passcodeEmail,
        ...action.payload,
      };
    },
    updateSelectedSecuritySite(state, action: PayloadAction<any>) {
      state.selectedSiteName = action.payload;
    },
    fetchCallContactList(state, action: PayloadAction<any>) {
      state.callContactList = {
        ...state.callContactList,
        data: [],
        loading: true,
      };
    },
    updateCallContactList(state, action: PayloadAction<any>) {
      state.callContactList = {
        ...state.callContactList,
        ...action.payload,
      };
    },
    updateSecurityManagerJobRole(state, action: PayloadAction<any>) {
      state.securityManagerJobRole = action.payload;
    },
    updateSecurityManagerRestrictedPassword(state, action: PayloadAction<any>) {
      state.securityManagerRestrictedPassword = action.payload;
    },
    updateSecurityManagerJobTitle(state, action: PayloadAction<any>) {
      state.securityManagerJobTitle = action.payload;
    },
    fetchSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.securityManagerAdminConfig = {
        ...state.securityManagerAdminConfig,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.securityManagerAdminConfig = {
        ...state.securityManagerAdminConfig,
        ...action.payload,
      };
    },
    putAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putsecurityManagerAdminConfig = {
        ...state.putsecurityManagerAdminConfig,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updateAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putsecurityManagerAdminConfig = {
        ...state.putsecurityManagerAdminConfig,
        ...action.payload,
      };
    },
    putSiteAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putSiteSecurityManagerAdminConfig = {
        ...state.putSiteSecurityManagerAdminConfig,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updateSiteAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putSiteSecurityManagerAdminConfig = {
        ...state.putSiteSecurityManagerAdminConfig,
        ...action.payload,
      };
    },
    fetchCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        data: [],
        loading: true,
      };
    },
    updateCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...action.payload,
      };
    },
    fetchSecurityManagerActivityLogTypes(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogTypes = {
        ...state.securityManagerActivityLogTypes,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerActivityLogTypes(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogTypes = {
        ...state.securityManagerActivityLogTypes,
        ...action.payload,
      };
    },
    fetchSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLog = {
        ...state.securityManagerActivityLog,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLog = {
        ...state.securityManagerActivityLog,
        ...action.payload,
      };
    },
    exportSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogExport = {
        ...state.securityManagerActivityLogExport,
        data: [],
        loading: true,
      };
    },
    updateExportSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogExport = {
        ...state.securityManagerActivityLogExport,
        ...action.payload,
      };
    },
    fetchSecurityManagerUser(state, action: PayloadAction<any>) {
      state.securityManagerUser = {
        ...state.securityManagerUser,
        data: [],
        loading: true,
        recordCount: 0,
      };
    },
    updateSecurityManagerUserList(state, action: PayloadAction<any>) {
      state.securityManagerUser = {
        ...state.securityManagerUser,
        ...action.payload,
      };
    },
    putSecurityManagerUser(state, action: PayloadAction<any>) {
      state.updateSecurityManagerUser = {
        ...state.updateSecurityManagerUser,
        data: [],
        loading: true,
      };
    },
    updatePutSecurityManageruser(state, action: PayloadAction<any>) {
      state.updateSecurityManagerUser = {
        ...state.updateSecurityManagerUser,
        ...action.payload,
      };
    },
    deleteSecurityManagerUser(state, action: PayloadAction<any>) {
      state.deleteSecurityManagerUser = {
        ...state.deleteSecurityManagerUser,
        data: [],
        loading: true,
      };
    },
    updateDeleteSecurityManagerUser(state, action: PayloadAction<any>) {
      state.deleteSecurityManagerUser = {
        ...state.deleteSecurityManagerUser,
        ...action.payload,
      };
    },
    fetchSecurityManagerZoneList(state, action: PayloadAction<any>) {
      state.securityManagerZoneList = {
        ...state.securityManagerZoneList,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerZoneList(state, action: PayloadAction<any>) {
      state.securityManagerZoneList = {
        ...state.securityManagerZoneList,
        ...action.payload,
      };
      if (state.securityManagerZoneList?.data != null) {
        state.securityManagerZoneList.data =
          state.securityManagerZoneList?.data.map((item: any) => {
            return {
              isDisabled: false,
              status: '',
              uniqId: uniqueId(),
            };
          });
      }
    },
    fetchSecurityManagerAlarmList(state, action: PayloadAction<any>) {
      state.securityManagerAlarmList = {
        ...state.securityManagerAlarmList,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerAlarmList(state, action: PayloadAction<any>) {
      state.securityManagerAlarmList = {
        ...state.securityManagerAlarmList,
        ...action.payload,
      };
    },
    fetchSMUserSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        loading: true,
      };
    },
    updateSMUserSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        ...action.payload,
      };
    },
    updateSelectedSiteGroup(state, action: PayloadAction<any>) {
      state.selectedSiteGroup = {
        ...state.selectedSiteGroup,
        ...action.payload,
      };
    },
    updateSMUserFilter(state, action: PayloadAction<any>) {
      state.securityManagerUserFilter = {
        ...state.securityManagerUserFilter,
        ...action.payload,
      };
    },
    clearSelectedSiteGroup(state, action: PayloadAction<any>) {
      state.selectedSiteGroup = null;
    },
    fetchSelfTestZoneList(state, action: PayloadAction<any>) {
      state.selfTestZoneList = {
        ...state.selfTestZoneList,
        data: [],
        loading: true,
      };
    },
    updateSelfTestZones(state, action: PayloadAction<any>) {
      state.selfTestZoneList = {
        ...state.selfTestZoneList,
        ...action.payload,
      };
      if (state.selfTestZoneList.data) {
        const intrusion = state.selfTestZoneList.data.intrusion ?? [];
        const panic = state.selfTestZoneList.data.panic ?? [];
        state.selfTestZoneList.data.intrusion = addKey(
          intrusion,
          'isSelected',
          false,
        );
        state.selfTestZoneList.data.panic = addKey(panic, 'isSelected', false);
      }
    },
    fetchGetSelfTestReport(state, action: PayloadAction<any>) {
      state.selfTestReport = {
        ...state.selfTestReport,
        loading: true,
      };
    },
    updateGetSelfTestReport(state, action: PayloadAction<any>) {
      state.selfTestReport = {
        ...state.selfTestReport,
        ...action.payload,
      };
    },
    fetchSelfTestDetailReport(state, action: PayloadAction<any>) {
      state.selfTestDetailReport = {
        ...state.selfTestDetailReport,
        data:
          action.payload.mode === 'export'
            ? state.selfTestDetailReport.data
            : {
                intrusion: [],
                panic: [],
                isSystemOnTest: false,
                siteId: '',
                affectedSystem: '',
                description: '',
                date: '',
                reportId: '',
                result: [],
                testResult: [],
                zoneType: [],
                selfTestStartTime: '',
              },
        loading: true,
      };
    },
    updateSelfTestDetailReport(state, action: PayloadAction<any>) {
      state.selfTestDetailReport = {
        ...state.selfTestDetailReport,
        ...action.payload,
      };
      if (state.selfTestDetailReport.data != null) {
        state.selfTestDetailReport.data.result = DataMaptoArray([
          state.selfTestDetailReport.data.result,
        ])[0];
        state.selfTestDetailReport.data.zoneType = DataMaptoArray([
          state.selfTestDetailReport.data.zoneType,
        ])[0];
      }
    },
    fetchGetSelfTestStatus(state, action: PayloadAction<any>) {
      state.selfTestZoneStatus = {
        ...state.selfTestZoneStatus,
        loading: false,
      };
    },
    fetchGetSelfTestExtend(state, action: PayloadAction<any>) {
      state.selfTestZoneStatus = {
        ...state.selfTestZoneStatus,
        loading: true,
      };
    },
    fetchGetSelfTestSubmit(state, action: PayloadAction<any>) {
      state.selfTestZoneStatus = {
        ...state.selfTestZoneStatus,
        loading: true,
      };
    },
    updateGetSelfTestStatus(state, action: PayloadAction<any>) {
      state.selfTestZoneStatus = {
        ...state.selfTestZoneStatus,
        ...action.payload,
      };
      if (
        state.selfTestZoneStatus.data.testResult !== null &&
        state.selfTestZoneStatus.data.testResult.length > 0
      ) {
        const passZone = state.selfTestZoneStatus.data.testResult.filter(
          (c: testStatus) => c.status === 'pass',
        );
        const failedZone: testStatus[] =
          state.selfTestZoneStatus.data.testResult
            .filter((c: testStatus) => c.status === 'fail')
            .map((it: any) => {
              return {
                ...it,
                isSelected: true,
              };
            });
        state.selfTestZoneStatus.statusList = {
          passZone,
          failedZone,
        };
      }
    },
    updateGetActiveSelfTestStatus(state, action: PayloadAction<any>) {
      state.activeSelfTestStatus = {
        ...state.activeSelfTestStatus,
        ...action.payload,
      };
    },
    fetchPostSelfTestSensors(state, action: PayloadAction<any>) {
      state.postSelfTestSensor = {
        ...state.postSelfTestSensor,
        loading: true,
      };
    },
    updatePostSelfTestSensors(state, action: PayloadAction<any>) {
      state.postSelfTestSensor = {
        ...state.postSelfTestSensor,
        ...action.payload,
      };
    },
    fetchPatchSelfTestSensors(state, action: PayloadAction<any>) {
      state.patchSelfTestSensor = {
        ...state.patchSelfTestSensor,
        loading: true,
      };
    },
    updatePatchSelfTestSensors(state, action: PayloadAction<any>) {
      state.patchSelfTestSensor = {
        ...state.patchSelfTestSensor,
        ...action.payload,
      };
    },
    updateSelfTestProps(state, action: PayloadAction<selfTestProps>) {
      state.selfTestProps = {
        ...state.selfTestProps,
        ...action.payload,
      };
      if (
        state.selfTestProps.testResult !== null &&
        state.selfTestProps.testResult.length > 0
      ) {
        const passZone = state.selfTestProps.testResult.filter(
          (c: testStatus) => c.status === 'pass',
        );
        const failedZone: testStatus[] = state.selfTestProps.testResult
          .filter((c: testStatus) => c.status === 'fail')
          .map((it: any) => {
            return {
              ...it,
              isSelected: true,
            };
          });
        state.selfTestZoneStatus.statusList = {
          passZone,
          failedZone,
        };
      }
    },
    fetchGlobalUserAffectedSite(state, action: PayloadAction<any>) {
      state.globalUserAffectedSite = {
        ...state.globalUserAffectedSite,
        loading: true,
      };
    },
    updateGlobalUserAffectedSite(state, action: PayloadAction<any>) {
      state.globalUserAffectedSite = {
        ...state.globalUserAffectedSite,
        ...action.payload,
      };
    },
    fetchDeleteGlobalUser(state, action: PayloadAction<any>) {
      state.deleteGlobalUser = {
        ...state.deleteGlobalUser,
        status: CONTEXT.PENDING,
        loading: true,
      };
    },
    updateDeleteGlobalUser(state, action: PayloadAction<any>) {
      state.deleteGlobalUser = {
        ...state.deleteGlobalUser,
        ...action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = SecurityManagerSlice;
