export const API_POINTS = {
  // ----------------------------------PRELOGIN-------------------------------
  GET_LOGIN_USER_DETAILS: 'auth/login',
  GET_REFRESH_TOKEN: 'auth/refresh-token',
  REGISTER: '',
  ONLINE_MANUALS: 'help/online-manual-contents',
  TERMS_CONDITION: 'dashboard/agreement',
  ACCEPT_AGREEMENT: 'user/user-agreement',
  // ----------------------------------POSTLOGIN-------------------------------

  // FILTERS
  GET_NETWORK_STATUS: 'dashboard/network-status',
  GET_NETWORK_STATUS_COUNT: 'dashboard/network-status-count',
  GET_FILTER_LOCATION: 'dashboard/cpfilter-dd-location',
  GET_SERVICE_COUNT: 'dashboard/sr-count',
  GET_NETWORK_COUNT: 'dashboard/network-count',
  GET_SERVICE_COUNT_INFO: 'dashboard/sr-count-info',
  GET_NETWORK_COUNT_INFO: 'dashboard/network-count-info',
  GET_STATE_LIST: 'export-data/state-list',
  GET_FILTERS: 'dashboard/cpfilter-dd-all',
  POST_FILTER_SITE: 'dashboard/cpfilter-site',
  POST_FILTER_NETWORK: 'dashboard/cpfilter-network',
  POST_FILTER_SERVICE_REQUEST: 'dashboard/cpfilter-service-request',
  POST_FILTER_SERVICE_TRIP: 'dashboard/cpfilter-service-trip',
  POST_FILTER_SYSTEM: 'dashboard/cpfilter-system',
  GET_SEARCH_ALL: 'dashboard/search-all',
  // customers

  GET_COMPANY_LIST: '/user/company-list',
  GET_COMPANY_SITE_LIST: '/user/site-list',
  GET_SYSTEMS: '/user/system-list',

  // Permission

  GET_PERMISSION: '/permission/',
  POST_CREATE_PERMISSION: '/permission/',
  UPDATE_PERMISSION: '/permission/',
  DELETE_PERMISSION: '/permission/',

  // Permission Group
  GET_PERMISSION_GROUP: '/permissiongroup/',
  POST_CREATE_PERMISSION_GROUP: '/permissiongroup/',
  UPDATE_PERMISSION_GROUP: '/permissiongroup/',
  DELETE_PERMISSION_GROUP: '/permissiongroup/',
  GET_PERMISSION_CUSTOMERS_LIST: 'permissiongroup/user-list',
  GET_SITE_MANAGER_INFO: 'dashboard/site-manager-info',
  GET_SITE_ITEM_DETAIL: '/itemdetail/sr-info',
  POST_PERMISSION_GROUP_CHILD: '/permissiongroup/permission-group-child',

  // GET_SITE_CASE_NOTES_DETAIL: '/dashboard/sr-case-note',
  GET_SITE_CASE_NOTES_DETAIL: '/service-request/case-note',
  GET_NOTES_COMMUNICATION_LOG: '/service-request/notes-communication-logs',
  // user list
  POST_CREATE_USER: '/user',
  POST_USER_LIST: '/user/list',
  GET_USER_DETAILS: 'user/single-user-details',
  PATCH_UPDATE_USER: '/user',
  PUT_SUSPEND_USER: '/user/change-status',
  DELETE_USER: '/user',
  POST_ASSOCIATE_SITE: '/user/associate-site-list',
  POST_USER_GROUP_LIST: 'user/user-group-list',
  GET_USER_SITE_LIST: '/user/site-list',
  GET_USER_COMPANY_LIST: '/user/associate-company-list',
  GET_PERMISSION_GROUP_LIST: 'user/permissiongroup-list',
  GET_TRIPS_ITEM_DETAIL: '/itemdetail/st-info',
  PUT_USER_RESET_PASSWORD: '/user/reset-password',
  GET_USER_GROUP_LIST: 'user/user-group-list',
  GET_USER_PROFILE_VIEW_SETTING: 'user/profile-data-dd',
  GET_USER_PROFILE_INFO: 'user/personal-info',
  PATCH_USER_PROFILE_INFO: 'user/personal-info',

  // permission management
  GET_USER_PERMISSION_LIST: 'user-permission/list-permissions',
  GET_USER_PERMISSION_GROUP_LIST: 'user-permission/list-permission-groups',
  GET_USER_PERMISSION_GROUP_DETAIL: 'user-permission/permission-group/detail',
  POST_USER_NEW_PERMISSION: 'user-permission/permission-group',
  PUT_USER_PERMISSION_GROUP: 'user-permission/permission-group',
  DELETE_USER_PERMISSION_GROUP: 'user-permission/permission-group',

  // permission search
  GET_SEARCH_PERMISSION: '/user-permission/search-permission',
  GET_SEARCH_PERMISSION_GROUP: '/user-permission/search-permission-group',
  GET_SEARCH_PERMISSION_USER: '/user-permission/search-user',

  // user update
  GET_USER_PERMISSION_GROUP_DETAILS: 'user-permission/user-permission-group',
  POST_USER_PERMISSION_GROUP_TO_USER:
    'user-permission/assign-user-permission-group',
  CHECK_USER_PERMISSION_GROUP_STATUS:
    'user-permission/permission-assignment-status',
  DELETE_USER_PERMISSIONS: '/user-permission/permission',
  UPDATE_PERMISSION_GROUP_NAME: '/user-permission/permission-group-name',
  GET_SITES_LIST: '/company-site/site-list',
  GET_SITE_REMARK: '/company-site/remark-list',
  UPDATE_SITE_VISIBILITY: '/company-site/site-visibility',
  UPDATE_COMPANY_SITES: '/company-site/assign-sitegroup',
  SITE_ASSOCIATE_SITE_GROUP: '/company-site/site-for-sitegroup',
  GET_SITE_CONTACT_detail: '/company-site/site-contact-details',
  PATCH_SITE_CONTACT_detail: '/company-site/site-details',
  GET_COMPANY_SITE_CONTACT_detail: '/company-site/company-contact-details',
  PATCH_COMPANY_SITE_CONTACT_detail: '/company-site/company-details',

  GET_BUSINESS_INTELLIGENCE: 'business-intelligence/home',
  GET_BUSINESS_SECURITY: 'access-alarm/home',
  GET_BUSINESS_SECURITY_VVA_combined: 'access-alarm/combined',
  GET_NETWORK_VOICES: '/ucaas/home',
  GET_VIDEO_VERIFIED_ALARM: '/digital-surveillance/home',
  GET_HELP_SUPPORT: '/help/home',
  GET_HELP_DOWNLOAD: 'help/helpful-downloads',
  GET_HELP_ELEARNING: 'help/get-elearning-info',

  GET_CS_SITEGROUP_LIST: '/company-site/sitegroup-list',
  GET_SITES_DETAILS: '/company-site/site-detail',
  GET_COMPANY_ACCT_TYPE: '/company-site/company-account-type',
  GET_PROFILE_PARTY_NUMBER: '/company-site/party-number',
  GET_MASTER_COMPANY_LIST: '/company-site/company-list',
  CREATE_COMPANY_PROFILE: '/company-site/company-profile',
  UPDATE_COMPANY_PROFILE: '/company-site/company-details',

  GET_SITEGROUP_ALL_SITES_CS: 'company-site/sitegroup-for-site',
  UPDATE_CS_SITE_GROUPS: 'company-site/sitegroup',
  POST_CS_SITE_GROUPS: 'company-site/sitegroup',

  GET_SERVICE_REQUEST_BY_CAUSE: 'insight/sr-by-cause',
  GET_SERVICE_REQUEST_BY_SUBJECT: 'insight/sr-by-subject',
  GET_CURRENT_SITE_STATUS: 'insight/current-site-status',
  GET_NETWORK_COUNT_DATE: 'insight/network-count-by-date',
  GET_SERVICE_REQUEST_LIST: 'help/submit-sr-info',
  GET_AFFECTED_SYSTEM: 'help/get-affected-system',
  POST_SERVICE_REQUEST: 'help/service-request',
  GET_NETWORK_TREND: 'insight/network-trend',

  GET_NOTIFICATION_LIST: 'notification/list',
  GET_NOTIFICATION_COMPANY_LIST: '/notification/company-list',
  GET_NOTIFICATION_DETAILS: 'notification/notification-details',
  POST_ADD_NOTIFICATION: '/notification/add',
  PATCH_UPDATE_NOTIFICATION: '/notification/update',
  DELETE_NOTIFICATION: 'notification/delete',
  GET_NOTIFICATION_SITE_GROUP_LIST: '/notification/sitegroup-list',
  GET_NOTIFICATION_SITE_LIST: '/notification/site-list',

  // user Notification
  GET_USER_NOTIFICATION_LIST: 'user-notification/user-notification',
  POST_USER_NOTIFICATION: 'user-notification/notification-read',

  POST_SUBMIT_AN_IDEA: 'help/idea',
  DELETE_SITE_GROUP: 'company-site/sitegroup',
  VIDEO_VIEWER_URL: 'itemdetail/video-url',

  POST_EXPORT_DATA_SITE: 'export-data/site',
  POST_EXPORT_DATA_SYSTEM: 'export-data/system',
  POST_EXPORT_DATA_NETWORK: 'export-data/network',
  POST_EXPORT_DATA_SERVICE_REQUEST: 'export-data/service-request',
  POST_EXPORT_DATA_SERVICE_TRIP: 'export-data/service-trip',
  POST_EXPORT_DATA_SECURITY_EVENT: 'export-data/events',
  POST_EXPORT_SERVICE_REQUEST_WO: 'export-data/sr-wo',
  GET_JITTER_ANALYTICS: 'insight/jitter',
  GET_PLOSS_ANALYTICS: 'insight/packet-loss',
  GET_MOS_ANALYTICS: 'insight/mos',
  GET_UPLINK_ANALYTICS: 'insight/uplink',
  GET_CHRONIC_PROFILE_LIST: 'chronic',
  GET_CHRONIC_PROFILE_DATA: 'chronic',
  GET_CHRONIC_DEFINITION: 'chronic/all',
  POST_CHRONIC_PROFILE_REQUEST: 'chronic',
  DELETE_CHRONIC_PROFILE: 'chronic',

  // Security Manager
  PUT_SITE_ON_TEST: 'security-manager/test',
  DELETE_SITE_TEST: 'security-manager/test',
  SECURITY_SITE_INFO: 'security-manager/site-info',
  SECURITY_CALL_CONTACT_LIST: 'security-manager/site-data',
  SECURITY_UPDATE_CALL_LIST: 'security-manager/update-call-list',
  SECURITY_MANAGER_USER: '/security-manager/administration/user-search',
  SECURITY_MANAGER_SITE_GROUP: '/security-manager/site-group',

  SM_GET_CONTACT_DETAILS: 'security-manager/contact',
  SM_POST_CONTACT_LIST: '/security-manager/contact',
  SM_PUT_CONTACT_LIST: '/security-manager/contact',
  SM_DELETE_CONTACT_LIST: '/security-manager/contact',
  SM_SEND_PASSCODE_EMAIL: '/security-manager/send-email',
  SM_CALL_LIST_CODE: '/security-manager/call-list',
  SM_UPDATE_ADMIN_COMPANY_CONFIG:
    '/security-manager/administration/company-settings',
  SM_UPDATE_ADMIN_SITE_CONFIG: '/security-manager/administration/site-settings',
  SM_GET_ADMIN_CONFIG: '/security-manager/administration/company-site-settings',
  COMPANY_PROFILE: 'company-site/company-profile',
  SM_ACTIVITY_LOGS_TYPES:
    '/security-manager/security-manager-activity-log-types',
  SM_ACTIVITY_LOGS: '/security-manager/security-manager-activity-logs',
  SM_ZONE_LIST: '/security-manager/test-zone-list',
  SM_ALARM_SEARCH: 'security-manager/alarm-search',
  // Self test
  SM_SELF_TEST_ZONE: 'security-manager/self-test/zone-list',
  SM_SELF_TEST_REPORT: 'security-manager/self-test/report',
  SM_SELF_TEST_STATUS: 'security-manager/self-test/check-status',
  // SM Reports
  SM_POST_EXPORT_ALL_ACTIVITY:
    'security-manager/export-data/customer-all-activity',
  SM_POST_EXPORT_OPEN_CLOSE_ACTIVITY:
    'security-manager/export-data/customer-open-close-activity',
  SM_POST_EXPORT_USER_CONTACT:
    'security-manager/export-data/user-and-contact-report',

  SM_GET_EXPORT_SITE: 'security-manager/export-data/report/filter/site',
  SM_POST_EXPORT_SITE_GROUP:
    'security-manager/export-data/report/filter/site-group',
  SM_CONTACT_ACTIVITY: 'security-manager/contact-activity',
  SM_CONTACT_ACTIVITY_DETAILS: 'security-manager/contact-activity/detail',
  SM_CONTACT_ACTIVITY_FILTER: 'security-manager/contact-activity/filter',

  // BULK UPLOAD
  POST_UPLOAD_SITE_GROUP: '/bulk-upload/site-group',
  GET_BULK_UPLOAD_SITE_GROUP_STATUS: '/bulk-upload/task',
  POST_UPLOAD_USER: '/bulk-upload/user',
  IMPERSONATE_USER: '/impersonate/user',
  IMPERSONATE_USER_SESSION: '/impersonate/end',
  SR_ITEM_CASENOTE: '/service-request/case-note',
  SR_ITEM_COMMENT: '/service-request/comment',
  SR_ITEM_ESCALATE: '/service-request/priority',
  SR_ITEM_INTERNAL_NOTE: '/service-request/internal-note',
  SR_ITEM_ATTACHMENTS: '/service-request/download-attachement',
  SR_CLOSE: 'service-request/close-sr',
  GET_FILTER_EVENT_STATUS: '/event/cpfilter-dd-events',
  POST_FILTER_SECURITY_EVENTS: '/event/list',
  GET_ACTIVE_EVENT_COUNT: '/event/active-event',
  GET_ACTIVE_EVENT_DETAILS: '/event/detail',
  GET_ACTIVE_EVENT_ZONES: '/event/Zone',
  GET_ACTIVE_EVENT_CURRENT_ACTIONS: '/event/current-actions',
  GET_USER_VALIDATION: '/config/special-character-check',
  GET_GLOBAL_USER_SITES:
    '/security-manager/administration/global-user/associated-sites',
  DELETE_GLOBAL_USER:
    '/security-manager/administration/global-user/delete-selected',
  POST_UPLOAD_SM_USER: 'security-manager/bulk-upload',
};
export const USER_PERMISSIONS: any = {
  ACITVE_DEACTIVE: 'Activate/Deactivate User',
  ADD_COMPANY: 'Add Company',
  ADD_PERMISSION_GROUP: 'Add Permission Group',
  ADD_PERMISSION_TO_PERMISSION_GROUP: 'Add Permission to a Permission Group',
  ADD_USER: 'Add User',
  ADD_EDIT_DELETE_NOTIFICATION: 'Add/Edit/Delete Notifications',
  ADMIN: 'Admin',
  ANALYTICS: 'Analytics',
  COMPANY_SITE_MANAGMENT: 'Companies and Sites Management',
  COMPANY_ADMIN: 'Company Admin',
  COMPANY_MANAGER: 'Company Manager',
  DELETE_PERMISSION_GROUP: 'Delete Permission Group',
  EDIT_PERMISSION_GROUP: 'Edit Permission Group',
  EDIT_USER: 'Edit User',
  LOCATION_MANAGER: 'Location Manager',
  SITE_VISIBLE_HIDDEN: 'Make site visible/hidden',
  NETWORK_MANAGER: 'Network Manager',
  NOTIFICATION: 'Notification',
  PERMSSION: 'Permission',
  REMOVE_PERMISSION_TO_PERMISSION_GROUP:
    'Remove Permission from a Permission Group',
  REPORT: 'Report',
  SERVICE_REQUEST: 'Service Request',
  SERVICE_TRIP: 'Service Trip',
  SITE_ADMIN: 'Site Admin',
  SITE_GROUP_ADMIN: 'Site Groups Administration',
  SITE_MANAGER: 'Site Manager',
  UCAAS: 'UCAAS',
  USER_ADMIN: 'User Admin',
  VIDEO_MANGER: 'Video Manager',
  VIDEO_VIEWER: 'Video Viewer',
  VIEW_PERMISSION_GROUP: 'View Permission Groups',
  VIEW_USER_INFO: 'View user information',
  VIEW_VIDEO: 'View Video',
  CHRONIC_PROFILE: 'Add/Edit/Delete Chronic Profile',
  // SIDE NAV PERMISSIONS
  OPEN_SERVICE_REQUEST: 'Open a service request - Navigation',
  INSIGHTS_AND_ANALYTICS: 'Insights and Analytics - Navigation',
  PARTNER_PORTALS: 'Partner Portals - Navigation',
  PARTNER_PORTALS_BUSINESS_INTELLIGENCE:
    'Partner Portals Business Intelligence- Navigation',
  PARTNER_PORTALS_NETWORK_AND_VOICE:
    'Partner Portals Network and Voice- Navigation',
  PARTNER_PORTALS_BUSINESS_SECURITY:
    'Partner Portals Business Security- Navigation',
  DEVICES: 'Devices - Navigation',
  MY_OPEN_PROJECTS: 'My Open Projects - Navigation',
  ADMINISTRATION: 'Administration - Navigation',
  ADMINISTRATION_PERMISSIONS: 'Administration Permissions - Navigation',
  ADMINISTRATION_USERS: 'Administration Users - Navigation',
  ADMINISTRATION_NOTIFICATION: 'Administration Notification - Navigation',
  ADMINISTRATION_COMPANY_AND_SITE:
    'Administration Company and Site - Navigation',
  REPORT_NAVIGATION: 'Report- Navigation',
  INTERNAL_NOTES: 'View Internal Notes',
  SECURITY_MANAGER_TILE: 'Security Manager tile',
  PUT_SITE_ON_TEST: 'Site on test',
  SELF_TEST: 'Self-Test Utility',
  SECURITY_MANAGEMENT: 'Security Management',
  CALL_LIST: 'Call List admin',
  CONTACT_LIST: 'Contact List admin',
  ALARM_CODES: 'Alarm Codes',
  SECURITY_MANAGER_ADMIN: 'Security Manager Admin',
  SITE_PASSWORD: 'Site Password',
  BULK_UPLOAD_USER: 'Upload Users in bulk',
  BULK_UPLOAD_SITE_GROUP: 'Upload Site Groups in bulk',
  SITE_MANAGER_USER_UPDATE_DELETE: 'Update/Delete security manager user',
  SECURITY_MANAGER_USER_ADMIN: 'Security Manager User Admin',
  AUTO_ADD_COMPANIES: 'Auto Add Companies',
  IS_INTERNAL: 'Manage Internal User',
  SM_ALL_ACTIVITY: 'View Customer All Activity Report',
  SM_OPEN_CLOSE_ACTIVITY: 'View Customer Open-Close Activity Report',
  USERS_AND_CONTACTS: 'View User And Contact Report',
  USER_IMPERSONATE: 'Impersonate User',
  LIVE_CHAT: 'Live Chat',
  MANAGE_SITE_CONTACT: 'Manage Site Contact',
  PAYMENT_PORTAL: 'Customer Payment Portal - Navigation',
  SR_REQUEST_CASE_NOTE: 'Service Request Case Notes',
  SR_REQUEST_COMMENT: 'Service Request Comments',
  SR_REQUEST_ESCALATE: 'Service Request Escalate',
  ALARM_EVENT: 'Alarm Events',
  TECHNICIAN_SITE_ON_TEST: 'Technician - Put Site On Test',
  REPORT_SERVICE_REQUEST: 'View Service Requests',
};
export const INTERFACE_URLS = {
  CONSULTATION: 'https://interfacesystems.com/free-consultation/',
  SYSTEMS: 'https://interfacesystems.com/business-security-systems',
  BUSINESS_INTELLIGENCE: 'https://interfacesystems.com/business-intelligence/',
  VOICE: 'https://interfacesystems.com/managed-network-voice-services',
  VIDEO:
    'https://interfacesystems.com/business-security-systems/video-verified-alarm',
  PAYMENT: 'https://interfacesystems.com/online-payments-center/',
  HELP_FAQS: 'https://interfacesystems.com/oneview-edge-help-and-faqs',
};
