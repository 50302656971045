import { apiInstance } from '../apiInstance';
import { API_POINTS } from '../Constant';

export const getCustomerList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_COMPANY_LIST, { params: payload })
    .then(res => res.data);
};
export const getCompanySiteList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_COMPANY_SITE_LIST, { params: payload })
    .then(res => res.data);
};
// rediret url
export const getBusinessIntelligence = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_BUSINESS_INTELLIGENCE, { params: payload })
    .then(res => res.data);
};
export const getBusinessSecurity = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_BUSINESS_SECURITY, { params: payload })
    .then(res => res.data);
};
export const getBusinessSecurityCombined = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_BUSINESS_SECURITY_VVA_combined, { params: payload })
    .then(res => res.data);
};
export const getNetworkVoices = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_VOICES, { params: payload })
    .then(res => res.data);
};
export const getVideoVerifiedAlarm = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_VIDEO_VERIFIED_ALARM, { params: payload })
    .then(res => res.data);
};
export const getHelpSupport = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_HELP_SUPPORT, { params: payload })
    .then(res => res.data);
};
export const getHelpDownloadSupport = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_HELP_DOWNLOAD)
    .then(res => res.data);
};
export const getServiceReqByCause = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SERVICE_REQUEST_BY_CAUSE, { params: payload })
    .then(res => res.data);
};
export const getServiceReqBySubject = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SERVICE_REQUEST_BY_SUBJECT, { params: payload })
    .then(res => res.data);
};
export const getCurrentSiteStatus = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_CURRENT_SITE_STATUS, { params: payload })
    .then(res => res.data);
};
export const getNetworkTrend = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NETWORK_TREND, { params: payload })
    .then(res => res.data);
};
export const getServiceRequestSite = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SERVICE_REQUEST_LIST, { params: payload })
    .then(res => res.data);
};
export const getAffectedSystem = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_AFFECTED_SYSTEM, { params: payload })
    .then(res => res.data);
};
export const postServiceRequest = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_SERVICE_REQUEST, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data);
};
// user profile
export const getUserProfileViewSetting = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_PROFILE_VIEW_SETTING, { params: payload })
    .then(res => res.data);
};
export const getUserProfileInfo = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_PROFILE_INFO, { params: payload })
    .then(res => res.data);
};
export const postUpdateUserProfileInfo = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.PATCH_USER_PROFILE_INFO, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const postSubmitAnIdea = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_SUBMIT_AN_IDEA, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const postExportDataSite = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_SITE, payload, requestHeaders)
    .then(res => res);
};
export const postExportDataSystem = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_SYSTEM, payload, requestHeaders)
    .then(res => res);
};
export const postExportDataNetwork = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_NETWORK, payload, requestHeaders)
    .then(res => res);
};
export const postExportDataServiceRequest = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_SERVICE_REQUEST, payload, requestHeaders)
    .then(res => res);
};
export const postExportDataServiceTrip = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_SERVICE_TRIP, payload, requestHeaders)
    .then(res => res);
};
export const postExportDataSecurityEvent = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_DATA_SECURITY_EVENT, payload, requestHeaders)
    .then(res => res);
};

export const getJitterAnalytics = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_JITTER_ANALYTICS, { params: payload })
    .then(res => res.data);
};
export const getLatencyPacketLossAnalytics = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PLOSS_ANALYTICS, { params: payload })
    .then(res => res.data);
};
export const getMOSAnalytics = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_MOS_ANALYTICS, { params: payload })
    .then(res => res.data);
};
export const getWU1plinkAnalytics = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_UPLINK_ANALYTICS, { params: payload })
    .then(res => res.data);
};
export const getW2UplinkAnalytics = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_UPLINK_ANALYTICS, { params: payload })
    .then(res => res.data);
};
export const getUserNotification = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_NOTIFICATION_LIST, { params: payload })
    .then(res => res.data);
};
export const postUserNotification = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_NOTIFICATION, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getUserSystems = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_SYSTEMS)
    .then(res => res.data);
};
export const getGlobalSearch = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SEARCH_ALL, { params: payload })
    .then(res => res.data);
};

export const getExportStateList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_STATE_LIST, { params: payload })
    .then(res => res.data);
};
export const postExportSMAllActivity = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.SM_POST_EXPORT_ALL_ACTIVITY, payload, requestHeaders)
    .then(res => res);
};
export const postExportSMOpenCloseActivity = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(
      API_POINTS.SM_POST_EXPORT_OPEN_CLOSE_ACTIVITY,
      payload,
      requestHeaders,
    )
    .then(res => res);
};
export const postExportSMSites = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SM_GET_EXPORT_SITE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getExportSMSiteGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_POST_EXPORT_SITE_GROUP, { params: payload })
    .then(res => res.data);
};
export const postExportSMUserContact = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.SM_POST_EXPORT_USER_CONTACT, payload, requestHeaders)
    .then(res => res);
};
export const getHelpELearning = async () => {
  return await apiInstance()
    .get(API_POINTS.GET_HELP_ELEARNING)
    .then(res => res.data);
};
export const getOnlineManuals = async () => {
  return await apiInstance()
    .get(API_POINTS.ONLINE_MANUALS)
    .then(res => res.data);
};
export const getTermsCondition = async () => {
  return await apiInstance()
    .get(API_POINTS.TERMS_CONDITION)
    .then(res => res.data);
};
export const patchUserAgreement = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.ACCEPT_AGREEMENT, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postExportSRworkOrder = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.POST_EXPORT_SERVICE_REQUEST_WO, payload, requestHeaders)
    .then(res => res);
};
export const getPanelContactActivity = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.SM_CONTACT_ACTIVITY, payload, requestHeaders)
    .then(res => res);
};
export const getSMPanelDetailActivity = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_CONTACT_ACTIVITY_DETAILS, { params: payload })
    .then(res => res.data);
};
export const getSMPanelFilter = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_CONTACT_ACTIVITY_FILTER, { params: payload })
    .then(res => res.data);
};
