import { useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import {
  Container,
  CustomRow,
  CustomCell,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableHead,
  CustomTableLabel,
  CustomTableRow,
  CustomTableSortLabel,
} from 'app/component/core/CustomResponsiveListView/style';
import { COLORS } from 'styles/colors';
import { getFormatedDate, uniqueId } from 'utils/CommonFn';
import {
  DATE_FORMAT_1,
  DATE_FORMAT_2,
  DATE_FORMAT_4,
  SERVICES,
  SORT_BY,
  TIMEZONE_TEXT,
} from 'utils/Constants';
import { RoundTick } from 'assets/component';
import Pagination from 'app/component/core/Pagination';
import { CONTEXT, DISPLAY_TEXT } from 'utils/Constants/Content';
import ListViewSkeleton from 'app/component/layout/Loader/ListViewSkeleton';
import { TableProps } from 'app/component/core/CustomResponsiveListView/type';

const ListView = (props: TableProps) => {
  const theme = useTheme();
  const {
    listData,
    listName,
    totalRecords = 0,
    onPageChange = () => {},
    loading,
    recordLimit,
    currentPage,
    headerItems,
    isAutoCells = false,
    onSortClick = () => {},
    Component,
    viewProps,
    viewStyle,
    hidePagination = false,
    footerLabel,
  } = props;
  const [order, setOrder] = useState<any>(SORT_BY.ASC);
  const [orderBy, setOrderBy] = useState();

  const handleSortRequest = (property: any) => {
    const isAscending = orderBy === property && order === SORT_BY.ASC;
    setOrder(isAscending ? SORT_BY.DESC : SORT_BY.ASC);
    setOrderBy(property);
    onSortClick(property, isAscending ? SORT_BY.DESC : SORT_BY.ASC);
  };

  const GetLabel = (props: any): JSX.Element => {
    const { keys, value, TimeZone = '' } = props;

    const setDateInFormat = () => {
      switch (keys) {
        case 'LastChronicDate': // Site
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'CreatedOn': // SR, ST, OSR
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'DispatchedOn': // SR, OSR
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'ResolvedOn': // SR, OSR
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'UpdatedOn': // SR, OSR
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'StatusChangeDate': // Network
          return getFormatedDate(value, DATE_FORMAT_1, TimeZone);
        case 'ScheduledDate': // ST, OSR
          if (listName === CONTEXT.SERVICE_REQUEST_WORK_ORDER_REPORT) {
            return getFormatedDate(value, DATE_FORMAT_2, TimeZone);
          }
          return getFormatedDate(value, DATE_FORMAT_1, '', 'utc');
        case 'AlarmTime': // Events
          return getFormatedDate(value, DATE_FORMAT_4, TimeZone);
        default:
          return (keys !== TIMEZONE_TEXT && value) ?? '-';
      }
    };
    switch (listName) {
      case CONTEXT.SITES_EXPORT_LIST:
        if (SERVICES.includes(keys)) {
          return (
            <div style={{ padding: '0rem 1rem' }}>
              {value ? <RoundTick color={COLORS.lightGreen} /> : '-'}
            </div>
          );
        }
        return setDateInFormat();

      default:
        return setDateInFormat();
    }
  };
  return (
    <>
      <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
        <Container theme={theme} sx={viewStyle}>
          <CustomTable
            stickyHeader
            sx={{ borderCollapse: 'separate', borderSpacing: '0rem 0.25rem' }}
            theme={theme}
          >
            <CustomTableHead theme={theme}>
              <CustomTableRow theme={theme}>
                {headerItems.map((headerItem: any, index: any) =>
                  headerItem.isSort ? (
                    <CustomTableCell
                      theme={theme}
                      key={index}
                      onClick={() => handleSortRequest(headerItem.sortColumn)}
                      isSort={headerItem.isSort}
                    >
                      <CustomTableSortLabel
                        theme={theme}
                        active={orderBy === headerItem.sortColumn}
                        hideSortIcon={true}
                        direction={
                          orderBy === headerItem.sortColumn
                            ? order
                            : SORT_BY.ASC
                        }
                        index={index}
                      >
                        {headerItem.label}
                      </CustomTableSortLabel>
                    </CustomTableCell>
                  ) : (
                    <CustomTableCell theme={theme} key={index}>
                      <CustomTableLabel index={index}>
                        {headerItem.label}
                      </CustomTableLabel>
                    </CustomTableCell>
                  ),
                )}
              </CustomTableRow>
            </CustomTableHead>
            <CustomTableBody theme={theme} sx={viewStyle}>
              {loading ? (
                <ListViewSkeleton
                  theme={theme}
                  headerCount={headerItems.length}
                />
              ) : listData?.length > 0 ? (
                <>
                  {isAutoCells
                    ? listData.map((item: any, idx: any) => {
                        return (
                          <CustomTableRow key={uniqueId()} theme={theme}>
                            {Object.entries(item).map(([key, value]) => {
                              const child = (
                                <GetLabel
                                  {...{
                                    keys: key,
                                    value,
                                    TimeZone: item.TimeZone,
                                  }}
                                />
                              );
                              return (
                                key !== 'TimeZone' && (
                                  <CustomTableCell
                                    theme={theme}
                                    component={idx === 0 ? 'th' : ''}
                                  >
                                    {child}
                                  </CustomTableCell>
                                )
                              );
                            })}
                          </CustomTableRow>
                        );
                      })
                    : listData.map((row: any) => (
                        <Component
                          {...{ row, theme, listName, ...viewProps }}
                        />
                      ))}
                </>
              ) : (
                <CustomRow>
                  <CustomCell colSpan={headerItems.length}>
                    {DISPLAY_TEXT.NO_RECORD_FOUND}
                  </CustomCell>
                </CustomRow>
              )}
            </CustomTableBody>
          </CustomTable>
        </Container>
      </Box>
      {!hidePagination && (
        <Pagination
          totalRecords={totalRecords}
          currentPage={currentPage}
          setCurrentPage={onPageChange}
          loading={loading}
          recordLimit={recordLimit}
        />
      )}
      {footerLabel && (
        <Grid container>
          <Grid item p={2}>
            {footerLabel}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListView;
