/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AdminState,
  CreatePermissionGroupProps,
  CreatePermissionProps,
  DeletePermissionGroup,
  NewUserProps,
  PermissionRequestProps,
  ResponseProps,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ToastAction } from 'app/component/core/ToastAlert/types';

export const initialState: AdminState = {
  permissonList: {
    loading: false,
    data: [],
    recordCount: 0,
  },
  permissonGroupList: {
    loading: false,
    data: [],
  },
  createdPermission: {
    loading: false,
    data: [],
  },
  createdPermissionGroup: {
    loading: false,
    data: [],
  },
  updatePermission: {
    loading: false,
    data: [],
  },
  deletePermission: {
    loading: false,
    data: [],
  },
  updatePermissionGroup: {
    loading: false,
    data: [],
  },
  deletePermissionGroup: {
    loading: false,
    data: [],
  },
  permissonGroupCustomerList: {
    loading: false,
    data: [],
  },
  userValidation: {
    loading: false,
    data: [],
  },
  usersList: {
    loading: false,
    data: [],
  },
  userDetails: {
    loading: false,
    data: [],
  },
  NewUser: {
    loading: false,
    data: [],
  },
  deleteUser: {
    loading: false,
    data: [],
  },
  associateSites: {
    loading: false,
    data: [],
  },
  userGroupList: {
    loading: false,
    data: [],
  },
  userSiteList: {
    loading: false,
    data: [],
  },
  userCompanyList: {
    loading: false,
    data: [],
  },
  userPermissionGroupList: {
    loading: false,
    data: [],
  },
  toastInfo: {
    showToast: false,
    toastMessage: '',
    toastType: undefined,
  },
  permissionChild: {
    loading: false,
    data: [],
  },
  permissionList: {
    loading: false,
    data: [],
  },
  permissionGroupList: {
    loading: false,
    data: [],
  },
  permissionGroupDetails: {
    loading: false,
    data: [],
  },
  createNewUserPermission: {
    loading: false,
    data: [],
  },
  updateUserPermissionGroup: {
    loading: false,
    data: [],
  },
  deleteUserPermissionGroup: {
    loading: false,
    data: [],
  },
  searchPermission: {
    loading: false,
    data: [],
  },
  searchPermissionGroup: {
    loading: false,
    data: [],
  },
  searchPermissionUser: {
    loading: false,
    data: [],
  },
  updateGroupPermission: {
    loading: false,
    data: [],
  },
  userPermissionGroupDetail: {
    loading: false,
    data: [],
  },
  permissionGroupStatus: {
    loading: false,
    data: [],
  },
  deleteUserPermission: {
    loading: false,
    data: [],
  },
  userPermissionName: {
    loading: false,
    data: [],
  },
  postPermissionGroupToUser: {
    loading: false,
    data: [],
  },
  CompanySiteActiveTab: 1,
  userChangePassword: {
    data: [],
    loading: false,
  },
  sitesList: {
    data: [],
    loading: false,
  },
  siteRemark: {
    data: [],
    loading: false,
  },
  siteVisibility: {
    data: [],
    loading: true,
  },
  associateSiteList: {
    data: [],
    loading: false,
  },
  companySelectedSites: {
    data: [],
    loading: true,
    status: '',
  },
  userSiteGroupList: {
    data: [],
    loading: false,
  },
  csSitesGroupList: {
    data: [],
    loading: false,
  },
  companySiteDetails: {
    data: [],
    loading: false,
  },
  companyAccountType: {
    data: [],
    loading: false,
  },
  profilePartyNumber: {
    data: [],
    loading: false,
  },
  masterCompanyList: {
    data: [],
    loading: false,
  },
  companyProfile: {
    data: [],
    loading: true,
    status: '',
  },
  associateSiteGroupList: {
    data: [],
    loading: false,
  },
  csSelectedSiteGroupList: {
    data: [],
    loading: true,
    status: '',
  },
  isUserHasAccessPermission: false,
  notificationList: {
    data: [],
    loading: false,
  },
  deleteNotification: {
    data: [],
    loading: false,
  },
  deleteSiteGroup: {
    data: [],
    loading: false,
  },
  notificationProps: {
    companyList: {
      data: [],
      loading: false,
    },
    siteList: {
      data: [],
      loading: false,
    },
    siteGroup: {
      data: [],
      loading: false,
    },
  },
  notificationDetails: {
    data: [],
    loading: false,
  },
  addNotification: {
    data: [],
    loading: false,
  },
  updateNotification: {
    data: [],
    loading: false,
  },
  chronicProfileList: {
    data: [],
    loading: false,
  },
  chronicProfileData: {
    data: [],
    loading: false,
  },
  chronicDefinitionData: {
    data: [],
    loading: false,
  },
  chronicProfileRequest: {
    data: [],
    loading: true,
    status: '',
  },
  deleteChronicProfile: {
    data: [],
    loading: true,
    status: '',
  },
  NewBulkUploadSiteGroup: {
    data: [],
    loading: false,
  },
  BulkUploadStatus: {
    data: [],
    loading: false,
  },
  BulkUploadSiteGroupTaskList: {
    data: [],
    loading: false,
  },
  BulkUploadedListStatus: {
    data: [],
    loading: false,
  },
  BulkUploadCancelTask: false,
  NewBulkUploadUser: {
    data: [],
    loading: false,
  },
  NewBulkUploadsmUser: {
    data: [],
    loading: false,
  },
  isOpenBulkUpload: false,
  ImpersonateUser: {
    data: [],
    loading: false,
    status: '',
  },
  CloseImpersonateSession: {
    data: [],
    loading: false,
  },
  sitesContactDetails: {
    data: [],
    loading: false,
  },
  updateSiteContactDetails: {
    data: [],
    loading: false,
  },
  companySitesContactDetails: {
    data: [],
    loading: false,
  },
  updateCompanySiteContactDetails: {
    data: [],
    loading: false,
  },
  getActiveUserTab: 1,
};
const AdminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // permission
    fetchPermissionList(state, action: PayloadAction<any>) {
      state.permissonList = { ...state.permissonList, loading: true };
    },
    updatePermissonList(state, action: PayloadAction<ResponseProps>) {
      state.permissonList = {
        ...state.permissonList,
        ...action.payload,
      };
      // if (state.permissonList?.data != null) {
      //   state.permissonList.data = state.permissonList?.data.map(
      //     (item: any) => {
      //       return {
      //         ...item,
      //         isChecked: false,
      //       };
      //     },
      //   );
      // }
    },
    fetchCreatePermission(state, action: PayloadAction<CreatePermissionProps>) {
      state.createdPermission = { ...state.createdPermission, loading: true };
    },
    updateCreatedPermission(state, action: PayloadAction<ResponseProps>) {
      state.createdPermission = {
        ...state.createdPermission,
        ...action.payload,
      };
    },
    fetchUpdatePermission(state, action: PayloadAction<CreatePermissionProps>) {
      state.updatePermission = { ...state.updatePermission, loading: true };
    },
    updatePermissions(state, action: PayloadAction<ResponseProps>) {
      state.createdPermission = {
        ...state.createdPermission,
        ...action.payload,
      };
    },
    fetchDeletePermission(state, action: PayloadAction<any>) {
      state.updatePermission = { ...state.updatePermission, loading: true };
    },
    updateDeletedPermissions(state, action: PayloadAction<ResponseProps>) {
      state.deletePermission = {
        ...state.deletePermission,
        ...action.payload,
      };
    },

    // permission group
    fetchPermissionGroupList(
      state,
      action: PayloadAction<PermissionRequestProps>,
    ) {
      state.permissonGroupList = {
        ...state.permissonGroupList,
        loading: true,
      };
    },
    updatePermissonGroupList(state, action: PayloadAction<any>) {
      state.permissonGroupList = {
        ...state.permissonGroupList,
        ...action.payload,
      };
      if (state.permissonGroupList?.data) {
        state.permissonGroupList.data = state.permissonGroupList?.data.map(
          (item: any) => {
            return { ...item, isSelected: false };
          },
        );
      }
    },
    fetchCreatePermissionGroup(
      state,
      action: PayloadAction<CreatePermissionGroupProps>,
    ) {
      state.createdPermissionGroup = {
        ...state.createdPermissionGroup,
        loading: true,
      };
    },
    updateCreatedPermissionGroup(state, action: PayloadAction<any>) {
      state.createdPermissionGroup = {
        ...state.createdPermissionGroup,
        ...action.payload,
      };
    },
    fetchUpdatePermissionGroup(
      state,
      action: PayloadAction<CreatePermissionProps>,
    ) {
      state.updatePermissionGroup = {
        ...state.updatePermissionGroup,
        loading: true,
      };
    },
    updatedPermissionGroup(state, action: PayloadAction<ResponseProps>) {
      state.updatePermissionGroup = {
        ...state.updatePermissionGroup,
        ...action.payload,
      };
    },
    fetchDeletePermissionGroup(
      state,
      action: PayloadAction<DeletePermissionGroup>,
    ) {
      state.deletePermissionGroup = {
        ...state.deletePermissionGroup,
        loading: true,
      };
    },
    updateDeletedPermissionGroup(state, action: PayloadAction<ResponseProps>) {
      state.deletePermissionGroup = {
        ...state.deletePermissionGroup,
        ...action.payload,
      };
    },
    fetchPermissionGroupCustomerList(state, action: PayloadAction<any>) {
      state.permissonGroupCustomerList = {
        ...state.permissonGroupCustomerList,
        loading: true,
      };
    },
    updatePermissionGroupCustomerList(
      state,
      action: PayloadAction<ResponseProps>,
    ) {
      state.permissonGroupCustomerList = {
        ...state.permissonGroupCustomerList,
        ...action.payload,
      };
    },
    // fetch user
    fetchCreateUser(state, action: PayloadAction<NewUserProps>) {
      state.NewUser = { ...state.NewUser, loading: true };
    },
    updateCreatedUser(state, action: PayloadAction<ResponseProps>) {
      state.NewUser = {
        ...state.NewUser,
        ...action.payload,
      };
    },

    fetchUsersList(state, action: PayloadAction<any>) {
      state.usersList = {
        ...state.usersList,
        loading: true,
      };
    },
    updateUsersList(state, action: PayloadAction<any>) {
      state.usersList = {
        ...state.usersList,
        ...action.payload,
      };
    },
    fetchUserValidation(state, action: PayloadAction<any>) {
      state.userValidation = { ...state.userValidation, loading: true };
    },
    updateUserValidation(state, action: PayloadAction<any>) {
      state.userValidation = {
        ...state.userValidation,
        ...action.payload,
      };
    },
    fetchUserDetails(state, action: PayloadAction<any>) {
      state.userDetails = { ...state.userDetails, loading: true };
    },
    updateUserDetails(state, action: PayloadAction<any>) {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
    },
    fetchPostUpdateUser(state, action: PayloadAction<NewUserProps>) {
      state.NewUser = { ...state.NewUser, loading: true };
    },
    updateUser(state, action: PayloadAction<ResponseProps>) {
      state.NewUser = {
        ...state.NewUser,
        ...action.payload,
      };
    },
    fetchSuspendUser(state, action: PayloadAction<any>) {
      state.permissonGroupList = {
        ...state.permissonGroupList,
        loading: true,
      };
    },
    fetchUserDelete(state, action: PayloadAction<any>) {
      state.deleteUser = { ...state.deleteUser, loading: true };
    },
    updateDeleteUser(state, action: PayloadAction<any>) {
      state.deleteUser = {
        ...state.deleteUser,
        ...action.payload,
      };
    },
    fetchAssociateSites(state, action: PayloadAction<any>) {
      state.associateSites = { ...state.associateSites, loading: true };
    },
    updateAssociateSites(state, action: PayloadAction<any>) {
      state.associateSites = {
        ...state.associateSites,
        ...action.payload,
      };
      if (state.associateSites?.data) {
        state.associateSites.data = state.associateSites?.data.map(
          (item: any) => {
            return { ...item, isSelected: false };
          },
        );
      }
    },
    fetchUsersGroupList(state, action: PayloadAction<any>) {
      state.userGroupList = { ...state.userGroupList, loading: true };
    },
    updateUserGroupList(state, action: PayloadAction<any>) {
      state.userGroupList = {
        ...state.userGroupList,
        ...action.payload,
      };
    },
    fetchUserSiteList(state, action: PayloadAction<any>) {
      state.userSiteList = { ...state.userSiteList, loading: true };
    },
    updateUserSiteList(state, action: PayloadAction<any>) {
      state.userSiteList = {
        ...state.userSiteList,
        ...action.payload,
      };
    },
    fetchUserCompanyList(state, action: PayloadAction<any>) {
      state.userCompanyList = { ...state.userCompanyList, loading: true };
    },
    updateUserCompanyList(state, action: PayloadAction<any>) {
      state.userCompanyList = {
        ...state.userCompanyList,
        ...action.payload,
      };
      /* if (state.userCompanyList?.data) {
        state.userCompanyList.data = state.userCompanyList?.data.map(
          (item: any) => {
            return { ...item, isSelected: false };
          },
        );
      } */
    },
    fetchUserPermissionGroupList(state, action: PayloadAction<any>) {
      state.userPermissionGroupList = {
        ...state.userPermissionGroupList,
        loading: true,
      };
    },
    updateUserPermissionList(state, action: PayloadAction<any>) {
      state.userPermissionGroupList = {
        ...state.userPermissionGroupList,
        ...action.payload,
      };
      if (state.userPermissionGroupList?.data) {
        state.userPermissionGroupList.data =
          state.userPermissionGroupList?.data.map((item: any) => {
            return { ...item, isSelected: false };
          });
      }
    },
    displayToast(state, action: PayloadAction<ToastAction>) {
      state.toastInfo.showToast = true;
      state.toastInfo.toastMessage = action.payload.toastMessage;
      state.toastInfo.toastType = action.payload.toastType;
    },
    closeToast(state) {
      state.toastInfo.showToast = false;
      state.toastInfo.toastMessage = '';
    },
    fetchPermissionGroupChild(state, action: PayloadAction<any>) {
      state.permissionChild = { ...state.permissionChild, loading: true };
    },
    updatePermissionGroupChild(state, action: PayloadAction<any>) {
      state.permissionChild = {
        ...state.permissionChild,
        ...action.payload,
      };
    },
    // permission management
    fetchUserPermissionList(state, action: PayloadAction<any>) {
      state.permissionList = { ...state.permissionList, loading: true };
    },
    updateUserPermissonList(state, action: PayloadAction<any>) {
      state.permissionList = {
        ...state.permissionList,
        ...action.payload,
      };
    },
    fetchUserPermissionsGroupList(state, action: PayloadAction<any>) {
      state.permissionGroupList = {
        ...state.permissionGroupList,
        loading: true,
      };
    },
    updateUserPermissonGroupList(state, action: PayloadAction<any>) {
      state.permissionGroupList = {
        ...state.permissionGroupList,
        ...action.payload,
      };
    },
    fetchUserPermissionsGroupDetails(state, action: PayloadAction<any>) {
      state.permissionGroupDetails = {
        ...state.permissionGroupDetails,
        data: [],
        loading: true,
      };
    },
    updateUserPermissonGroupDetails(state, action: PayloadAction<any>) {
      state.permissionGroupDetails = {
        ...state.permissionGroupDetails,
        ...action.payload,
      };
    },
    fetchPostCreateNewGroup(state, action: PayloadAction<any>) {
      state.permissionGroupList = {
        ...state.permissionGroupList,
        ...action.payload,
      };
    },
    deletePermissionGroup(state, action: PayloadAction<any>) {
      state.deletePermissionGroup = {
        ...state.deletePermissionGroup,
        loading: true,
      };
    },
    fetchSearchPermissions(state, action: PayloadAction<any>) {
      state.searchPermission = { ...state.searchPermission, loading: true };
    },
    updateActiveTab(state, action: PayloadAction<any>) {
      state.CompanySiteActiveTab = action.payload;
    },
    updateSearchPermisson(state, action: PayloadAction<any>) {
      state.searchPermission = {
        ...state.searchPermission,
        ...action.payload,
      };
    },
    fetchSearchPermissionsGroup(state, action: PayloadAction<any>) {
      state.searchPermissionGroup = {
        ...state.searchPermissionGroup,
        data: [],
        loading: true,
      };
    },
    updateSearchPermissonGroup(state, action: PayloadAction<any>) {
      state.searchPermissionGroup = {
        ...state.searchPermissionGroup,
        ...action.payload,
      };
    },
    fetchSearchPermissionsUser(state, action: PayloadAction<any>) {
      state.searchPermissionUser = {
        ...state.searchPermissionUser,
        loading: true,
      };
    },
    updateSearchPermissionUser(state, action: PayloadAction<any>) {
      state.searchPermissionUser = {
        ...state.searchPermissionUser,
        ...action.payload,
      };
    },
    putPermissionGroup(state, action: PayloadAction<any>) {
      state.updateGroupPermission = {
        ...state.updateGroupPermission,
        loading: true,
      };
    },
    fetchPostPermissionGroupToUser(state, action: PayloadAction<any>) {
      state.postPermissionGroupToUser = {
        ...state.postPermissionGroupToUser,
        loading: true,
      };
    },
    fetchUserPermissionsDetails(state, action: PayloadAction<any>) {
      state.userPermissionGroupDetail = {
        ...state.userPermissionGroupDetail,
        data: [],
        loading: true,
      };
    },
    updateUserPermissionsDetails(state, action: PayloadAction<any>) {
      state.userPermissionGroupDetail = {
        ...state.userPermissionGroupDetail,
        ...action.payload,
      };
    },
    checkUserPermissionedGroupStatus(state, action: PayloadAction<any>) {
      state.permissionGroupStatus = {
        ...state.permissionGroupStatus,
        data: [],
        loading: true,
      };
    },
    updateUserPermissionedGroupStatus(state, action: PayloadAction<any>) {
      state.permissionGroupStatus = {
        ...state.permissionGroupStatus,
        ...action.payload,
      };
    },
    deleteUserPermission(state, action: PayloadAction<any>) {
      state.deleteUserPermission = {
        ...state.deleteUserPermission,
        loading: true,
      };
    },
    putPermissionGroupName(state, action: PayloadAction<any>) {
      state.userPermissionName = {
        ...state.userPermissionName,
        loading: true,
      };
    },
    clearUserState(state, action: PayloadAction<any>) {
      state.userDetails = {
        data: [],
        loading: false,
      };
      state.userCompanyList = {
        data: [],
        loading: false,
      };
      state.userPermissionGroupList = {
        data: [],
        loading: false,
      };
      state.associateSites = {
        data: [],
        loading: false,
      };
      state.userSiteGroupList = {
        data: [],
        loading: false,
      };
    },

    clearAssociateSiteState(state, action: PayloadAction<any>) {
      state.associateSites = {
        data: [],
        loading: false,
      };
    },
    putChangeUserPassword(state, action: PayloadAction<any>) {
      state.userChangePassword = { ...state.userChangePassword, loading: true };
    },
    updateChangeUserPassword(state, action: PayloadAction<any>) {
      state.userChangePassword = {
        ...state.userChangePassword,
        ...action.payload,
      };
    },
    fetchSitesList(state, action: PayloadAction<any>) {
      state.sitesList = {
        ...state.sitesList,
        data: [],
        loading: true,
      };
    },
    updateSitesList(state, action: PayloadAction<any>) {
      state.sitesList = {
        ...state.sitesList,
        ...action.payload,
      };
    },
    fetchSiteRemark(state, action: PayloadAction<any>) {
      state.siteRemark = {
        ...state.siteRemark,
        data: [],
        loading: true,
      };
    },
    updateSiteRemark(state, action: PayloadAction<any>) {
      state.siteRemark = {
        ...state.siteRemark,
        ...action.payload,
      };
    },
    putSiteVisibility(state, action: PayloadAction<any>) {
      state.siteVisibility = {
        ...state.siteVisibility,
        ...action.payload,
      };
    },
    fetchAssociateSitesList(state, action: PayloadAction<any>) {
      state.associateSiteList = {
        ...state.associateSiteList,
        data: [],
        loading: true,
      };
    },
    updateAssociateSitesList(state, action: PayloadAction<any>) {
      state.associateSiteList = {
        ...state.associateSiteList,
        ...action.payload,
      };
    },
    fetchSiteContactDetails(state, action: PayloadAction<any>) {
      state.sitesContactDetails = {
        ...state.sitesContactDetails,
        data: [],
        loading: true,
      };
    },
    updateSiteContactDetails(state, action: PayloadAction<any>) {
      state.sitesContactDetails = {
        ...state.sitesContactDetails,
        ...action.payload,
      };
    },
    patchSiteContactDetails(state, action: PayloadAction<any>) {
      state.updateSiteContactDetails = {
        ...state.updateSiteContactDetails,
        data: [],
        loading: true,
      };
    },
    updatePatchSiteContactDetails(state, action: PayloadAction<any>) {
      state.updateSiteContactDetails = {
        ...state.updateSiteContactDetails,
        ...action.payload,
      };
    },
    fetchCompanySiteContactDetails(state, action: PayloadAction<any>) {
      state.companySitesContactDetails = {
        ...state.companySitesContactDetails,
        data: [],
        loading: true,
      };
    },
    updateCompanySiteContactDetails(state, action: PayloadAction<any>) {
      state.companySitesContactDetails = {
        ...state.companySitesContactDetails,
        ...action.payload,
      };
    },
    patchCompanySiteContactDetails(state, action: PayloadAction<any>) {
      state.updateCompanySiteContactDetails = {
        ...state.updateCompanySiteContactDetails,
        data: [],
        loading: true,
      };
    },
    updatepatchCompanySiteContactDetails(state, action: PayloadAction<any>) {
      state.updateCompanySiteContactDetails = {
        ...state.updateCompanySiteContactDetails,
        ...action.payload,
      };
    },
    patchCompanySites(state, action: PayloadAction<any>) {
      state.companySelectedSites = {
        ...state.companySelectedSites,
        ...{ data: [], status: 'pending' },
      };
    },
    updatePatchCompanySites(state, action: PayloadAction<any>) {
      state.companySelectedSites = {
        ...state.companySelectedSites,
        ...action.payload,
      };
    },
    fetchSiteGroups(state, action: PayloadAction<any>) {
      state.userSiteGroupList = { ...state.userSiteGroupList, loading: true };
    },
    updateSiteGroups(state, action: PayloadAction<any>) {
      state.userSiteGroupList = {
        ...state.userSiteGroupList,
        ...action.payload,
      };
      if (state.userSiteGroupList?.data) {
        state.userSiteGroupList.data = state.userSiteGroupList?.data.map(
          (item: any) => {
            return { ...item, isSelected: false };
          },
        );
      }
    },
    fetchCSSiteGroupList(state, action: PayloadAction<any>) {
      state.csSitesGroupList = {
        ...state.csSitesGroupList,
        loading: true,
        data: [],
      };
    },
    updateCSSiteGroupList(state, action: PayloadAction<any>) {
      state.csSitesGroupList = {
        ...state.csSitesGroupList,
        ...action.payload,
      };
    },
    fetchSiteDetails(state, action: PayloadAction<any>) {
      state.companySiteDetails = {
        ...state.companySiteDetails,
        data: [],
        loading: true,
      };
    },
    updateSiteDetails(state, action: PayloadAction<any>) {
      state.companySiteDetails = {
        ...state.companySiteDetails,
        ...action.payload,
      };
    },
    fetchCompanyAccountType(state, action: PayloadAction<any>) {
      state.companyAccountType = {
        ...state.companyAccountType,
        data: [],
        loading: true,
      };
    },
    updateCompanyAccountType(state, action: PayloadAction<any>) {
      state.companyAccountType = {
        ...state.companyAccountType,
        ...action.payload,
      };
    },
    fetchProfilePartyNumber(state, action: PayloadAction<any>) {
      state.profilePartyNumber = {
        ...state.profilePartyNumber,
        data: [],
        loading: true,
      };
    },
    updateProfilePartyNumber(state, action: PayloadAction<any>) {
      state.profilePartyNumber = {
        ...state.profilePartyNumber,
        ...action.payload,
      };
    },
    fetchMasterCompanyList(state, action: PayloadAction<any>) {
      state.masterCompanyList = {
        ...state.masterCompanyList,
        data: [],
        loading: true,
      };
    },
    updateMasterCompanyList(state, action: PayloadAction<any>) {
      state.masterCompanyList = {
        ...state.masterCompanyList,
        ...action.payload,
      };
    },
    createCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...{ data: [], status: 'pending' },
      };
    },
    updateCreateCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...action.payload,
      };
    },
    patchCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...{ data: [], status: 'pending' },
      };
    },
    updatePatchCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...action.payload,
      };
    },
    fetchAssociateSiteGroupList(state, action: PayloadAction<any>) {
      state.associateSiteGroupList = {
        ...state.associateSiteGroupList,
        data: [],
        loading: true,
      };
    },
    updateAssociateSiteGroupList(state, action: PayloadAction<any>) {
      state.associateSiteGroupList = {
        ...state.associateSiteGroupList,
        ...action.payload,
      };
    },
    patchCSSiteGroup(state, action: PayloadAction<any>) {
      state.csSelectedSiteGroupList = {
        ...state.csSelectedSiteGroupList,
        ...{ data: [], status: 'pending' },
      };
    },
    updatePatchCSSiteGroup(state, action: PayloadAction<any>) {
      state.csSelectedSiteGroupList = {
        ...state.csSelectedSiteGroupList,
        ...action.payload,
      };
    },
    postCSSiteGroup(state, action: PayloadAction<any>) {
      state.csSelectedSiteGroupList = {
        ...state.csSelectedSiteGroupList,
        ...{ data: [], status: 'pending' },
      };
    },
    checkUserAccessPermission(state, action: PayloadAction<any>) {
      state.isUserHasAccessPermission = action.payload;
    },
    // Notification slice
    fetchNotificationList(state, action: PayloadAction<any>) {
      state.notificationList = {
        ...state.notificationList,
        loading: true,
      };
    },
    updateNotificationList(state, action: PayloadAction<any>) {
      state.notificationList = {
        ...state.notificationList,
        ...action.payload,
      };
    },
    fetchNotificationCompanyList(state, action: PayloadAction<any>) {
      state.notificationProps.companyList = {
        ...state.notificationProps.companyList,
        loading: true,
      };
    },
    updateNotificationCompanyList(state, action: PayloadAction<any>) {
      state.notificationProps.companyList = {
        ...state.notificationProps.companyList,
        ...action.payload,
      };
    },
    fetchNotificationDetails(state, action: PayloadAction<any>) {
      state.notificationDetails = {
        ...state.notificationDetails,
        loading: true,
      };
    },
    updateNotificationDetails(state, action: PayloadAction<any>) {
      state.notificationDetails = {
        ...state.notificationDetails,
        ...action.payload,
      };
    },
    fetchPostNotification(state, action: PayloadAction<any>) {
      state.addNotification = {
        ...state.addNotification,
        loading: true,
      };
    },
    updatePostNotification(state, action: PayloadAction<any>) {
      state.addNotification = {
        ...state.addNotification,
        ...action.payload,
      };
    },
    fetchPatchNotification(state, action: PayloadAction<any>) {
      state.updateNotification = {
        ...state.updateNotification,
        loading: true,
      };
    },
    updatePatchNotification(state, action: PayloadAction<any>) {
      state.updateNotification = {
        ...state.updateNotification,
        ...action.payload,
      };
    },
    deleteNotification(state, action: PayloadAction<any>) {
      state.deleteNotification = {
        ...state.deleteNotification,
        loading: true,
      };
    },

    deleteSiteGroup(state, action: PayloadAction<any>) {
      state.deleteSiteGroup = {
        ...state.deleteSiteGroup,
        loading: true,
        data: [],
      };
    },
    fetchNotificationSiteGroupList(state, action: PayloadAction<any>) {
      state.notificationProps.siteGroup = {
        ...state.notificationProps.siteGroup,
        loading: true,
      };
    },
    updateNotificationSiteGroupList(state, action: PayloadAction<any>) {
      state.notificationProps.siteGroup = {
        ...state.notificationProps.siteGroup,
        ...action.payload,
      };
    },
    fetchNotificationSiteList(state, action: PayloadAction<any>) {
      state.notificationProps.siteList = {
        ...state.notificationProps.siteList,
        loading: true,
      };
    },
    updateNotificationSiteList(state, action: PayloadAction<any>) {
      state.notificationProps.siteList = {
        ...state.notificationProps.siteList,
        ...action.payload,
      };
    },
    fetchChronicProfileList(state, action: PayloadAction<any>) {
      state.chronicProfileList = {
        ...state.chronicProfileList,
        loading: true,
      };
    },
    updateChronicProfileList(state, action: PayloadAction<any>) {
      state.chronicProfileList = {
        ...state.chronicProfileList,
        ...action.payload,
      };
    },
    fetchChronicProfileData(state, action: PayloadAction<any>) {
      state.chronicProfileData = {
        ...state.chronicProfileData,
        loading: true,
      };
    },
    updateChronicProfileData(state, action: PayloadAction<any>) {
      state.chronicProfileData = {
        ...state.chronicProfileData,
        ...action.payload,
      };
    },
    fetchChronicDefinationData(state, action: PayloadAction<any>) {
      state.chronicDefinitionData = {
        ...state.chronicDefinitionData,
        loading: true,
      };
    },
    updateChronicDefinationData(state, action: PayloadAction<any>) {
      state.chronicDefinitionData = {
        ...state.chronicDefinitionData,
        ...action.payload,
      };
    },
    postChronicProfileRequest(state, action: PayloadAction<any>) {
      state.chronicProfileRequest = {
        ...state.chronicProfileRequest,
        loading: true,
        status: 'pending',
      };
    },
    updateChronicProfileRequest(state, action: PayloadAction<any>) {
      state.chronicProfileRequest = {
        ...state.chronicProfileRequest,
        ...action.payload,
      };
    },
    deleteChronicProfile(state, action: PayloadAction<any>) {
      state.deleteChronicProfile = {
        ...state.deleteChronicProfile,
        status: 'pending',
      };
    },
    updateDeleteChronicProfile(state, action: PayloadAction<any>) {
      state.deleteChronicProfile = {
        ...state.deleteChronicProfile,
        ...action.payload,
      };
    },
    fetchCreateBulkSiteGroup(state, action: PayloadAction<any>) {
      state.NewBulkUploadSiteGroup = {
        ...state.NewBulkUploadSiteGroup,
        loading: true,
      };
    },
    updateCreatedBulkSiteGroup(state, action: PayloadAction<ResponseProps>) {
      state.NewBulkUploadSiteGroup = {
        ...state.NewBulkUploadSiteGroup,
        ...action.payload,
      };
    },
    fetchUploadStatus(state, action: PayloadAction<any>) {
      state.BulkUploadStatus = { ...state.BulkUploadStatus, loading: true };
    },
    updateUploadStatus(state, action: PayloadAction<ResponseProps>) {
      state.BulkUploadStatus = {
        ...state.BulkUploadStatus,
        ...action.payload,
      };
    },
    BulkUploadedListStatus(state, action: PayloadAction<ResponseProps>) {
      state.BulkUploadedListStatus = {
        ...state.BulkUploadedListStatus,
        ...action.payload,
      };
    },
    fetchBulkUploadSiteGroupTaskList(state, action: PayloadAction<any>) {
      state.BulkUploadSiteGroupTaskList = {
        ...state.BulkUploadSiteGroupTaskList,
        loading: true,
      };
    },
    updateBulkUploadSiteGroupTaskList(
      state,
      action: PayloadAction<ResponseProps>,
    ) {
      state.BulkUploadSiteGroupTaskList = {
        ...state.BulkUploadSiteGroupTaskList,
        ...action.payload,
      };
    },
    updateBulkUploadTask(state, action: PayloadAction<any>) {
      state.BulkUploadCancelTask = action.payload;
    },
    fetchCreateBulkUser(state, action: PayloadAction<any>) {
      state.NewBulkUploadUser = {
        ...state.NewBulkUploadUser,
        loading: true,
      };
    },
    updateCreatedBulkUser(state, action: PayloadAction<ResponseProps>) {
      state.NewBulkUploadUser = {
        ...state.NewBulkUploadUser,
        ...action.payload,
      };
    },
    fetchCreateSMbulkUser(state, action: PayloadAction<any>) {
      state.NewBulkUploadsmUser = {
        ...state.NewBulkUploadsmUser,
        loading: true,
      };
    },
    updateCreatedSMbulkUser(state, action: PayloadAction<ResponseProps>) {
      state.NewBulkUploadsmUser = {
        ...state.NewBulkUploadsmUser,
        ...action.payload,
      };
    },
    updateBulkUpladModal(state, action: PayloadAction<any>) {
      state.isOpenBulkUpload = action.payload;
    },
    fetchImpersonateUser(state, action: PayloadAction<any>) {
      state.ImpersonateUser = {
        ...state.ImpersonateUser,
        loading: true,
        status: 'pending',
      };
    },
    updateimpersonateUser(state, action: PayloadAction<ResponseProps>) {
      state.ImpersonateUser = {
        ...state.ImpersonateUser,
        ...action.payload,
      };
    },
    closeImpersonateUserSession(state, action: PayloadAction<any>) {
      state.CloseImpersonateSession = {
        ...state.CloseImpersonateSession,
        loading: true,
      };
    },
    updateImpersonateUserSession(state, action: PayloadAction<ResponseProps>) {
      state.CloseImpersonateSession = {
        ...state.CloseImpersonateSession,
        ...action.payload,
      };
    },
    updateUserActiveTab(state, action: PayloadAction<any>) {
      state.getActiveUserTab = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = AdminSlice;
