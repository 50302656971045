import { apiInstance } from '../apiInstance';
import { API_POINTS } from '../Constant';

export const getPermissionList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PERMISSION, { data: payload })
    .then(res => res.data);
};
export const postCreatePermission = async (request: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_CREATE_PERMISSION)
    .then(res => res.data);
};
export const updatePermission = async (request: any) => {
  return await apiInstance()
    .patch(API_POINTS.UPDATE_PERMISSION)
    .then(res => res.data);
};
export const deletePermission = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_PERMISSION, { data: payload })
    .then(res => res.data);
};

// permission group
export const getPermissionGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PERMISSION_GROUP, { params: payload })
    .then(res => res.data);
};
export const postCreatePermissionGroup = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_CREATE_PERMISSION_GROUP, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const updatePermissionGroup = async (request: any) => {
  return await apiInstance()
    .patch(API_POINTS.UPDATE_PERMISSION_GROUP)
    .then(res => res.data);
};
export const deletePermissionGroup = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_PERMISSION_GROUP, { data: payload })
    .then(res => res.data);
};
export const getPermissionGroupCustomersList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PERMISSION_CUSTOMERS_LIST, { params: payload })
    .then(res => res.data);
};

// users
export const postCreateUser = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_CREATE_USER, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postUserList = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_LIST, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getUserValidation = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_VALIDATION, { params: payload })
    .then(res => res.data);
};
export const getUserDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_DETAILS, { params: payload })
    .then(res => res.data);
};

export const updateUser = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.PATCH_UPDATE_USER, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const suspendUser = async (payload: any) => {
  return await apiInstance()
    .put(API_POINTS.PUT_SUSPEND_USER, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const deleteUser = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_USER, { data: payload })
    .then(res => res.data);
};
export const resetPassword = async (payload: any) => {
  return await apiInstance()
    .put(API_POINTS.PUT_USER_RESET_PASSWORD, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postAssociateSite = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_ASSOCIATE_SITE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const postUserGroupList = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_GROUP_LIST, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getUserSiteList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_SITE_LIST, { params: payload })
    .then(res => res.data);
};
export const getUserCompanyList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_COMPANY_LIST, { params: payload })
    .then(res => res.data);
};
export const getUserPermissionGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PERMISSION_GROUP_LIST, { params: payload })
    .then(res => res.data);
};
export const postPermissionGroup = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_PERMISSION_GROUP_CHILD, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

// permission management

export const getUserPermissionsList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_PERMISSION_LIST, { params: payload })
    .then(res => res.data);
};
export const getUserPermissionsGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_PERMISSION_GROUP_LIST, { params: payload })
    .then(res => res.data);
};
export const getUserPermissionsGroupDetail = async (payload: any) => {
  return await apiInstance()
    .get(`${API_POINTS.GET_USER_PERMISSION_GROUP_DETAIL}/${payload.groupId}`)
    .then(res => res.data);
};
export const postNewPermissionGroup = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_NEW_PERMISSION, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const putEditPermissionGroup = async (payload: any) => {
  return await apiInstance()
    .put(
      `${API_POINTS.PUT_USER_PERMISSION_GROUP}/${payload.groupId}`,
      payload.data,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
export const deletePermissionsGroup = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_USER_PERMISSION_GROUP, { params: payload.data })
    .then(res => res.data);
};

// search group
export const getSearchPermission = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SEARCH_PERMISSION, { params: payload })
    .then(res => res.data);
};
export const getSearchPermissionGroup = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SEARCH_PERMISSION_GROUP, { params: payload })
    .then(res => res.data);
};
export const getSearchPermissionUser = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SEARCH_PERMISSION_USER, { params: payload })
    .then(res => res.data);
};

export const getUserGroupDetail = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_USER_PERMISSION_GROUP_DETAILS, { params: payload })
    .then(res => res.data);
};

export const postPermissionGroupToUser = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_PERMISSION_GROUP_TO_USER, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const checkUserPermissionedGroupStatus = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.CHECK_USER_PERMISSION_GROUP_STATUS, { params: payload })
    .then(res => res.data);
};

export const deletePermissionUser = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_USER_PERMISSIONS, { params: payload.data })
    .then(res => res.data);
};

export const putPermissionGroupName = async (payload: any) => {
  return await apiInstance()
    .put(
      `${API_POINTS.UPDATE_PERMISSION_GROUP_NAME}/${payload.groupId}`,
      payload.data,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};

export const postUserSiteGroupList = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_USER_GROUP_LIST, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const getSitesList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITES_LIST, { params: payload })
    .then(res => res.data);
};

export const getSiteRemark = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITE_REMARK, { params: payload })
    .then(res => res.data);
};

export const updateSiteVisibility = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.UPDATE_SITE_VISIBILITY, payload.data, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const getAssociateSiteGroup = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SITE_ASSOCIATE_SITE_GROUP, { params: payload })
    .then(res => res.data);
};
export const getSiteContactDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITE_CONTACT_detail, { params: payload })
    .then(res => res.data);
};
export const updateSiteContactDetails = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.PATCH_SITE_CONTACT_detail, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getCompanySiteContactDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_COMPANY_SITE_CONTACT_detail, { params: payload })
    .then(res => res.data);
};

export const updateCompanySiteContactDetails = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.PATCH_COMPANY_SITE_CONTACT_detail, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const updateCompanySites = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.UPDATE_COMPANY_SITES, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
// export const getUserSiteGroups = async (payload: any) => {
//   return await apiInstance()
//     .get(API_POINTS.GET_USER_GROUP_LIST, { params: payload })
//     .then(res => res.data);
// };

export const getCSSiteGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_CS_SITEGROUP_LIST, { params: payload })
    .then(res => res.data);
};
export const getSitesDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITES_DETAILS, { params: payload })
    .then(res => res.data);
};
export const getCompanyAccountType = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_COMPANY_ACCT_TYPE, { params: payload })
    .then(res => res.data);
};
export const getProfilePartyNumbers = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_PROFILE_PARTY_NUMBER, { params: payload })
    .then(res => res.data);
};
export const getMasterCompanyList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_MASTER_COMPANY_LIST, { params: payload })
    .then(res => res.data);
};
export const createCompanyProfile = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.CREATE_COMPANY_PROFILE, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const updateCompanyProfile = async (payload: any) => {
  return await apiInstance()
    .patch(
      `${API_POINTS.UPDATE_COMPANY_PROFILE}?companyId=${payload.companyId}`,
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};

export const getAssociateSiteGroupAllSitesList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_SITEGROUP_ALL_SITES_CS, { params: payload })
    .then(res => res.data);
};

export const updateCSSiteGroup = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.UPDATE_CS_SITE_GROUPS, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};

export const postCSSiteGroup = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_CS_SITE_GROUPS, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const deleteSiteGroup = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_SITE_GROUP, { params: payload })
    .then(res => res.data);
};

// Notifications
export const getNotificationList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTIFICATION_LIST, { params: payload })
    .then(res => res.data);
};
export const getNotificationCompanyList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTIFICATION_COMPANY_LIST, { params: payload })
    .then(res => res.data);
};
export const getNotificationDetail = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTIFICATION_DETAILS, { params: payload })
    .then(res => res.data);
};
export const postAddNotification = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_ADD_NOTIFICATION, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const patchUpdateNotification = async (payload: any) => {
  return await apiInstance()
    .patch(API_POINTS.PATCH_UPDATE_NOTIFICATION, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const deleteNotification = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_NOTIFICATION, { params: payload })
    .then(res => res.data);
};
export const getNotificationSiteGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTIFICATION_SITE_GROUP_LIST, { params: payload })
    .then(res => res.data);
};
export const getNotificationSiteList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_NOTIFICATION_SITE_LIST, { params: payload })
    .then(res => res.data);
};
export const getChronicList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_CHRONIC_PROFILE_LIST, { params: payload })
    .then(res => res.data);
};
export const getChronicDefinitionList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_CHRONIC_DEFINITION, { params: payload })
    .then(res => res.data);
};
export const getChronicProfileData = async (payload: any) => {
  return await apiInstance()
    .get(`${API_POINTS.GET_CHRONIC_PROFILE_DATA}/${payload.profileId}`)
    .then(res => res.data);
};
export const postChronicProfileRequest = async (payload: any) => {
  return await apiInstance()
    .post(
      API_POINTS.POST_CHRONIC_PROFILE_REQUEST,
      { ...payload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
export const putChronicProfileRequest = async (payload: any) => {
  return await apiInstance()
    .put(
      API_POINTS.POST_CHRONIC_PROFILE_REQUEST,
      { ...payload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
export const deleteChronicProfileData = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.DELETE_CHRONIC_PROFILE, { params: payload })
    .then(res => res.data);
};
export const postBulkSiteGroup = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_UPLOAD_SITE_GROUP, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data);
};
export const GETBulkSiteGroupStatus = async (payload: any) => {
  return await apiInstance()
    .get(`${API_POINTS.GET_BULK_UPLOAD_SITE_GROUP_STATUS}/${payload}`)
    .then(res => res.data);
};
export const GetSiteGroupBulkUploadList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_BULK_UPLOAD_SITE_GROUP_STATUS, { params: payload })
    .then(res => res.data);
};
export const postBulkUser = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_UPLOAD_USER, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data);
};
export const postSMBulkUser = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.POST_UPLOAD_SM_USER, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(res => res.data);
};
export const getImpersonateUserDetails = async (payload: any) => {
  return await apiInstance()
    .post(
      API_POINTS.IMPERSONATE_USER,
      { ...payload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
export const closeImpersonateUserSession = async (payload: any) => {
  return await apiInstance()
    .post(
      API_POINTS.IMPERSONATE_USER_SESSION,
      { ...payload },
      {
        params: { userId: payload.userId },
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
