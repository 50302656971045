import { TabsContainer } from './styles';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Tabs from './component/Tabs';
import { useCallback, useEffect, useState } from 'react';
import { actions } from 'app/features/DashboardSlice/slice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getServicesCount,
  getServiceCountInfo,
  getNetworkCountInfo,
} from 'app/features/DashboardSlice/Selectors';
import QuickStats from './component/QuickStats';
import ModalComponent from 'app/component/core/Modal';
import QuickStatsModal from './component/Cards/QuickStatsModal';
import {
  DASHBOARD_SERVICE_REQUEST,
  DASHBOARD_NETWORK_OUTAGES,
} from 'utils/Constants';
import { CONTEXT } from 'utils/Constants/Content';
import Banner from './component/BannerNotfication';
import { getBannerNotification } from 'app/features/GlobalSlice/Selectors';
import { LayoutWrapper } from 'app/pages/styles';

export function Dashboard(): JSX.Element {
  const theme = useTheme();
  const selectorServiceCount: any = useSelector(getServicesCount, shallowEqual);
  const selectorServiceCountInfo: any = useSelector(getServiceCountInfo);
  const selectorNetworkCountInfo: any = useSelector(getNetworkCountInfo);

  const serviceList = selectorServiceCountInfo.data || [];
  const networkList = selectorNetworkCountInfo.data || [];
  const [open, setOpen] = useState(false);
  const [activeLabel, setActiveLabel] = useState('');

  const [serviceReqCurrentPage, setServiceReqCurrentPage] = useState(1);
  const [networkCurrentPage, setNetworkCurrentPage] = useState(1);
  const selectorBannerNotification: any = useSelector(getBannerNotification);
  const selectorNotification = selectorBannerNotification.data || [];
  const isMidumScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const onServiceReqPagination = (pageNo: any): any => {
    setServiceReqCurrentPage(pageNo);
    dispatch(
      actions.fetchServiceCountInfo({
        page: pageNo,
        limit: 10,
        recordCountRequire: true,
      }),
    );
  };
  const onNetworkPagination = (pageNo: any): any => {
    setNetworkCurrentPage(pageNo);
    dispatch(
      actions.fetchNetworkCountInfo({
        page: pageNo,
        limit: 10,
        recordCountRequire: true,
      }),
    );
  };
  const onModalClose = (): any => {
    setOpen(false);
    setServiceReqCurrentPage(1);
    setNetworkCurrentPage(1);
  };
  const dispatch = useDispatch();

  const oniconHandleClick = useCallback((item: any) => {
    const { label, score } = item;
    setActiveLabel(label);
    if (score > 0) {
      switch (label) {
        case CONTEXT.ACTIVE_SERVICE_REQUEST:
          setOpen(true);
          dispatch(
            actions.fetchServiceCountInfo({
              page: 1,
              limit: 10,
              recordCountRequire: true,
            }),
          );
          break;
        case CONTEXT.NETWORK_OUTAGES:
          setOpen(true);
          dispatch(
            actions.fetchNetworkCountInfo({
              page: 1,
              limit: 10,
              recordCountRequire: true,
            }),
          );
          break;
      }
    }
  }, []);

  useEffect(() => {}, [selectorServiceCount]);

  useEffect(() => {
    return () => {
      dispatch(
        actions.updateServiceCount({
          loading: false,
          networkOutageCount: 0,
          serviceRequestCount: 0,
          securityEventsCount: 0,
        }),
      );
    };
  }, []);

  return (
    <LayoutWrapper sx={{ padding: 0 }}>
      {selectorNotification.length > 0 && (
        <Grid item xs={12} md={12} lg={12} pb={1}>
          <Banner />
        </Grid>
      )}
      <Grid item xs={12} md={12} lg={12}>
        <QuickStats onIconItemClick={oniconHandleClick} />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TabsContainer
          theme={theme}
          sx={{
            marginTop: 2,
            overflow: 'hidden',
            height: isMidumScreen ? '66vh' : '73vh',
          }}
        >
          <Tabs />
        </TabsContainer>
      </Grid>
      <ModalComponent
        modalOpen={open}
        children={
          activeLabel === CONTEXT.ACTIVE_SERVICE_REQUEST ? (
            <QuickStatsModal
              label={activeLabel}
              headers={DASHBOARD_SERVICE_REQUEST}
              ListViewData={serviceList}
              onCancelClick={onModalClose}
              loading={selectorServiceCountInfo.loading}
              TotalListCount={selectorServiceCountInfo.recordCount}
              onPagination={onServiceReqPagination}
              currentPage={serviceReqCurrentPage}
            />
          ) : (
            <QuickStatsModal
              label={activeLabel}
              headers={DASHBOARD_NETWORK_OUTAGES}
              ListViewData={networkList}
              onCancelClick={onModalClose}
              loading={selectorNetworkCountInfo.loading}
              TotalListCount={selectorNetworkCountInfo.recordCount}
              onPagination={onNetworkPagination}
              currentPage={networkCurrentPage}
            />
          )
        }
        setModalOpen={onModalClose}
      />
    </LayoutWrapper>
  );
}
