export const ERROR_TEXT = {
  API_ERROR: 'Something went wrong!',
  COMPANY: 'At least one company should be selected.',
  SITE: 'At least one site should be selected.',
  SITE_GROUP: 'At least one site group should be selected.',
  PERMISSION: 'At least one permission should be selected.',
  NO_DATA_DISPLAY: 'No Data To Display',
  FIRST_NAME: 'First name cannot be left blank',
  LAST_NAME: 'Last name cannot be left blank',
  MOBILE: 'Mobile Number cannot be left blank',
  TITLE: 'Title cannot be left blank',
  MESSAGE: 'Message cannot be left blank',
  START_DATE: 'Start date cannot be left blank',
  TIME: 'Time should be greater than current time',
  END_DATE: 'End date cannot be left blank',
  MOBILE_BLANK: 'Mobile Number cannot be left blank',
  MOBILE_TEN_DIGIT: 'Please enter a valid 10-digit mobile number',
  ALTERNATE_MOBILE_BLANK: 'Mobile Number cannot be left blank',
  ALTERNATE_MOBILE_TEN_DIGIT: 'Please enter a valid 10-digit alternate number',
  EMAIL_BLANK: 'Email ID cannot be left blank',
  EMAIL_FORMAT: 'Email ID format should be : john@iss.com',
  MAX_CHAR: 'Max 12 charecters only.',
  JOB_TITLE: 'Job title cannot be left blank',
  JOB_TITLE_VALID: 'Please enter valid job title',
  USERNAME_BLANK: 'User name cannot be left blank',
  AFFECTED_SITE: 'Please select Affected Site',
  AFFECTED_SYSTEM: 'Please select Affected System',
  PREFERRED_HOUR: 'Preferred hours cannot be left blank',
  DESCRIPTION_BLANK: 'Description cannot be left blank',
  DESCRIPTION_LIMIT: 'Description cannot be more than 680 characters',
  IDEA: 'Idea cannot be left blank',
  PROFILE_DEFINITION: 'Profile definition cannot be left blank',
  PARAM_1: 'Param 1 cannot be left blank',
  PARAM_2: 'Param 2 cannot be left blank',
  PARAM_3: 'Param 3 cannot be left blank',
  NAME: 'Name cannot be left blank',
  JOBTITLE: 'Job Title cannot be left blank',
  PASSCODE_BLANK: 'Alarm code cannot be left blank',
  CORRECT_PASSCODE:
    'Alarm codes of this nature are not encouraged, please consider choosing another number',
  EMPLOYEE_ID: 'Employee ID cannot be left blank',
  VALID_MOBILE: 'Please enter a valid mobile number',
  VALID_PHONE: 'Please enter a valid phone number',
  EMAIL_REQUIRED: 'Email ID is required to send details',
  ALREADY_EXIST: ' already exists.',
  HOME_NUMBER_BLANK: 'Home Number cannot be left blank',
  MOBILE_PHONE_BLANK: 'Phone Number cannot be left blank',
  ALREADY_USED_PASSCODE:
    'You have entered a alarm code that has previously or is currently being used. Please enter a different alarm code.',
  VALID_NORTH_AMERICAN_NUMBER: 'Number must be a valid North American number.',
  PASSCODE_MAX_LENGTH:
    'Please only key a maximum number of digits allowed by the panel.',
  PASSCODE_CHANGED:
    'As you have made changes to an existing contact, please amend the alarm code.”',
  PASSCODE_RESTRICTED:
    'This alarm code is not allowed. Please re-enter a different number.',
  PASSCODE_ALREADY_USED:
    'This alarm code is either in use or has been used before. Please re-enter a different number.',
  PASSCODE_REQUIRED_FOR_PANEL:
    'Alarm code is a required field for panel users. To remove a contact from the panel, the user must be deleted.',
  UPLOAD_FILE: 'Please save your file in the correct format and upload again',
  PAGE_NOT_FOUND: '404 Page Not Found! :(',
  ADD_TO_CALL_LIST:
    'Please enter a {key} number to add this contact to the call list.',
  VALID_FIRST_NAME: 'Please enter a valid first name',
  VALID_LAST_NAME: 'Please enter a valid last name',
  SR_TITLE: 'Service request title cannot be left blank',
  OTHER_PREFERRED_REQUIRED: `Other's description cannot be left blank`,
  UPLOAD_USERS_WARNING_MESSAGE:
    'You are about to process multi changes to users. Please confirm you wish to proceed.',
  UPLOAD_SM_CONTACT_WARNING_MESSAGE:
    'You are about to process multiple changes to contacts. Please confirm you wish to continue.',
  SELECT_COMPANY: 'Select at least one company from dropdown.',
  IMPERSONATE_USER_SESSION_EXPIRED_MESSAGE:
    'The user session has expired. Please initiate a new session from the user section.',
  IMPERSONATE_USER_SESSION_EXPIRED_WARNING:
    'The user session will expired in 5 minutes.',
  USER_PERMISSION_DENIEND_WARNING:
    'You do not have permission to this function. Please contact your Company administrator.',
  DELETE_SITE_GROUP_MESSAGE:
    'Are you sure you wish to delete this Site Group ?',
  VIDEO_NON_WORKING_BROWSER_WARNING:
    'The browser you are using is not compatible with the video viewer. Please try again using a different browser.',
  DELETE_CHRONIC_PROFILE_MESSAGE: 'Do you wish to remove this Chronic Profile?',
  FILE_EXCEED:
    'The maximum limit for file uploads has been reached. No more files can be uploaded for this Service Request.',
  SR_FILE_SIZE:
    'You have exceeded the maximum allowed file size. Please reduce the size of the file and try again.',
  SR_FILE_FORMAT:
    'The file you are attempting to upload is not allowed. Please save in a different format and try again.',
  SR_FILE_ALLOWED:
    'You have reached the maximum file upload limit. Only {0} files can be uploaded for this Service Request.',
  BULK_UPLOAD: 'Please save your file in the correct format and upload again',
  SITE_GROUP_NAME: 'Site group name cannot be left blank.',
  NOTIFICATION_EXPIRED: 'This notification cannot be edited as it has expired.',
  SITE_ON_TEST:
    'Your system is under test. You must stop the test before starting a self-test or wait for the test to complete.',
  SELECT_ZONE: 'Please select atleast one zone to raise a service request',
  SELF_TEST_IN_PROGRESS:
    'Your self-test is in progress. Please complete it before proceeding with this action.',
  PENDING_INSTALLATION: 'This site is pending installation',
};
export const DISPLAY_TEXT = {
  COMING_SOON: 'Coming Soon',
  NO_RECORD_FOUND: 'No record found!',
  NO_LOG_AVAILABLE: 'No communication log available!',
  NO_DATA_To_DISPLAY: 'No data to display message',
  NOT_APPLICABLE: 'Not Applicable',
  NO_RECORD_SELECTED: 'No record selected',
  DOWNLOAD: `To download the NVR ActiveX Installer or HikVision Web Installer, use the Chrome browser. 
    To view live and recorded video, use Internet Explorer V11 browser.`,
  DOWNLOAD_VLC: 'To View the downloaded video please install ',
  POPULAR_DOWNLOAD: 'Most Popular Downloads',
  NO_LINKS: 'No Link Available',
  SUBMIT_CASE: 'Submit Service Request',
  HIDE_CS_SITE_DATA_CHECKED_MESSAGE:
    'If the site is unchecked (unhidden), the data from these sites will be used to populate widget data. Also the site name and information will now display in Site Overview data.',
  HIDE_CS_SITE_DATA_UNCHECKED_MESSAGE:
    'If the site is checked (hidden), the data from these sites is not used to populate widget data. Also the site name and information will not display in Site Overview data.',
  ALL_COMPANY_SELECTED: 'All companies selected',
  ALL_SITE_GROUP_SELECTED: 'All Site Groups selected',
  ALL_PERMISSION_SELECTED: 'All Permission Groups selected',
  EXISTING_OPEN_CASES: 'Existing Open Cases',
  ADD_CHRONIC: 'Add Chronic Profile',
  EDIT_CHRONIC: 'Edit Chronic Profile',
  SUBMIT: 'Submit',
  SAVE: 'Save',
  SEND: 'Send',
  CANCEL: 'Cancel',
  NO_EXISTING_OPEN_CASES: 'No existing open cases',
  FUTURE_ENHANCEMENT: 'Future Enhancement',
  EVENT_QUICK_STAT_DESC:
    'This tile will display the number of alarm events that are currently active. Clicking on the info icon will then display a pop-up of the actual alarm event details, together with information on the actual panel installed at the location.',
  EVENT_TAB_DESC:
    'The events tab will display details of alerts that have been triggered, together with which device was affected, the severity of the alarm, and the date/time of the incident.',
  DEVICE_QUICK_STAT_DESC:
    'The Devices Offline tile will display the number of devices that are currently offine. Clicking on the info icon will display details on the actual device affected, and date/time of when the device went down.',
  DEVICE_LINK_DESC:
    'The Devices page will provide users with a view of each Company, or individual site, installed devices, together with the current status of the device. Where possible, the user will then be able to access additional functionality such as launching the Video Viewers, launch panel information, lock/unlock doors, arm/disarm doors.',
  OPEN_PROJECT_LINK_DESC:
    'This section will provide a tool that will display the status of any ongoing installation projects, where you will be able to interact directly with the project team. There will also be the ability to view previously completed projects. Basic project information will be displayed such as location, description, current status, and a target start and end date.',
  SITE_TEST_DESCRIPTION:
    "This feature will allow an individual site to be placed 'On Test' by the entering of a few simple settings.",
  LOCKDOWN_DESCRIPTION:
    "This feature will allow an site to be put on 'Lockdown' by simply selecting this button and confirming your selection. ",
  WHEATHER_DESCRIPTION:
    'This icon will open a weather forecast for the selected location.',
  INCIDENT_DESCRIPTION:
    'This will automatically generate an Incident Report that can be printed directly or downloaded.',
  VIDEO_DESCRIPTION:
    'If there is a video recording available for the event, then it will be available for playback in this section.',
  CCM_DESCRIPTION:
    "This will display interactions between customers and Interface team members, much like.a 'Chat' box. Both customers and Interface users can directly interact, leaving notes, uploading files etc.",
  USER_ALARM_CODES:
    'This section will display an individual users alarm code(s), together with details of the company, site and alarm panel.',
  ADD_CONTACT: 'Add Contact',
  EDIT_CONTACT: 'Edit Contact',
  UNSUPPORTED_IE_MESSAGE:
    'This link may not work on some older browsers. If you cannot access the link, please use a different browser.',
  NO_SITE_SELECTED: 'Please select company and site to showcase graph.',
  NO_VIDEO_RELATED_DATA:
    'Site has camera devices but no video-related installed systems that are in Complete status.',
  NO_GRAPHITE_DATA:
    'Site has managed network system installed but no graphite data.',
  NO_GRAPHITE_NETWORK_DATA:
    'Site has network system installed but no graphite data.',
  NON_MANAGED_NETWORK: 'Non Managed Network',
  MANAGED_NETWORK: 'Managed Network',
  DONT_HAVE_PERMISSION:
    'You do not have the correct permission to view alarm codes/passwords. These details will be emailed to the user.',
  DRAG_OVER: 'Drag Over',
  CALL_LIST_LOCKED: 'Call list is locked.',
  DELETE: 'Delete',
  PREFERRED_CONTACT: 'Preferred Contact',
  CLICK_TO_BROWSE: 'Click to browse or drag and drop your files',
  DATE_ERROR_MESSAGE: 'Start Date should be less than End Date',
  UPLOAD_FILE_STATUS: 'Upload file status list',
  RESTRICTED_PASSCODE: 'Restricted Alarm code',
  JOB_TITLE_LIST: 'Job Title List',
  JOB_ROLE_CONTACT_LIST: 'Job role preference/order list',
  JOB_LIST_DESCRIPTION: `This section allows a set list of job titles to be added which is applied at a company level and will apply for all sites within the Company. 
  Job titles added here will then be able to be selected in the Job Role Preference/Order list
  Details will be used to load the screen when the Security Manager screen is loaded, and cannot be amended or removed by a non-admin user. `,
  CONTACT_LIST_DESCRIPTION: `The job role preference list will initially match the job title list.
  Any new job titles added in the job title list will be added to the preference list, and any job titles removed from the job title list will also be removed from the preference list 
  The order of job titles can be amended in the preference list and any job titles can be added or removed, providing they are in the job title list.
  If more than one call list exists, the admin user can organise all lists associated with the company. 
  Clicking this lock preference checkbox will mean that the order cannot be amended in the Security Manager screen, and preferences cannot be re-ordered, removed, or added from the list by a non-Admin user.`,
  RESTRICTED_PASSCODE_DESCRIPTION: `An Admin user can add any four-digit number to this list, and numbers on this list cannot be assigned to a contact.
  Every company will, by default, have the number 2580 and 1234 included in their restricted list, and except for 2580 and 1234, numbers can be removed from the list.`,
  NO_NOTIFICATION_FOUND: 'No New Notifications found!',
  ACTIVATE_USER: 'You are about to activate this user. Do you wish to proceed?',
  DEACTIVATE_USER:
    'You are about to deactivate this user. Do you wish to proceed?',
  ZONE_SELECTION:
    'If no zones are selected, then the full system will be placed on test.',
  IMPERSONATE: 'Impersonate',
  SESSION_EXPIRE: 'Session ends in ',
  ESCALATE_REQUEST: 'Escalate',
  ACTIVE_SECURITY_EVENTS:
    'This number represents security events that were active and now resolved up to two hours previously',
  ADD_EDIT_CONTACT: 'Processing your request, this may take a few seconds.',
  PREFERRED_OTHER_DESCRIPTION:
    'Please enter a time for an engineer visit, with reason for not choosing a standard time.',
  SR_SUPPORT_TITLE:
    'Please select a site.  If this request is for all sites, please call our support team at (800) 727-1668 who will enter the request on your behalf.',
  SUPPORT_CONTACT_INFO: 'Support Contact Information',
  SUPPORT_CONTACT_INFO_DESCRIPTION_1:
    'Our Support Team Agents will contact the individual below with regards to the service request you are submitting.',
  SUPPORT_CONTACT_INFO_DESCRIPTION_2:
    'Please verify the contact information or change as necessary.',
  PREMISE_CONTACT:
    'This will be the contact details for the Premise contact of that location.',
  SUPPORT_CONTACT:
    'This will be the contact details for the Support contact of that location.',
  ESCALATION_CONTACT:
    'This will be the contact details for the Escalation contact of that location.',
  PREMISE_CONTACT_TITLE: 'Site Premise Contact Details',
  SUPPORT_CONTACT_TITLE: 'Site Support Contact Details',
  ESCALATION_CONTACT_TITLE: 'Escalation Contact Details',
  SM_TITLE: 'Security Manager',
  ATTACHEMENT: 'Attachments',
  SM_ALARM_CODE_TOOLTIP:
    'This is the actual code stored in the alarm panel for the selected user',
  SM_VERBAL_PASSWORD_TOOLTIP:
    'This is the password that is used to verbally verify a user if they contact us',
  EVENT_DETAIL_ACTION_LABEL: 'Actions Taken',
  CONTACT_SENSON: 'Please make sure to select zones that can be tested.',
  CONTACT_SENSON_SUB_TITLE:
    'Please be aware that not all zones can be tested. Please only select zones you are able to actually test.',
  INTRUSION_ZONE: 'Intrusion Zone',
  PANIC_ZONE: 'Panic Zone',
  SELFT_TEST_PROCEED:
    'By initiating the self - Test mode, your system will be put automatically into test mode for maximum of one hour.',
  SERVICE_REQUEST_OPEN_TITLE: 'Do you want to open an Service Request ?',
  SELF_TEST_PASSED:
    'Your results have been recorded and your system is back to normal functionality.',
  TIMER_TITLE: 'The Test period is ending , do you want to extend by 30 mins?',
  INCOMPLETE_TEST:
    'The test cannot be restarted and the test will be marked as incomplete. Your system will be back online, so any alarms triggered will be treated as live events.',
  CANCLE_TEST:
    'The test will be marked incomplete. Are you sure you want to cancel the test?',
  SELF_TEST: {
    HOW_TO_TEST: 'How To Test',
    STEP_ONE_TITLE: 'Your Security Zones',
    STEP_ONE_DESCRIPTION:
      'Below is the listing of Zones identified  for your location to test. You will  use this for reference.',
    STEP_ONE_FORM: 'Download Manual Test Form',
    STEP_ONE_ZONES: [
      'FRONT DOOR CONTACT',
      'SIDE DOOR CONTACT',
      'BACK DOOR CONTACT',
      'ROOF HATCH CONTACT',
      'DRIVE THRU WINDOW CONTACT',
      'DINING MOTION',
      'SIDE DINING MOTION',
      'DRIVE THRU WINDOW MOTION',
      'MANAGER STATION MOTION',
    ],
    STEP_TWO_DESCRIPTION:
      'TO PERFORM TEST, SYSTEM MUST SHOW GREEN READY LIGHT ON THE KEYPAD. THIS  REQUIRES NO MOVEMENT IN THE RESTAURENT.',
    STEP_TWO_CONTENT_TITLE:
      'Start by placing the Alarm system in Test Mode at the Keypad.',
    STEP_THREE_DESCRIPTION:
      'Begin testing the Door Contacts and the Interior Motions. We recommend testing with a colleague: one person to walk the floor , and the other person by the keypad watching the Display. When a device within a zone is triggered, you will hear a chime at the keypad. The keypad will display “Fault “ with the zone number or zone description.',
    STEP_PARA_1:
      'Once you close the zone, or the zone clears, the display will return to showing "Test In Progress.',
    STEP_PARA_2:
      'To test a Door Contact, open and close the door. To test an Interior Motion sensor, we recommend walking across the path of the motion, and under the motion (please see example image below).',
    STEP_PARA_3:
      'Once you close the zone, or the zone clears, the display will return to showing "Test In Progress',
    STEP_NOTE_1:
      'Mark a PASS if you heard the chime and saw the zone on the keypad.',
    STEP_NOTE_2:
      'Mark a FAIL if you did not hear the chime and did not see the zone on the keypad.',
    OPEN_SR: 'Would you like to open a service request?',
    FAILED_ZONE_YES:
      'Selecting this button will allow you to select which devices you want to open a service request for.',
    FAILED_ZONE_NO:
      'Selecting this button will complete the test and store the results in the history table.',
    SLEF_TEST_PDF_TITLE: 'Alarm Self Test Instructions',
  },
  CLOSE_SR_CONFIRMATION:
    'You are about to close this service request, and any associated service trips. Do you want to continue?',
  FILTER_VIEW_REPORT:
    'Please select the data required from the dropdown filters above.',
  SR_WORK_ORDER_PRESENT:
    'This Service Request cannot be canceled automatically at this time as work has already started. Please call us at 800-727-1668 to speak with an agent.',
  ACTIVITY_LOG: 'Actvity Log',
};

export const CONTEXT = {
  SERVICE_REQUEST: 'Open Service request',
  NEW_RELASES: 'New Releases',
  SUBMIT_IDEA: 'Submit an Idea',
  HELPFUL_DOWNLOAD: 'Helpful Downloads',
  ACTIVE_SERVICE_REQUEST: 'Active Service Requests',
  NETWORK_OUTAGES: 'Site Network Down',
  DEVICE_OFFLINE: 'Devices Offline',
  EVENT: 'Security Events',
  ONLINE_MANUAL: 'Online Manuals',
  E_LEARNING: 'E-learning',

  SITE: 'site',
  NETWORK: 'network',
  SERVICE_TRIP: 'servicetrip',
  SERVICE_REQUESTS: 'servicerequest',
  SECURITY_EVENTS: 'securityEvents',
  PERMISSION_GROUP: 'permissiongroup',
  USER_MANAGEMENT: 'usermanagement',
  USER_LIST: 'userlist',
  SECURITY_MANAGER_USER_LIST: 'securityManagerUserList',
  COMPANY_LIST: 'companylist',
  CHRONIC_LIST: 'chronicList',
  SITE_CS_LIST: 'siteCSlist',
  SITE_GROUP_CS_LIST: 'siteGroupCSlist',
  SITE_DETAILS_CHECK_LIST: 'siteDetailsCheckList',
  SITE_DETAILS_CS_LIST: 'siteDetailsCSlist',
  INSTALLED_SYSTEM_CS_LIST: 'installedSystemCSlist',
  INSTALLED_SYSTEM_ORACLE_CS_LIST: 'installedSystemOracleCSlist',
  VIEW_GROUP_CS_LIST: 'viewGroupCSlist',
  NOTIFICATION_LIST: 'notificationlist',
  USER_PROFILE_PERMISSION_LIST: 'userProfilePermissionlist',
  USER_PROFILE_PERMISSION_GROUP_LIST: 'userProfilePermissionGrouplist',
  SYSTEM_EXPORT_LIST: 'systemExportList',
  SITES_EXPORT_LIST: 'sitesExportList',
  NETWORK_EXPORT_LIST: 'networkExportList',
  SERVICE_REQUEST_EXPORT_LIST: 'serviceRequestExportList',
  SERVICE_REQUEST_WORK_ORDER_REPORT: 'openserviceRequestwoReport',
  SERVICE_TRIP_EXPORT_LIST: 'serviceTripExportList',
  SECURITY_EVENT_EXPORT_LIST: 'serviceEventExportList',
  USER_NOTIFCATION_LIST: 'userNotifcationlist',
  NEW_RELASE_LIST: 'newrelaselist',
  ACTIVITY_LOG: 'activityLog',
  CONTACT_LIST: 'contactList',
  SITE_MANAGER: 'siteManager',
  DASHBOARD: 'dashboard',
  SITE_GROUP_UPLOADED_LIST: 'siteGroupUploadedList',
  BULK_UPLOADED_STATUS: 'siteGroupUploadedStatus',
  UPLOAD_BULK_STATUS: 'uploadBulkStatus',
  COMPANY: 'company',
  USER: 'User',
  SITE_GROUP: 'SiteGroup',
  SM_USER: 'SecurityManager',
  SEARCH: 'search',
  AVATAR: 'avatar',
  GO: 'Go',
  SITE_ANALYTICS: 'Site Analytics',
  NEW_SERVICE_REQUEST: 'New Service Request',
  INSIGHT_ANALYTICS: 'Insight & Analytics',
  THEME: 'theme',
  NOTIFICATION: 'notification',
  // variable names
  GENERAL: 'general',
  RELEASE: 'new_release',
  BANNER: 'banner',
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
  ERROR: 'error',
  SITE_MANAGER_SOURCE: 'siteManager',
  ADD_TO_COMPANY: 'Company To Add',
  OPEN_REQUEST_ITEM: 'openRequestItem',
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_LIMIT: 10,
  DEFAULT_PAGE_COUNT_REQUIRED: true,
  USER_VIEW: 'View of User ',
  SERVICES: 'Services',
  SERVICE_LEVEL: 'Service Level',
  INTSTALLED_SYSTEM: 'Installed System',
  CLEAR_FILTER: 'Clear all applied filters',
  COLUMNS: 'Columns',
  SM_ALL_ACTIVITY: 'allActivity',
  SM_PANEL_CONTACT_ACTIVITY: 'panelContactActivity',
  SM_OPEN_CLOSE_ACTIVITY: 'OpenCloseActivity',
  SITEGROUP: 'Site Group',
  SM_USER_CONTACT: 'userContact',
  SM_PANEL_CONTACT: 'panelContact',
  SM_TIME_VALIDATION:
    'This report will only display events between the times selected.',
  SM_TIME_INFO:
    'Optional:  for each date in the range you chose, only show activity between certain times of the day',
  SYSTEM: 'System',
  SELF_TEST: 'Self Test',
  SELF_TEST_STATUS: 'Self Test Status',
  SELF_TEST_ID: 'Self Test ID',
  SELF_TEST_SR: 'Service Request No.',
  SELF_TEST_CARRIED: 'Carried Out By',
  SELF_TEST_EMAIL: 'Email ID',
  SERVICE_REQUEST_REPORT: 'ServiceRequestReport',
};

export const SECURITY_MANAGER: any = {
  TEST_STATUS: 'On Test Status',
  MONITORING_ID: 'Site Monitoring ID',
  BUSINESS_UNIT: 'Business Unit',
  PUT_SITE_ON_TEST: 'Put Site on Test',
  STOP_TEST: 'Stop Test',
  PUT_SITE_ON_TEST_LABEL: 'Put Site on Test',
  PANEL_TYPE: 'Panel Type',
  TEST_TYPE: 'Service: Full',
  CALL_LIST: 'Call List',
  LIST: 'List',
  ADD_CONTACT: 'Add contact',
  ON_TEST: 'Site will be on Test till',
  STOP_TEST_CONFIRM_MESAGE:
    'Are you sure you want to stop the Site test early?',
  TEST_PERMISSION_MESSAGE:
    'You do not have permission to use this functionality. Please speak to your company admin',
  DELETE_CONTACT: 'Are you sure you want to remove this contact?',
  PASSCODE_LENGTH: 'Length of alarm code',
  DIGIT: 'digit',
  '1': 'Home',
  '3': 'Mobile',
  DELETE: 'Delete',
  ADD: 'Add',
  JOB_TITLE_EXISTS: 'Job title already exists.',
  PASSCODE_EXISTS: 'Alarm code already exists.',
  JOB_TITLE_LIMIT: 'Job title limit reached',
  PANEL_LIMIT_ALLOWED: 'Max allowed panel user reached.',
  NO_PASSWORD_EDIT: 'No password to edit',
  SECURITY_MANAGER_USER: 'Edit Global User',
  MAXIMUM_NUMBER_LIMIT:
    'The maximum number of contacts has been reached. Please either delete a contact first to add, or edit an existing contact.',
  GLOBAL_USER_DELETE_LABEL:
    'User is assigned to all of these sites. Please select the ones that you want to remove the User from.',
  ADMIN_GLOBAL_USER_EDIT:
    'Please go to the Security Manager User screen in the Admin section to edit Global users.',
};

export const MESSAGES = {
  CHRONIC_DELETE: 'The selected profile has been removed from your company',
  CHRONIC_ADD: 'You have added the profile to your company',
  CHRONIC_UPDATE: 'You have added the profile to your company',
};

export const SITE_TEST_VALIDATION_MESSAGE = {
  REASON_BLANK: 'Please provide a reason for putting the site on test',
  REASON_LIMIT: 'Reason cannot be more than 100 characters',
  MAX_HOURS: 'Please ensure your test duration is less than 24 hours',
  MINUTES_BLANK: 'Please ensure your test duration is 59 minutes or less',
  MAX_MINUTES: 'Please ensure your test duration is 59 minutes or less',
  HOURS_BLANK: 'Please enter a duration up to 23 hours 59 minutes',
  HOURS_TEST_RESTRICTION: 'Please enter a test duration up to 23 hours',
  MAX_ALLOWED: 'Max Allowed On Test Hours',
  MAX_HOURS_LIMIT: `You have entered a duration that exceeds your company's maximum allowed. Please amend the duration to be equal to or less than $ hours.`,
  MAX_HOURS_PERMISSION_LIMIT: `Please ensure your test duration is less then $ hours`,
  HOURS_X_BLANK: 'Please enter a duration up $ hours',
};
export const FILE_FORMAT = {
  xlsx: 'xlsx',
  NON_EX: '.pdf, .doc, .docx, .xlsx, .csv, .jpg, .jpeg, .png, .txt',
};

export const ITEM_DETAILS = {
  COMMUNICATION_LOG: 'Customer Communication Log',
  CASE_NOTE: 'Notes/Communication Log',
  ADD_CASE_NOTE: 'Add Case Note',
  SHOW_CASE_NOTES: 'Case Notes',
  ADD_INTERNAL_NOTE: 'Add Internal Notes',
  ESCALATE_SUCCESS_MESSAGE: 'Your request have been escalated',
  ADD_INTERNAL_NOTE_MESSAGE: 'Your Internal Note have been saved',
  ADD_CASE_NOTE_MESSAGE: 'Your Case Notes have been saved',
  COMMUNICATION_LOG_ERROR:
    'This Service Request is now closed. No further messages can be added to it.',
};
export enum PANELTYPE_ID {
  COMPASS = 1,
  DMP = 2,
  BSCHA = 3,
}
export const CONTACT_TYPE = {
  PRIMARY: 'PRI',
  SUPPORT: 'SUP',
  ESCALATION: 'ESC',
};
export const SERVICE_REQUEST_STATUS = [
  'Closed',
  'Customer Refused Repair',
  'Cancelled',
  'Resolved Successful',
  'Works As Intended',
];
