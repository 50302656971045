import { call, takeLatest, put } from '@redux-saga/core/effects';
import { actions } from './slice';
import { actions as adminActions } from '../AdminSlice/slice';
import {
  createPutSiteOnTest,
  deleteSiteOnTest,
  fetchCallContactList,
  fetchSecurityManagerSiteInfo,
  updateCallList,
  postAddContact,
  deleteContact,
  editContact,
  getContactDetails,
  sendPasscodeEmail,
  getCallContactList,
  getAdminSecurityConfig,
  updateAdminSecurityConfig,
  updateAdminSiteSecurityConfig,
  getCompanyProfile,
  getSMActivityLog,
  getSMActivityLogTypes,
  getSecurityManagerUser,
  editSecurityManagerUser,
  getSecurityManagerZoneList,
  getSecurityManagerUserAlarmList,
  getSecurityManagerUserSiteGroupList,
  getSelfTestReport,
  postSelfTestSensor,
  getSelfTestZone,
  getSelfTestStatus,
  patchSelfTestSensor,
  getUserAffectedSiteList,
  deleteGlobalUser,
} from './api';
import { ResponseProps, zoneResponse } from './types';
import { CONTEXT, ERROR_TEXT } from 'utils/Constants/Content';
import { FILE_EXPORT_DATE_FORMAT, FILE_EXPORT_NAME } from 'utils/Constants';
import moment from 'moment-timezone';
import { isEmpty } from 'utils/CommonFn/validators';

function* fetchSecuritySiteInfo(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(fetchSecurityManagerSiteInfo, payload);
      if (response?.data) {
        yield put(
          actions.updateSecuritySiteInfo({
            loading: false,
            data: response.data,
            status: CONTEXT.FULFILLED,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateSecuritySiteInfo({
        loading: false,
        data: [],
        status: CONTEXT.ERROR,
      }),
    );
  }
}

function* postPutSiteOnTest(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(createPutSiteOnTest, payload);
      if (response?.data) {
        yield put(
          actions.updatePutSiteOnTest({
            loading: false,
            status: 'fulfilled',
            data: response.data,
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: response?.data?.message,
            toastType: 'success',
          }),
        );
      } else {
        yield put(
          actions.updatePutSiteOnTest({
            loading: false,
            data: [],
            status: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updatePutSiteOnTest({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
    yield put(
      adminActions.displayToast({
        toastMessage: 'Something went wrong!',
        toastType: 'error',
      }),
    );
  }
}
function* deleteSiteTest(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deleteSiteOnTest, payload);
      if (response?.data) {
        yield put(
          actions.updateDeleteSiteTest({
            loading: false,
            status: 'fulfilled',
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: response?.data,
            toastType: 'success',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateDeleteSiteTest({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
    yield put(
      adminActions.displayToast({
        toastMessage: 'Something went wrong!',
        toastType: 'error',
      }),
    );
  }
}

function* fetchSecurityCallContactList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(fetchCallContactList, payload);
      if (response?.data) {
        yield put(
          actions.updateSecurityManagerCallContactList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateSecurityManagerCallContactList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* PutSecurityCallList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateCallList, payload);
      if (response?.data) {
        yield put(
          actions.updatePutSecurityManagerCallList({
            loading: false,
            status: 'fulfilled',
            data: response.data,
          }),
        );
        /* yield put(
          adminActions.displayToast({
            toastMessage: response?.data,
            toastType: 'success',
          }),
        ); */
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updatePutSecurityManagerCallList({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
    yield put(
      adminActions.displayToast({
        toastMessage: 'Something went wrong!',
        toastType: 'error',
      }),
    );
  }
}
function* fetchContactDetails(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    response = yield call(getContactDetails, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateContactDetails({
          loading: false,
          data: response.data,
          status: 'success',
        }),
      );
      if (!isEmpty(response.data?.warningMessage)) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data?.warningMessage,
            toastType: 'warning',
          }),
        );
      }
    } else {
      console.log('=== API Failed to respond ===');
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error ?? error?.error;
    yield put(
      adminActions.displayToast({
        toastMessage: errorMessage || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    console.log(error);
    yield put(
      actions.updateContactDetails({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}
function* fetchAddContact(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  const siteId = payload.siteId;
  delete payload.siteId;
  try {
    if (payload) {
      const callback = payload.addContatcallback;
      delete payload.addContatcallback;
      response = yield call(postAddContact, payload, siteId);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateCreatedContact({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        if (callback) {
          callback(response);
        }
      } else {
        yield put(
          actions.updateCreatedContact({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error ?? error?.error;
    yield put(
      adminActions.displayToast({
        toastMessage: errorMessage || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateCreatedContact({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchDeleteContact(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(deleteContact, payload.serialNo);
      if (response && response.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateDeleteContact({
            loading: false,
            data: [],
            status: 'fulfilled',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateDeleteContact({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchEditContact(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      const callback = payload.addContatcallback;
      response = yield call(editContact, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateSecurityManagerUserList({
            loading: true,
            data: [],
            recordCount: 0,
          }),
        );
        yield put(
          actions.updateEditContact({
            loading: false,
            data: [],
          }),
        );
        if (callback) {
          callback(response);
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    const errorMessage = error?.response?.data?.error ?? error?.error;
    yield put(
      adminActions.displayToast({
        toastMessage: errorMessage || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateEditContact({
        loading: false,
        data: [],
      }),
    );
  }
}

function* processPasscodeEmail(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(sendPasscodeEmail, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updatePasscodeEmail({
            loading: false,
            status: CONTEXT.FULFILLED,
            data: [],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updatePasscodeEmail({
        loading: false,
        status: CONTEXT.ERROR,
        data: [],
      }),
    );
  }
}
function* fetchContactList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getCallContactList, payload);
      if (response?.data) {
        yield put(
          actions.updateCallContactList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateCallContactList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSecurityManagerAdminConfig(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getAdminSecurityConfig, payload);
      if (response?.data) {
        yield put(
          actions.updateSecurityManagerConfig({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateSecurityManagerConfig({
        loading: false,
        data: [],
      }),
    );
  }
}
function* putSecurityManagerAdminConfig(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateAdminSecurityConfig, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data?.response,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateAdminSecurityManagerConfig({
            loading: false,
            data: response.data,
            status: 'fulfilled',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateAdminSecurityManagerConfig({
        loading: false,
        data: [],
      }),
    );
  }
}
function* putSiteSecurityManagerAdminConfig(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateAdminSiteSecurityConfig, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data?.response,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateSiteAdminSecurityManagerConfig({
            loading: false,
            data: response.data,
            status: 'fulfilled',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSiteAdminSecurityManagerConfig({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}
function* fetchCompanyProfile(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getCompanyProfile, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.updateCompanyProfile({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateCompanyProfile({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSecurityManagerActivityLogTypes(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getSMActivityLogTypes, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.updateSecurityManagerActivityLogTypes({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    yield put(
      actions.updateSecurityManagerActivityLogTypes({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSecurityManagerActivityLog(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getSMActivityLog, payload);
      if (response?.data?.data) {
        yield put(
          actions.updateSecurityManagerActivityLog({
            loading: false,
            data: response.data.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSecurityManagerActivityLog({
        loading: false,
        data: [],
      }),
    );
  }
}
/* Common function for export excel */
function exportUtils(response: any, fileName: any) {
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(new Blob([response.data]), `${fileName}.xlsx`);
  } else {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
}
function exportSelfTest(response: any, fileName: any) {
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(new Blob([response]), `${fileName}.xlsx`);
  } else {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
}

function* postExportActivityLogReport(request: any) {
  let response: { headers: Record<string, string>; data: any };
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getSMActivityLog, payload);
      if (
        response.headers['content-type'] ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${FILE_EXPORT_NAME.ACTIVITY_LOG}_${moment().format(
          FILE_EXPORT_DATE_FORMAT,
        )}`;
        exportUtils(response, fileName);
        yield put(
          actions.updateExportSecurityManagerActivityLog({
            loading: false,
          }),
        );
        yield put(
          adminActions.displayToast({
            toastMessage: 'Download Successful.',
            toastType: 'success',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateExportSecurityManagerActivityLog({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchSecurityManagerUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(getSecurityManagerUser, payload);
      if (response?.data) {
        yield put(
          actions.updateSecurityManagerUserList({
            loading: false,
            data: response?.data?.securityManagerUserSearchData,
            recordCount: response?.data?.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.updateSecurityManagerUserList({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* updateSecurityManagerUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(editSecurityManagerUser, payload);
      if (response?.data) {
        yield put(
          actions.updatePutSecurityManageruser({
            loading: false,
            data: response.data,
            status: 'fulfilled',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updatePutSecurityManageruser({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}

function* deleteSecurityManagerUsers(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  const serialNo = payload.serialNo;
  delete payload.serialNo;
  try {
    if (payload) {
      response = yield call(deleteContact, serialNo, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateDeleteSecurityManagerUser({
            loading: false,
            data: response.data,
            status: 'fulfilled',
          }),
        );
        yield put(
          actions.updateSecurityManagerUserList({
            loading: true,
            data: [],
            recordCount: 0,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateDeleteSecurityManagerUser({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}
function* fetchSecurityManagerZoneList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(getSecurityManagerZoneList, payload);
      if (response?.data) {
        yield put(
          actions.updateSecurityManagerZoneList({
            loading: false,
            data: response?.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSecurityManagerZoneList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSecurityManagerAlarmList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(getSecurityManagerUserAlarmList, payload);
      if (response?.data) {
        yield put(
          actions.updateSecurityManagerAlarmList({
            loading: false,
            data: response?.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSecurityManagerAlarmList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSecurityManagerSiteGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSecurityManagerUserSiteGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSMUserSiteGroupList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSMUserSiteGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGetSelfTestReport(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(getSelfTestReport, payload);
      if (response?.data) {
        yield put(
          actions.updateGetSelfTestReport({
            loading: false,
            data: response?.data,
            recordCount: response?.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateGetSelfTestReport({
            loading: false,
            data: [],
            recordCount: 0,
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateGetSelfTestReport({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchSelfTestDetailReport(request: any) {
  let response: {
    type: string;
    headers: Record<string, string>;
    data: zoneResponse;
  };
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(getSelfTestReport, payload);
      if (
        response.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const fileName = `${
          FILE_EXPORT_NAME.SM_SELF_TEST_HISTORY
        }_${moment().format(FILE_EXPORT_DATE_FORMAT)}`;
        exportSelfTest(response, fileName);
        yield put(
          adminActions.displayToast({
            toastMessage: 'Download Successful.',
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateSelfTestDetailReport({
            loading: false,
          }),
        );
      } else {
        if (response.data) {
          yield put(
            actions.updateSelfTestDetailReport({
              loading: false,
              data: response.data,
            }),
          );
        } else {
          console.log('=== API Failed to respond ===');
          yield put(
            actions.updateSelfTestDetailReport({
              loading: false,
              data: [],
            }),
          );
        }
      }
    } else {
      yield put(
        actions.updateSelfTestDetailReport({
          loading: false,
          data: [],
        }),
      );
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSelfTestDetailReport({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGetSelfTestStatus(request: any) {
  let response: zoneResponse;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getSelfTestStatus, payload);
      if (Object.keys(response.data).length > 0) {
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: response?.data,
            status: '',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: [],
            status: '',
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateGetSelfTestReport({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGetSelfTestSubmit(request: any) {
  let response: zoneResponse;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getSelfTestStatus, payload);
      if (Object.keys(response.data).length > 0) {
        yield put(
          actions.updateSelfTestProps({
            testProgress: 'completed',
            ...response.data,
          }),
        );
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: response?.data,
          }),
        );
      } else {
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: [],
            status: '',
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateGetSelfTestReport({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGetSelfTestExtend(request: any) {
  let response: zoneResponse;
  const { payload } = request;
  try {
    if (payload) {
      response = yield call(getSelfTestStatus, payload);
      if (Object.keys(response.data).length > 0) {
        yield put(
          actions.updateSelfTestProps({
            ...response.data,
          }),
        );
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: response?.data,
          }),
        );
      } else {
        yield put(
          actions.updateGetSelfTestStatus({
            loading: false,
            data: [],
            status: '',
          }),
        );
      }
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateGetSelfTestReport({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchPostSelfTestSensors(request: any) {
  const { siteId }: any = request.payload;
  try {
    if (request.payload) {
      const sensorResult: ResponseProps = yield call(
        postSelfTestSensor,
        request.payload,
      );
      if (Object.keys(sensorResult.data).length > 0) {
        const reportId = sensorResult.data?.reportId;
        const resStatus: ResponseProps = yield call(getSelfTestStatus, {
          siteId,
          reportId,
        });
        if (Object.keys(resStatus.data).length > 0) {
          yield put(
            actions.updateSelfTestProps({
              ...resStatus.data,
              ...sensorResult.data,
            }),
          );
          yield put(
            actions.updatePostSelfTestSensors({
              loading: false,
              data: {
                ...sensorResult.data,
                ...resStatus.data,
                isSystemOnTest: resStatus.data?.isSystemOnTest,
              },
            }),
          );
          yield put(
            actions.updateGetSelfTestStatus({
              loading: false,
              data: resStatus.data,
            }),
          );
        }
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updatePostSelfTestSensors({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
      yield put(
        actions.updatePostSelfTestSensors({
          loading: false,
          data: [],
        }),
      );
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updatePostSelfTestSensors({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchPatchSelfTestSensors(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(patchSelfTestSensor, payload);
      if (response?.data) {
        yield put(
          actions.updatePatchSelfTestSensors({
            loading: false,
            data: response?.data,
          }),
        );
        yield put(
          actions.updateSelfTestProps({
            modifiedZones: false,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updatePatchSelfTestSensors({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
      yield put(
        actions.updatePatchSelfTestSensors({
          loading: false,
          data: [],
        }),
      );
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updatePatchSelfTestSensors({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchSelfTestZoneList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(getSelfTestZone, payload);
      if (response?.data) {
        yield put(
          actions.updateSelfTestZones({
            loading: false,
            data: response?.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateSelfTestZones({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
      yield put(
        actions.updateSelfTestZones({
          loading: false,
          data: [],
        }),
      );
    }
  } catch (error: any) {
    yield put(
      adminActions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateSelfTestZones({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchGlobalUserAffectedSites(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserAffectedSiteList, payload);
      if (response?.data?.associatedSites) {
        yield put(
          actions.updateGlobalUserAffectedSite({
            loading: false,
            data: response.data.associatedSites,
          }),
        );
      } else {
        yield put(
          actions.updateGlobalUserAffectedSite({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateGlobalUserAffectedSite({
        loading: false,
        data: [],
      }),
    );
  }
}
function* deleteSelectedGlobalUser(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(deleteGlobalUser, payload);
      if (response?.data) {
        yield put(
          adminActions.displayToast({
            toastMessage: response?.data,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateDeleteGlobalUser({
            loading: false,
            data: [],
            status: CONTEXT.FULFILLED,
          }),
        );
      } else {
        yield put(
          actions.updateDeleteGlobalUser({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateDeleteGlobalUser({
        loading: false,
        data: [],
      }),
    );
  }
}
export function* securityManagerSaga() {
  yield takeLatest(actions.putSiteOnTest, postPutSiteOnTest);
  yield takeLatest(actions.fetchSecuritySiteInfo, fetchSecuritySiteInfo);
  yield takeLatest(actions.deleteSiteTest, deleteSiteTest);
  yield takeLatest(
    actions.fetchSecurityManagerCallContactList,
    fetchSecurityCallContactList,
  );
  yield takeLatest(actions.fetchContactDetails, fetchContactDetails);
  yield takeLatest(actions.putSecurityManagerCallList, PutSecurityCallList);
  yield takeLatest(actions.fetchAddContact, fetchAddContact);
  yield takeLatest(actions.fetchDeleteContact, fetchDeleteContact);
  yield takeLatest(actions.fetchEditContact, fetchEditContact);
  yield takeLatest(actions.sendPasscodeEmail, processPasscodeEmail);
  yield takeLatest(actions.fetchCallContactList, fetchContactList);
  yield takeLatest(
    actions.fetchSecurityManagerConfig,
    fetchSecurityManagerAdminConfig,
  );
  yield takeLatest(
    actions.putAdminSecurityManagerConfig,
    putSecurityManagerAdminConfig,
  );
  yield takeLatest(
    actions.putSiteAdminSecurityManagerConfig,
    putSiteSecurityManagerAdminConfig,
  );
  yield takeLatest(actions.fetchCompanyProfile, fetchCompanyProfile);
  yield takeLatest(
    actions.fetchSecurityManagerActivityLogTypes,
    fetchSecurityManagerActivityLogTypes,
  );
  yield takeLatest(
    actions.fetchSecurityManagerActivityLog,
    fetchSecurityManagerActivityLog,
  );
  yield takeLatest(
    actions.exportSecurityManagerActivityLog,
    postExportActivityLogReport,
  );
  yield takeLatest(actions.fetchSecurityManagerUser, fetchSecurityManagerUser);
  yield takeLatest(actions.putSecurityManagerUser, updateSecurityManagerUser);
  yield takeLatest(
    actions.deleteSecurityManagerUser,
    deleteSecurityManagerUsers,
  );
  yield takeLatest(
    actions.fetchSecurityManagerZoneList,
    fetchSecurityManagerZoneList,
  );
  yield takeLatest(
    actions.fetchSecurityManagerAlarmList,
    fetchSecurityManagerAlarmList,
  );
  yield takeLatest(
    actions.fetchSMUserSiteGroupList,
    fetchSecurityManagerSiteGroupList,
  );
  yield takeLatest(actions.fetchSelfTestZoneList, fetchSelfTestZoneList);
  yield takeLatest(actions.fetchGetSelfTestReport, fetchGetSelfTestReport);
  yield takeLatest(
    actions.fetchSelfTestDetailReport,
    fetchSelfTestDetailReport,
  );
  yield takeLatest(actions.fetchGetSelfTestStatus, fetchGetSelfTestStatus);
  yield takeLatest(actions.fetchGetSelfTestExtend, fetchGetSelfTestExtend);
  yield takeLatest(actions.fetchGetSelfTestSubmit, fetchGetSelfTestSubmit);
  yield takeLatest(actions.fetchPostSelfTestSensors, fetchPostSelfTestSensors);
  yield takeLatest(
    actions.fetchPatchSelfTestSensors,
    fetchPatchSelfTestSensors,
  );
  yield takeLatest(
    actions.fetchGlobalUserAffectedSite,
    fetchGlobalUserAffectedSites,
  );
  yield takeLatest(actions.fetchDeleteGlobalUser, deleteSelectedGlobalUser);
}
