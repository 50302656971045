import { call, takeLatest, put, delay, select } from '@redux-saga/core/effects';
import { actions } from './slice';
import { actions as globalAction } from 'app/features/GlobalSlice/slice';
import { actions as dashboardAction } from 'app/features/DashboardSlice/slice';
import {
  getPermissionGroupList,
  getPermissionList,
  postCreatePermission,
  postCreatePermissionGroup,
  updatePermission,
  deletePermission,
  updatePermissionGroup,
  deletePermissionGroup,
  getPermissionGroupCustomersList,
  postUserList,
  suspendUser,
  getUserDetails,
  postCreateUser,
  updateUser,
  deleteUser,
  postUserGroupList,
  postAssociateSite,
  getUserSiteList,
  getUserCompanyList,
  getUserPermissionGroupList,
  postPermissionGroup,
  getUserPermissionsList,
  getUserPermissionsGroupList,
  getUserPermissionsGroupDetail,
  postNewPermissionGroup,
  deletePermissionsGroup,
  getSearchPermission,
  getSearchPermissionGroup,
  getSearchPermissionUser,
  putEditPermissionGroup,
  postPermissionGroupToUser,
  getUserGroupDetail,
  deletePermissionUser,
  checkUserPermissionedGroupStatus,
  putPermissionGroupName,
  resetPassword,
  getSitesList,
  getSiteRemark,
  updateSiteVisibility,
  getAssociateSiteGroup,
  updateCompanySites,
  postUserSiteGroupList,
  getSitesDetails,
  getCompanyAccountType,
  getProfilePartyNumbers,
  getMasterCompanyList,
  updateCompanyProfile,
  createCompanyProfile,
  getCSSiteGroupList,
  getAssociateSiteGroupAllSitesList,
  updateCSSiteGroup,
  postCSSiteGroup,
  getNotificationList,
  deleteNotification,
  deleteSiteGroup,
  getNotificationCompanyList,
  getNotificationDetail,
  postAddNotification,
  patchUpdateNotification,
  getNotificationSiteGroupList,
  getNotificationSiteList,
  getChronicList,
  getChronicProfileData,
  getChronicDefinitionList,
  postChronicProfileRequest,
  deleteChronicProfileData,
  putChronicProfileRequest,
  postBulkSiteGroup,
  GETBulkSiteGroupStatus,
  GetSiteGroupBulkUploadList,
  postBulkUser,
  getImpersonateUserDetails,
  closeImpersonateUserSession,
  getUserValidation,
  getSiteContactDetails,
  updateSiteContactDetails,
  getCompanySiteContactDetails,
  updateCompanySiteContactDetails,
  postSMBulkUser,
} from './api';
import { ResponseProps } from './types';
import { fetchMasterCompanyId, setupIMUser } from 'app/features/authService';
import { CONTEXT, ERROR_TEXT, MESSAGES } from 'utils/Constants/Content';
import { isEmpty } from 'utils/CommonFn/validators';
import { getTaskStatus } from './Selectors';

// permission
function* fetchPermissionList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getPermissionList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updatePermissonList({
            loading: false,
            data: response.data,
            recordCount: response.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePermissonList({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* postCreateNewPermission(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postCreatePermission, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateCreatedPermission({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCreatedPermission({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postUpdatePermission(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updatePermission, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updatePermissions({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePermissions({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postDeletePermission(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deletePermission, payload);
      if (response && response.data === 'Permission deleted successfully') {
        yield put(
          actions.updateDeletedPermissions({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.fetchPermissionList({
            limit: 10,
            page: 1,
            recordCountRequire: true,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateDeletedPermissions({
        loading: false,
        data: [],
      }),
    );
  }
}
// permission Group

function* fetchPermissionGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getPermissionGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updatePermissonGroupList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePermissonGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postCreateNewPermissionGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postCreatePermissionGroup, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateCreatedPermissionGroup({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.fetchPermissionList({
            page: 1,
          }),
        );
        yield put(
          actions.fetchPermissionGroupList({
            page: 1,
          }),
        );
        yield put(
          actions.fetchPermissionGroupCustomerList({
            companyId: fetchMasterCompanyId(),
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCreatedPermissionGroup({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postUpdatePermissionGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updatePermissionGroup, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updatedPermissionGroup({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatedPermissionGroup({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postDeletePermissionGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deletePermissionGroup, payload);
      if (
        response &&
        response.data === 'Permission group deleted successfully'
      ) {
        yield put(
          actions.updateDeletedPermissionGroup({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.fetchPermissionGroupList({
            page: 1,
            recordCountRequire: true,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateDeletedPermissionGroup({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchPermissionGroupCustomerList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getPermissionGroupCustomersList, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updatePermissionGroupCustomerList({
            loading: false,
            data: response.data.userList,
          }),
        );
      } else {
        yield put(
          actions.updatePermissionGroupCustomerList({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePermissionGroupCustomerList({
        loading: false,
        data: [],
      }),
    );
  }
}

// user list
function* fetchPostCreateUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(postCreateUser, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateCreatedUser({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: 'user created successfully',
            toastType: 'success',
          }),
        );
        if (callback) {
          callback(response);
        }
      } else {
        yield put(
          actions.updateCreatedUser({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateCreatedUser({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUsersList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(postUserList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateUsersList({
            loading: false,
            data: response.data,
            recordCount: response.recordCount,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUsersList({
        loading: false,
        data: [],
        recordCount: 0,
      }),
    );
  }
}
function* fetchUserValidation(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    response = yield call(getUserValidation, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateUserValidation({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserValidation({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserDetail(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    response = yield call(getUserDetails, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateUserDetails({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserDetails({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchPostUpdateUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;

      response = yield call(updateUser, payload);
      if (response && response.data) {
        yield put(
          actions.updateUser({
            loading: false,
            data: response.data,
          }),
        );
        if (callback) {
          callback(response);
        }
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    console.log(error);
    yield put(
      actions.updateUser({
        loading: false,
        data: [],
      }),
    );
  }
}
function* putSuspendUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  const pageno = payload.page;
  const searchUser = payload.searchTerm;
  const allUsersSelected = payload.getAllUsers;
  delete payload.searchTerm;
  delete payload.page;
  try {
    if (payload) {
      response = yield call(suspendUser, payload);
      if (
        response &&
        (response.data === 'User is activated' ||
          response.data === 'User is deactivated')
      ) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );

        yield put(
          actions.fetchUsersList({
            searchTerm: searchUser || '',
            limit: 10,
            page: pageno,
            recordCountRequire: true,
            getAllUsers: allUsersSelected,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* postDeleteUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deleteUser, payload);
      if (response && response.data === 'user deleted successfully') {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );

        yield put(
          actions.updateDeleteUser({
            limit: 10,
            page: 1,
            recordCountRequire: true,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateDeleteUser({
        loading: false,
        data: [],
      }),
    );
  }
}
function* putChangePassword(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(resetPassword, payload);
      if (response && response.data.length > 0) {
        if (response.data[0].message === 'success') {
          yield put(
            actions.displayToast({
              toastMessage: 'password change successfully',
              toastType: 'success',
            }),
          );
          yield put(
            actions.updateChangeUserPassword({
              loading: false,
              data: [],
            }),
          );
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updateChangeUserPassword({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* postAssociateSites(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postAssociateSite, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateAssociateSites({
            loading: false,
            data: response.data?.sites,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateAssociateSites({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postUsersGroupList(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postUserGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateUserGroupList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserSiteList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserSiteList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateUserSiteList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserSiteList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserCompanyList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserCompanyList, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateUserCompanyList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserCompanyList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserPermissionGroup(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserPermissionGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateUserPermissionList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserPermissionList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postPermissionGroupChild(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postPermissionGroup, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updatePermissionGroupChild({
            loading: false,
            data: response.data?.sites,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updatePermissionGroupChild({
        loading: false,
        data: [],
      }),
    );
  }
}

// permission managment

function* fetchUserPermissionList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserPermissionsList, payload);
      if (response && Array.isArray(response.response)) {
        yield put(
          actions.updateUserPermissonList({
            loading: false,
            data: response.response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserPermissonList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchUserPermissionsGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserPermissionsGroupList, payload);
      if (response && Array.isArray(response.response)) {
        yield put(
          actions.updateUserPermissonGroupList({
            loading: false,
            data: response.response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserPermissonGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchUserPermissionsGroupDetails(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserPermissionsGroupDetail, payload);
      if (response) {
        yield put(
          actions.updateUserPermissonGroupDetails({
            loading: false,
            data: response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateUserPermissonGroupDetails({
        loading: false,
        data: [],
      }),
    );
  }
}

function* postCreateNewGroup(request: any) {
  let response: ResponseProps;
  const { payload } = request;

  try {
    if (payload) {
      response = yield call(postNewPermissionGroup, payload);
      if (response && Array.isArray(response.response)) {
        yield put(
          actions.updateUserPermissonGroupList({
            loading: false,
            data: response.response,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: 'Group successfully created',
            toastType: 'success',
          }),
        );
        yield put(actions.fetchSearchPermissionsGroup({}));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    // console.log(error);
    yield put(
      actions.updateUserPermissonGroupList({
        loading: false,
        data: [],
      }),
    );
    if (error?.response?.data?.message) {
      yield put(
        actions.displayToast({
          toastMessage: error.response.data.message,
          toastType: 'error',
        }),
      );
    }
  }
}

function* permissionGroupDelete(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deletePermissionsGroup, payload);
      if (response && response.message) {
        yield put(
          actions.displayToast({
            toastMessage: response.message,
            toastType: 'success',
          }),
        );
        // fetch selected group data
        if (payload.groupId) {
          yield put(
            actions.fetchUserPermissionsGroupDetails({
              groupId: payload.groupId,
            }),
          );
        } else if (payload.userId) {
          yield put(
            actions.fetchUserPermissionsDetails({ userId: payload.userId }),
          );
        } else if (payload.reloadGroups) {
          yield put(actions.fetchSearchPermissionsGroup({}));
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* fetchSearchPermission(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSearchPermission, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSearchPermisson({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSearchPermisson({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSearchPermissionGroup(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSearchPermissionGroup, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSearchPermissonGroup({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSearchPermissonGroup({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchSearchPermissionUser(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSearchPermissionUser, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSearchPermissionUser({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSearchPermissionUser({
        loading: false,
        data: [],
      }),
    );
  }
}

function* putPermissionGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(putEditPermissionGroup, payload);
      if (response?.permissionGroup?.PermissionGroupId) {
        yield put(
          actions.displayToast({
            toastMessage: 'Permission assigned successfully!',
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateUserPermissonGroupDetails({
            loading: false,
            data: response.permissionGroup,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    if (error?.response?.data?.errorCode === 'ALREADY_EXIST') {
      yield put(
        actions.displayToast({
          toastMessage: error.response.data.error,
          toastType: 'error',
        }),
      );
    } else {
      yield put(
        actions.displayToast({
          toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
          toastType: 'error',
        }),
      );
    }
  }
}

function* postPermissionsGroupToUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postPermissionGroupToUser, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: 'Permission group assigned successfully!',
            toastType: 'success',
          }),
        );
        yield put(
          actions.fetchUserPermissionsDetails({ userId: payload.userId }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    if (error?.response?.data?.errorCode === 'ALREADY_EXIST') {
      yield put(
        actions.displayToast({
          toastMessage: error.response.data.error,
          toastType: 'error',
        }),
      );
    } else {
      yield put(
        actions.displayToast({
          toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
          toastType: 'error',
        }),
      );
    }
  }
}

function* fetchUserPermissionsGroupDetail(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getUserGroupDetail, payload);
      if (response) {
        yield put(
          actions.updateUserPermissionsDetails({
            loading: false,
            data: response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* userPermissionDelete(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(deletePermissionUser, payload);
      if (response && response.message) {
        yield put(
          actions.displayToast({
            toastMessage: response.message,
            toastType: 'success',
          }),
        );
        // fetch selected group data
        if (payload.groupId) {
          yield put(
            actions.fetchUserPermissionsGroupDetails({
              groupId: payload.groupId,
            }),
          );
        }
        if (payload.userId) {
          yield put(
            actions.fetchUserPermissionsDetails({ userId: payload.userId }),
          );
        }
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* checkUserPermissionGroupStatus(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(checkUserPermissionedGroupStatus, payload);
      if (response) {
        yield put(
          actions.updateUserPermissionedGroupStatus({
            loading: false,
            data: response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* putUserPermissionGroupName(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(putPermissionGroupName, payload);
      if (response && response.message) {
        yield put(
          actions.displayToast({
            toastMessage: response.message,
            toastType: 'success',
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* fetchUserSiteGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(postUserSiteGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSiteGroups({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSiteGroups({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchSitesList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSitesList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSitesList({
            loading: false,
            data: response,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSitesList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchSiteRemarks(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSiteRemark, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSiteRemark({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSitesList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* putSiteVisibility(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateSiteVisibility, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: 'Site status changed successfully!',
            toastType: 'success',
          }),
        );
        yield put(actions.fetchSitesList(payload.config));
        yield put(
          globalAction.fetchCompanySiteList({
            companyId: fetchMasterCompanyId(),
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* fetchAssociateSiteList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getAssociateSiteGroup, payload);
      if (response && response.data) {
        yield put(
          actions.updateAssociateSitesList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* fetchSiteContactDetails(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    const contactType = payload?.type ?? '';
    if (payload) {
      response = yield call(getSiteContactDetails, payload);
      if (response && response.data) {
        yield put(
          actions.updateSiteContactDetails({
            loading: false,
            data: isEmpty(contactType) ? response.data : [response.data],
          }),
        );
      } else {
        yield put(
          actions.updateSiteContactDetails({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updateSiteContactDetails({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* patchSiteContactDetails(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const siteId = payload.siteId;
      response = yield call(updateSiteContactDetails, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        if (siteId) {
          yield put(actions.fetchSiteContactDetails({ siteId }));
        }
        yield put(
          actions.updatePatchSiteContactDetails({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updatePatchSiteContactDetails({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* fetchCompanySiteContactDetails(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getCompanySiteContactDetails, payload);
      if (response && response.data.length > 0) {
        yield put(
          actions.updateCompanySiteContactDetails({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateCompanySiteContactDetails({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updateCompanySiteContactDetails({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* patchCompanySiteContactDetails(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const companyId = payload.companyId;
      response = yield call(updateCompanySiteContactDetails, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        if (companyId) {
          yield put(actions.fetchCompanySiteContactDetails({ companyId }));
        }
        yield put(
          actions.updatepatchCompanySiteContactDetails({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.updatepatchCompanySiteContactDetails({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* patchCompanySites(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateCompanySites, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(actions.updatePatchCompanySites({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updatePatchCompanySites({ status: 'error' }));
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* fetchCompanySiteDetails(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getSitesDetails, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateSiteDetails({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateSiteDetails({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchCompanyAccountType(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getCompanyAccountType, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateCompanyAccountType({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCompanyAccountType({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchProfilePartyNumber(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getProfilePartyNumbers, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateProfilePartyNumber({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateProfilePartyNumber({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchMasterCompanyList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getMasterCompanyList, payload);
      if (response?.data?.companyData?.length > 0) {
        yield put(
          actions.updateMasterCompanyList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
        yield put(
          actions.updateMasterCompanyList({
            loading: false,
            data: [],
          }),
        );
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateMasterCompanyList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* createCompanyMasterProfile(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(createCompanyProfile, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: 'Company created successfully.',
            toastType: 'success',
          }),
        );
        yield put(actions.updateCreateCompanyProfile({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updateCreateCompanyProfile({ status: 'error' }));
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* patchCompanyMasterProfile(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateCompanyProfile, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(actions.updatePatchCompanyProfile({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updatePatchCompanyProfile({ status: 'error' }));
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* fetchCSSiteGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getCSSiteGroupList, payload);
      if (response && Array.isArray(response.data)) {
        yield put(
          actions.updateCSSiteGroupList({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateCSSiteGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchAssociateSiteGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getAssociateSiteGroupAllSitesList, payload);
      if (response && response.data) {
        yield put(
          actions.updateAssociateSiteGroupList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* patchCSSiteGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(updateCSSiteGroup, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: response.data,
            toastType: 'success',
          }),
        );
        yield put(actions.updatePatchCSSiteGroup({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(actions.updatePatchCSSiteGroup({ status: 'error' }));
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* postCompanySiteSiteGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      response = yield call(postCSSiteGroup, payload);
      if (response?.data?.length > 0) {
        yield put(
          actions.displayToast({
            toastMessage: 'Site group created successfully',
            toastType: 'success',
          }),
        );
        yield put(actions.updatePatchCSSiteGroup({ status: 'fulfilled' }));
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error.response.data.error);
    yield put(actions.updatePatchCSSiteGroup({ status: 'error' }));
    yield put(
      actions.displayToast({
        toastMessage: error?.response?.data?.error
          ? error.response.data.error
          : ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* fetchNotificationList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getNotificationList, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateNotificationList({
            loading: false,
            data: response.data.companyNotificationData,
            recordCount: response.data.recordCount,
          }),
        );
      } else {
        yield put(
          actions.updateNotificationList({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNotificationCompanyList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getNotificationCompanyList, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateNotificationCompanyList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateNotificationCompanyList({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationCompanyList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNotificationDetails(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getNotificationDetail, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updateNotificationDetails({
            loading: false,
            data: response.data.singleNotification,
          }),
        );
      } else {
        yield put(
          actions.updateNotificationDetails({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationDetails({
        loading: false,
        data: [],
      }),
    );
  }
}
function* postNotification(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(postAddNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updatePostNotification({
            loading: false,
            data: [],
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: response.data.message,
            toastType: 'success',
          }),
        );
        if (callback) {
          callback(response.data);
        }
      } else {
        yield put(
          actions.updatePostNotification({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      yield put(
        actions.updatePostNotification({
          loading: false,
          data: [],
        }),
      );
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.updatePostNotification({
        loading: false,
        data: [],
      }),
    );
    yield put(
      actions.displayToast({
        toastMessage: error?.response?.data?.error
          ? error.response.data.error
          : ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}
function* patchNotification(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;

  try {
    if (payload) {
      const callback = payload.callback;
      delete payload.callback;
      response = yield call(patchUpdateNotification, payload);
      if (response && Object.keys(response.data).length > 0) {
        yield put(
          actions.updatePatchNotification({
            data: [],
            loading: false,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: response.data.message,
            toastType: 'success',
          }),
        );
        if (callback) {
          callback(response.data);
        }
      } else {
        yield put(
          actions.updatePatchNotification({
            data: [],
            loading: false,
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.updatePatchNotification({
        data: [],
        loading: false,
      }),
    );
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error?.response?.data?.error
          ? error.response.data.error
          : ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
  }
}

function* deleteNewNotification(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      const pageno = payload.page;
      delete payload.page;

      response = yield call(deleteNotification, payload);
      if (response && response.data) {
        yield put(
          actions.displayToast({
            toastMessage: response.data.message,
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateNotificationList({
            loading: false,
          }),
        );
        yield put(
          actions.fetchNotificationList({
            limit: 10,
            page: pageno,
            recordCountRequire: true,
            // notificationType: CONTEXT.GENERAL,
          }),
        );
      } else {
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* deleteCSSiteGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  const pageno = payload.page;
  delete payload.page;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(deleteSiteGroup, payload);
      if (response?.data) {
        yield put(
          actions.displayToast({
            toastMessage: 'Site group deleted successfully',
            toastType: 'success',
          }),
        );
        yield put(
          actions.updateCSSiteGroupList({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.fetchCSSiteGroupList({
            limit: 10,
            page: pageno,
            recordCountRequire: true,
          }),
        );
      } else {
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    console.log(error);
    yield put(
      actions.updateCSSiteGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNotificationSiteGroupList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    // if (payload) {
    response = yield call(getNotificationSiteGroupList, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateNotificationSiteGroupList({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      yield put(
        actions.updateNotificationSiteGroupList({
          loading: false,
          data: [],
        }),
      );
      console.log('=== API Failed to respond ===');
    }
    // } else {
    //   console.log("=== Missing Payload ===");
    // }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationSiteGroupList({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchNotificationSiteList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    // if (payload) {
    response = yield call(getNotificationSiteList, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateNotificationSiteList({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      yield put(
        actions.updateNotificationSiteList({
          loading: false,
          data: [],
        }),
      );
      console.log('=== API Failed to respond ===');
    }
    // } else {
    //   console.log("=== Missing Payload ===");
    // }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateNotificationSiteList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchChronicProfileList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    response = yield call(getChronicList, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateChronicProfileList({
          loading: false,
          data: response,
        }),
      );
    } else {
      yield put(
        actions.updateChronicProfileList({
          loading: false,
          data: [],
        }),
      );
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateChronicProfileList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchChronicProfileData(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    response = yield call(getChronicProfileData, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateChronicProfileData({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      yield put(
        actions.updateChronicProfileData({
          loading: false,
          data: [],
        }),
      );
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateChronicProfileData({
        loading: false,
        data: [],
      }),
    );
  }
}

function* postChronicRequest(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload.chronicProfileId) {
      response = yield call(putChronicProfileRequest, payload);
    } else {
      response = yield call(postChronicProfileRequest, payload);
    }
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateChronicProfileRequest({
          loading: false,
          data: response.data,
          status: 'fulfilled',
        }),
      );
      yield put(
        actions.displayToast({
          toastMessage: payload.chronicProfileId
            ? MESSAGES.CHRONIC_UPDATE
            : MESSAGES.CHRONIC_ADD,
          toastType: 'success',
        }),
      );
    } else {
      yield put(
        actions.updateChronicProfileRequest({
          loading: false,
          data: [],
          status: 'fulfilled',
        }),
      );
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: 'Something went wrong.',
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateChronicProfileRequest({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}

function* fetchChronicDefinition(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    response = yield call(getChronicDefinitionList, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.updateChronicDefinationData({
          loading: false,
          data: response.data,
        }),
      );
    } else {
      yield put(
        actions.updateChronicDefinationData({
          loading: false,
          data: [],
        }),
      );
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateChronicDefinationData({
        loading: false,
        data: [],
      }),
    );
  }
}

function* deleteChronicProfile(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    response = yield call(deleteChronicProfileData, payload);
    if (response && Object.keys(response.data).length > 0) {
      yield put(
        actions.displayToast({
          toastMessage: MESSAGES.CHRONIC_DELETE,
          toastType: 'success',
        }),
      );
      yield put(
        actions.fetchChronicProfileList({
          limit: 10,
          pageNo: 1,
          recordCountRequire: true,
        }),
      );
    } else {
      console.log('=== API Failed to respond ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: 'Something went wrong',
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateDeleteChronicProfile({
        loading: false,
        data: [],
        status: 'error',
      }),
    );
  }
}
function* fetchBulkUploadTask(request: any): any {
  const { payload, reuestfor, timer } = request;
  try {
    const response: ResponseProps = yield call(GETBulkSiteGroupStatus, payload);
    if (response && Object.keys(response.data).length > 0) {
      if (
        response.data.isCompleted &&
        response.data.completionPercentage === 100
      ) {
        const result: any = response?.data?.taskResult || [];
        if (!isEmpty(result)) {
          const taskResult: any = JSON.parse(result);
          if (taskResult.error) {
            yield put(actions.updateBulkUpladModal(false));
            yield put(
              actions.displayToast({
                toastMessage: taskResult.message,
                toastType: 'error',
              }),
            );
            if (reuestfor === CONTEXT.UPLOAD_BULK_STATUS) {
              yield put(
                actions.updateUploadStatus({
                  loading: false,
                  data: [],
                }),
              );
            } else {
              yield put(
                actions.BulkUploadedListStatus({
                  loading: false,
                  data: [],
                }),
              );
            }
          } else {
            if (reuestfor === CONTEXT.UPLOAD_BULK_STATUS) {
              yield put(
                actions.updateUploadStatus({
                  loading: false,
                  data: { ...response.data, taskResult },
                  recordCount: response?.data?.completionPercentage,
                }),
              );
            } else {
              yield put(
                actions.BulkUploadedListStatus({
                  loading: false,
                  data: { ...response.data, taskResult },
                  recordCount: response?.data?.completionPercentage,
                }),
              );
              yield put(
                actions.updateUploadStatus({
                  loading: false,
                }),
              );
            }
          }
        }
      } else {
        const isTaskProgress = yield select(getTaskStatus);
        if (timer && !isTaskProgress) {
          yield put(
            actions.updateUploadStatus({
              recordCount: response?.data?.completionPercentage,
            }),
          );
          yield delay(timer);
          yield call(fetchBulkUploadTask, request);
        } else {
          yield put(
            actions.updateUploadStatus({
              loading: false,
              data: [],
            }),
          );
        }
      }
    } else {
      yield put(
        actions.updateUploadStatus({
          loading: false,
          data: [],
        }),
      );
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateUploadStatus({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchPostCreateBulkSiteGroup(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(postBulkSiteGroup, payload);
      if (response && response.data) {
        const taskID = response.data || '';
        yield put(
          actions.updateCreatedBulkSiteGroup({
            loading: false,
            data: response.data,
          }),
        );
        yield call(fetchBulkUploadTask, {
          payload: taskID,
          reuestfor: CONTEXT.UPLOAD_BULK_STATUS,
          timer: 2000,
        });
      } else {
        yield put(
          actions.updateCreatedBulkSiteGroup({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateCreatedBulkSiteGroup({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchPostCreateSMbulkUser(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(postSMBulkUser, payload);
      if (response && response.data) {
        const taskID = response.data || '';
        yield put(
          actions.updateCreatedSMbulkUser({
            loading: false,
            data: response.data,
          }),
        );
        yield call(fetchBulkUploadTask, {
          payload: taskID,
          reuestfor: CONTEXT.UPLOAD_BULK_STATUS,
          timer: 2000,
        });
      } else {
        yield put(
          actions.updateCreatedSMbulkUser({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateCreatedSMbulkUser({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchPostCreateBulkUSer(request: any) {
  let response: ResponseProps;
  const { payload }: any = request;
  try {
    if (payload) {
      response = yield call(postBulkUser, payload);
      if (response && response.data) {
        const taskID = response.data || '';
        yield put(
          actions.updateCreatedBulkUser({
            loading: false,
            data: response.data,
          }),
        );
        yield call(fetchBulkUploadTask, {
          payload: taskID,
          reuestfor: CONTEXT.UPLOAD_BULK_STATUS,
          timer: 2000,
        });
      } else {
        yield put(
          actions.updateCreatedBulkUser({
            loading: false,
            data: response.data,
          }),
        );
        yield put(
          actions.displayToast({
            toastMessage: ERROR_TEXT.API_ERROR,
            toastType: 'error',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    console.log(error);
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateCreatedBulkUser({
        loading: false,
        data: [],
      }),
    );
  }
}
function* fetchBulkUploadTaskList(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(GetSiteGroupBulkUploadList, payload);
      if (response && response.data.length > 0) {
        yield put(
          actions.updateBulkUploadSiteGroupTaskList({
            loading: false,
            data: response.data,
          }),
        );
      } else {
        yield put(
          actions.updateBulkUploadSiteGroupTaskList({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error) {
    console.log(error);
    yield put(
      actions.updateBulkUploadSiteGroupTaskList({
        loading: false,
        data: [],
      }),
    );
  }
}

function* fetchImpersonateUserData(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(getImpersonateUserDetails, payload);
      if (response && response.data.length > 0) {
        const token = response.data[0] ?? null;
        if (token) {
          setupIMUser(token);
          yield put(dashboardAction.updateCompanyId(''));
          yield put(dashboardAction.updateSiteId(''));
        }
        yield put(
          actions.updateimpersonateUser({
            loading: false,
            data: response.data[0],
            status: 'suceess',
          }),
        );
      } else {
        yield put(
          actions.updateimpersonateUser({
            loading: false,
            data: [],
            status: 'success',
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.updateimpersonateUser({
        loading: false,
        data: error?.response?.data?.error,
        status: 'error',
      }),
    );
  }
}
function* closeImpersonateUser(request: any) {
  let response: ResponseProps;
  try {
    const { payload } = request;
    if (payload) {
      response = yield call(closeImpersonateUserSession, payload);
      if (response && response.data.length > 0) {
        const type: any = response.data[0]?.success ? 'success' : 'error';
        yield put(
          actions.displayToast({
            toastMessage: response.data[0]?.message,
            toastType: type,
          }),
        );
        yield put(
          actions.updateImpersonateUserSession({
            loading: false,
            data: response.data[0],
          }),
        );
      } else {
        yield put(
          actions.updateImpersonateUserSession({
            loading: false,
            data: [],
          }),
        );
        console.log('=== API Failed to respond ===');
      }
    } else {
      console.log('=== Missing Payload ===');
    }
  } catch (error: any) {
    yield put(
      actions.displayToast({
        toastMessage: error.response.data.error || ERROR_TEXT.API_ERROR,
        toastType: 'error',
      }),
    );
    yield put(
      actions.updateImpersonateUserSession({
        loading: false,
        data: [],
      }),
    );
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.fetchPermissionList, fetchPermissionList);
  yield takeLatest(actions.fetchPermissionGroupList, fetchPermissionGroupList);
  yield takeLatest(actions.fetchCreatePermission, postCreateNewPermission);
  yield takeLatest(
    actions.fetchCreatePermissionGroup,
    postCreateNewPermissionGroup,
  );
  yield takeLatest(actions.fetchUpdatePermission, postUpdatePermission);
  yield takeLatest(actions.fetchDeletePermission, postDeletePermission);
  yield takeLatest(
    actions.fetchUpdatePermissionGroup,
    postUpdatePermissionGroup,
  );
  yield takeLatest(
    actions.fetchDeletePermissionGroup,
    postDeletePermissionGroup,
  );
  yield takeLatest(
    actions.fetchPermissionGroupCustomerList,
    fetchPermissionGroupCustomerList,
  );
  yield takeLatest(actions.fetchCreateUser, fetchPostCreateUser);
  yield takeLatest(actions.fetchUsersList, fetchUsersList);
  yield takeLatest(actions.fetchUserValidation, fetchUserValidation);
  yield takeLatest(actions.fetchUserDetails, fetchUserDetail);
  yield takeLatest(actions.fetchSuspendUser, putSuspendUser);
  yield takeLatest(actions.fetchPostUpdateUser, fetchPostUpdateUser);
  yield takeLatest(actions.fetchUserDelete, postDeleteUser);
  yield takeLatest(actions.fetchAssociateSites, postAssociateSites);
  yield takeLatest(actions.fetchUsersGroupList, postUsersGroupList);
  yield takeLatest(actions.fetchUserSiteList, fetchUserSiteList);
  yield takeLatest(actions.fetchUserCompanyList, fetchUserCompanyList);
  yield takeLatest(
    actions.fetchUserPermissionGroupList,
    fetchUserPermissionGroup,
  );
  yield takeLatest(actions.fetchPermissionGroupChild, postPermissionGroupChild);
  yield takeLatest(actions.fetchUserPermissionList, fetchUserPermissionList);
  yield takeLatest(
    actions.fetchUserPermissionsGroupList,
    fetchUserPermissionsGroupList,
  );
  yield takeLatest(
    actions.fetchUserPermissionsGroupDetails,
    fetchUserPermissionsGroupDetails,
  );
  yield takeLatest(actions.fetchPostCreateNewGroup, postCreateNewGroup);
  yield takeLatest(actions.deletePermissionGroup, permissionGroupDelete);
  yield takeLatest(actions.fetchSearchPermissions, fetchSearchPermission);
  yield takeLatest(
    actions.fetchSearchPermissionsGroup,
    fetchSearchPermissionGroup,
  );
  yield takeLatest(
    actions.fetchSearchPermissionsUser,
    fetchSearchPermissionUser,
  );
  yield takeLatest(actions.putPermissionGroup, putPermissionGroup);
  yield takeLatest(
    actions.fetchPostPermissionGroupToUser,
    postPermissionsGroupToUser,
  );
  yield takeLatest(
    actions.fetchUserPermissionsDetails,
    fetchUserPermissionsGroupDetail,
  );
  yield takeLatest(actions.deleteUserPermission, userPermissionDelete);
  yield takeLatest(
    actions.checkUserPermissionedGroupStatus,
    checkUserPermissionGroupStatus,
  );
  yield takeLatest(actions.putPermissionGroupName, putUserPermissionGroupName);
  yield takeLatest(actions.putChangeUserPassword, putChangePassword);
  yield takeLatest(actions.fetchSitesList, fetchSitesList);
  yield takeLatest(actions.fetchSiteRemark, fetchSiteRemarks);
  yield takeLatest(actions.putSiteVisibility, putSiteVisibility);
  yield takeLatest(actions.fetchAssociateSitesList, fetchAssociateSiteList);
  yield takeLatest(actions.fetchSiteContactDetails, fetchSiteContactDetails);
  yield takeLatest(actions.patchSiteContactDetails, patchSiteContactDetails);
  yield takeLatest(
    actions.fetchCompanySiteContactDetails,
    fetchCompanySiteContactDetails,
  );
  yield takeLatest(
    actions.patchCompanySiteContactDetails,
    patchCompanySiteContactDetails,
  );
  yield takeLatest(actions.patchCompanySites, patchCompanySites);
  yield takeLatest(actions.fetchSiteGroups, fetchUserSiteGroupList);
  yield takeLatest(actions.fetchSiteDetails, fetchCompanySiteDetails);
  yield takeLatest(actions.fetchCompanyAccountType, fetchCompanyAccountType);
  yield takeLatest(actions.fetchProfilePartyNumber, fetchProfilePartyNumber);
  yield takeLatest(actions.fetchMasterCompanyList, fetchMasterCompanyList);
  yield takeLatest(actions.createCompanyProfile, createCompanyMasterProfile);
  yield takeLatest(actions.patchCompanyProfile, patchCompanyMasterProfile);
  yield takeLatest(actions.fetchCSSiteGroupList, fetchCSSiteGroupList);
  yield takeLatest(
    actions.fetchAssociateSiteGroupList,
    fetchAssociateSiteGroupList,
  );
  yield takeLatest(actions.patchCSSiteGroup, patchCSSiteGroup);
  yield takeLatest(actions.postCSSiteGroup, postCompanySiteSiteGroup);
  yield takeLatest(actions.fetchNotificationList, fetchNotificationList);
  yield takeLatest(
    actions.fetchNotificationCompanyList,
    fetchNotificationCompanyList,
  );
  yield takeLatest(actions.fetchNotificationDetails, fetchNotificationDetails);
  yield takeLatest(actions.fetchPostNotification, postNotification);
  yield takeLatest(actions.fetchPatchNotification, patchNotification);
  yield takeLatest(actions.deleteNotification, deleteNewNotification);
  yield takeLatest(actions.deleteSiteGroup, deleteCSSiteGroup);
  yield takeLatest(
    actions.fetchNotificationSiteGroupList,
    fetchNotificationSiteGroupList,
  );
  yield takeLatest(
    actions.fetchNotificationSiteList,
    fetchNotificationSiteList,
  );
  yield takeLatest(actions.fetchChronicProfileList, fetchChronicProfileList);
  yield takeLatest(actions.fetchChronicProfileData, fetchChronicProfileData);
  yield takeLatest(actions.postChronicProfileRequest, postChronicRequest);
  yield takeLatest(actions.fetchChronicDefinationData, fetchChronicDefinition);
  yield takeLatest(actions.deleteChronicProfile, deleteChronicProfile);
  yield takeLatest(
    actions.fetchCreateBulkSiteGroup,
    fetchPostCreateBulkSiteGroup,
  );
  yield takeLatest(
    actions.fetchBulkUploadSiteGroupTaskList,
    fetchBulkUploadTaskList,
  );
  yield takeLatest(actions.fetchUploadStatus, fetchBulkUploadTask);
  yield takeLatest(actions.fetchCreateBulkUser, fetchPostCreateBulkUSer);
  yield takeLatest(actions.fetchCreateSMbulkUser, fetchPostCreateSMbulkUser);
  yield takeLatest(actions.fetchImpersonateUser, fetchImpersonateUserData);
  yield takeLatest(actions.closeImpersonateUserSession, closeImpersonateUser);
}
