/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectSlice = (state: RootState) =>
  state?.securityManager ?? initialState;

export const getSecuritySiteInfo = createSelector(
  [selectSlice],
  state => state.securitySiteInfo,
);
export const getPutSiteTestStatus = createSelector(
  [selectSlice],
  state => state.siteOnTest,
);
export const getDeleteSiteInfoStatus = createSelector(
  [selectSlice],
  state => state.deleteSiteTest,
);
export const getSecurityCallContactInfo = createSelector(
  [selectSlice],
  state => state.securityCallContactInfo,
);
export const getUserCallList = createSelector(
  [selectSlice],
  state => state.userCallList,
);
export const getUserContactList = createSelector(
  [selectSlice],
  state => state.userContactList,
);
export const getCustomerList = createSelector(
  [selectSlice],
  state => state.contactList,
);
export const getContactDetails = createSelector(
  [selectSlice],
  state => state.contactDetails,
);
export const getAddContacts = createSelector(
  [selectSlice],
  state => state.addContact,
);
export const getPasscodeEmail = createSelector(
  [selectSlice],
  state => state.passcodeEmail,
);
export const getSelectedSite = createSelector(
  [selectSlice],
  state => state.selectedSiteName,
);
export const getSelectedCallContact = createSelector(
  [selectSlice],
  state => state.callContactList,
);
export const getSecurityManagerJobRole = createSelector(
  [selectSlice],
  state => state.securityManagerJobRole,
);
export const getSecurityManagerRestrictedPassword = createSelector(
  [selectSlice],
  state => state.securityManagerRestrictedPassword,
);
export const getSecurityManagerJobTitle = createSelector(
  [selectSlice],
  state => state.securityManagerJobTitle,
);
export const getSecurityManagerAdminConfig = createSelector(
  [selectSlice],
  state => state.securityManagerAdminConfig,
);
export const getPutSecurityManagerAdminConfig = createSelector(
  [selectSlice],
  state => state.putsecurityManagerAdminConfig,
);
export const getPutSiteSecurityManagerAdminConfig = createSelector(
  [selectSlice],
  state => state.putSiteSecurityManagerAdminConfig,
);
export const getCompanyProfile = createSelector(
  [selectSlice],
  state => state.companyProfile,
);
export const getSecurityManagerActivityLogTypes = createSelector(
  [selectSlice],
  state => state.securityManagerActivityLogTypes,
);
export const getSecurityManagerActivityLog = createSelector(
  [selectSlice],
  state => state.securityManagerActivityLog,
);
export const getExportSecurityManagerActivityLog = createSelector(
  [selectSlice],
  state => state.securityManagerActivityLogExport,
);
export const getSecurityManagerUser = createSelector(
  [selectSlice],
  state => state.securityManagerUser,
);
export const getSecurityManagerUserPutStatus = createSelector(
  [selectSlice],
  state => state.updateSecurityManagerUser,
);
export const getSecurityManagerUserDeleteStatus = createSelector(
  [selectSlice],
  state => state.deleteSecurityManagerUser,
);
export const getsecurityManagerZoneList = createSelector(
  [selectSlice],
  state => state.securityManagerZoneList,
);
export const getsecurityManagerAlarmList = createSelector(
  [selectSlice],
  state => state.securityManagerAlarmList,
);
export const getsecurityManagerSiteGroupList = createSelector(
  [selectSlice],
  state => state.securityManagerSiteGroupList,
);
export const getSelectedSiteGroup = createSelector(
  [selectSlice],
  state => state.selectedSiteGroup,
);
export const getSelectedSMUserFilter = createSelector(
  [selectSlice],
  state => state.securityManagerUserFilter,
);
export const getSMSelfTestReport = createSelector(
  [selectSlice],
  state => state.selfTestReport,
);
export const getSMSelfTestDetailReport = createSelector(
  [selectSlice],
  state => state.selfTestDetailReport,
);
export const getSelfTestZoneList = createSelector(
  [selectSlice],
  state => state.selfTestZoneList,
);
export const getSelfTestStatus = createSelector(
  [selectSlice],
  state => state.selfTestZoneStatus,
);
export const getPostSelfTestSensor = createSelector(
  [selectSlice],
  state => state.postSelfTestSensor,
);
export const getActiveSelfTestStatus = createSelector(
  [selectSlice],
  state => state.activeSelfTestStatus,
);
export const getSelfTestProps = createSelector(
  [selectSlice],
  state => state.selfTestProps,
);
export const getTestStatus = createSelector(
  [selectSlice],
  state => state.selfTestProps.testProgress,
);
export const getGlobalUserAffectedSites = createSelector(
  [selectSlice],
  state => state.globalUserAffectedSite,
);
export const getGlobalDeleteUser = createSelector(
  [selectSlice],
  state => state.deleteGlobalUser,
);
